import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MediaServiceModule } from '@services/media';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UiKitChipsModule } from '../chips/chips.module';
import { UiKitControlsModule } from '../controls';
import { UiKitIconsModule } from '../icons';
import { UiKitBadgeComponent } from './components/ui-kit-badge/ui-kit-badge.component';
import { UiKitBreadcrumbsComponent } from './components/ui-kit-breadcrumbs/ui-kit-breadcrumbs.component';
import { UiKitCalloutComponent } from './components/ui-kit-callout/ui-kit-callout.component';
import { UiKitCardComponent } from './components/ui-kit-card/ui-kit-card.component';
import { UiKitColorPreviewComponent } from './components/ui-kit-color-preview/ui-kit-color-preview.component';
import { UiKitColorSelectorComponent } from './components/ui-kit-color-selector/ui-kit-color-selector.component';
import { UiKitConfirmModalComponent } from './components/ui-kit-confirm-modal/ui-kit-confirm-modal.component';
import { UiKitContentTranslationComponent } from './components/ui-kit-content-translation/ui-kit-content-translation.component';
import { UiKitContentTranslationV2Component } from './components/ui-kit-content-translation-v2/ui-kit-content-translation-v2.component';
import { UiKitCopyContentComponent } from './components/ui-kit-copy-content/ui-kit-copy-content.component';
import { UiKitDividerLineComponent } from './components/ui-kit-divider-line/ui-kit-divider-line.component';
import { UiKitEmptyStateComponent } from './components/ui-kit-empty-state/ui-kit-empty-state.component';
import { UiKitEmptyStateRowComponent } from './components/ui-kit-empty-state-row/ui-kit-empty-state-row.component';
import { UiKitErrorStateComponent } from './components/ui-kit-error-state/ui-kit-error-state.component';
import { UiKitEventTypeComponent } from './components/ui-kit-event-type/ui-kit-event-type.component';
import { UiKitExpanderComponent } from './components/ui-kit-expander/ui-kit-expander.component';
import { UiKitHistoryValueComponent } from './components/ui-kit-history-value/ui-kit-history-value.component';
import { UiKitIconSelectorComponent } from './components/ui-kit-icon-selector/ui-kit-icon-selector.component';
import { UiKitImageComponent } from './components/ui-kit-image/ui-kit-image.component';
import { UiKitImageCarouselComponent } from './components/ui-kit-image-carousel/ui-kit-image-carousel.component';
import { UiKitImagePreviewComponent } from './components/ui-kit-image-preview/ui-kit-image-preview.component';
import { UiKitInfoLabelComponent } from './components/ui-kit-info-label/ui-kit-info-label.component';
import { UiKitInfoTooltipComponent } from './components/ui-kit-info-tooltip/ui-kit-info-tooltip.component';
import { UiKitLabelComponent } from './components/ui-kit-label/ui-kit-label.component';
import { UiKitModalComponent } from './components/ui-kit-modal/ui-kit-modal.component';
import { UiKitNotificationComponent } from './components/ui-kit-notification/ui-kit-notification.component';
import { UiKitPageTitleComponent } from './components/ui-kit-page-title/ui-kit-page-title.component';
import { UiKitPreviewStatusIconRowComponent } from './components/ui-kit-preview-status-icon-row/ui-kit-preview-status-icon-row.component';
import { UiKitProgressBarComponent } from './components/ui-kit-progress-bar/ui-kit-progress-bar.component';
import { UiKitShowMoreComponent } from './components/ui-kit-show-more/ui-kit-show-more.component';
import { UiKitStepTrackerComponent } from './components/ui-kit-step-tracker/ui-kit-step-tracker.component';
import { UiKitTabbedNavbarComponent } from './components/ui-kit-tabbed-navbar/ui-kit-tabbed-navbar.component';
import { UiKitThumbnailComponent } from './components/ui-kit-thumbnail/ui-kit-thumbnail.component';
import { UiKitConfirmModalDirective } from './directives/ui-kit-confirm-modal.directive';
import { UiKitContentTranslationDirective } from './directives/ui-kit-content-translation.directive';
import { UiKitElementHeightListenerDirective } from './directives/ui-kit-element-height-listener.directive';
import { UiKitStickyListenerDirective } from './directives/ui-kit-sticky-listener.directive';
import { UiKitStickyPinnedDirective } from './directives/ui-kit-sticky-pinned.directive';

const components = [
  UiKitBadgeComponent,
  UiKitBreadcrumbsComponent,
  UiKitCalloutComponent,
  UiKitCardComponent,
  UiKitColorPreviewComponent,
  UiKitColorSelectorComponent,
  UiKitConfirmModalComponent,
  UiKitContentTranslationComponent,
  UiKitContentTranslationV2Component,
  UiKitCopyContentComponent,
  UiKitDividerLineComponent,
  UiKitEmptyStateComponent,
  UiKitEmptyStateRowComponent,
  UiKitErrorStateComponent,
  UiKitEventTypeComponent,
  UiKitExpanderComponent,
  UiKitHistoryValueComponent,
  UiKitIconSelectorComponent,
  UiKitImageCarouselComponent,
  UiKitImageComponent,
  UiKitImagePreviewComponent,
  UiKitInfoLabelComponent,
  UiKitInfoTooltipComponent,
  UiKitLabelComponent,
  UiKitModalComponent,
  UiKitNotificationComponent,
  UiKitPageTitleComponent,
  UiKitPreviewStatusIconRowComponent,
  UiKitProgressBarComponent,
  UiKitShowMoreComponent,
  UiKitStepTrackerComponent,
  UiKitTabbedNavbarComponent,
  UiKitThumbnailComponent,
];

const directives = [
  UiKitConfirmModalDirective,
  UiKitContentTranslationDirective,
  UiKitElementHeightListenerDirective,
  UiKitStickyListenerDirective,
  UiKitStickyPinnedDirective,
];

@NgModule({
  imports: [
    ClipboardModule,
    FormsModule,
    SharedModule,
    RouterModule,
    UiKitControlsModule,
    UiKitIconsModule,
    UiKitChipsModule,
    MediaServiceModule,
    NgxPermissionsModule.forChild(),

    // Material imports
    MatTooltipModule,
    MatDialogModule,
    MatRippleModule,
    MatMenuModule,
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [
    ...components,
    ...directives,
  ],
})
export class UiKitElementsModule { }
