import { Component, Input } from '@angular/core';

@Component({
  selector: 'datatable-message',
  templateUrl: './datatable-message.component.html',
  styleUrls: ['./datatable-message.component.scss'],
})
export class DatatableMessageComponent {

  @Input() readonly messageLabel: string = 'label_no_data_to_display';

}
