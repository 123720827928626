import { IContact } from '@core/interfaces/contact/contact.interface';
import { BillingInformationView } from '@core/models/billing/billing-information-view.model';
import { Company } from '@core/models/company/company.model';
import { Contact } from '@core/models/contact/contact.model';
import { AAGUID, Status } from '@interticket/core';
import { PartnerStatus } from '../enums/partner-status.enum';
import { IPartnerView } from '../interfaces/partner-view.interface';
import { PartnerViewWebConfig } from './partner-view-web-config.model';

export class PartnerView implements IPartnerView {

  readonly billingInformation: BillingInformationView;
  readonly company: Company;
  readonly contacts: Contact[];
  readonly createdAt: string;
  readonly createdBy: string;
  readonly invitationDeadline: string;
  readonly invitedUserEmail: string;
  readonly invitedUserFristName: string;
  readonly invitedUserLastName: string;
  readonly name: string;
  readonly partnerId: AAGUID;
  readonly regionPartnerId: AAGUID;
  readonly slug: string;
  readonly status: Status<PartnerStatus>;
  readonly web: PartnerViewWebConfig;

  constructor(iPartnerView: IPartnerView) {
    this.billingInformation = iPartnerView.billingInformation ? new BillingInformationView(iPartnerView.billingInformation) : null;
    this.company = new Company(iPartnerView.company);
    this.contacts = iPartnerView.contacts ? iPartnerView.contacts.map((iContact: IContact) => new Contact(iContact)) : [];
    this.createdAt = iPartnerView.createdAt;
    this.createdBy = iPartnerView.createdBy;
    this.invitationDeadline = iPartnerView.invitationDeadline;
    this.invitedUserEmail = iPartnerView.invitedUserEmail;
    this.invitedUserFristName = iPartnerView.invitedUserFristName;
    this.invitedUserLastName = iPartnerView.invitedUserLastName;
    this.name = iPartnerView.name;
    this.partnerId = iPartnerView.partnerId;
    this.regionPartnerId = iPartnerView.regionPartnerId;
    this.slug = iPartnerView.slug;
    this.status = new Status<PartnerStatus>(iPartnerView.status);
    this.web = new PartnerViewWebConfig(iPartnerView.web);
  }

}
