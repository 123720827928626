import { DateAdapter } from 'angular-calendar';
import { DateTime } from 'luxon';

export function adapterFactory(): DateAdapter {

  function addDays(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ days: amount }).toJSDate();
  }

  function addHours(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ hours: amount }).toJSDate();
  }

  function addMinutes(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ minutes: amount }).toJSDate();
  }

  function addSeconds(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ seconds: amount }).toJSDate();
  }

  function addWeeks(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ weeks: amount }).toJSDate();
  }

  function addMonths(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).plus({ months: amount }).toJSDate();
  }

  function differenceInDays(dateLeft: Date | number, dateRight: Date | number): number {
    const date1 = DateTime.fromJSDate(new Date(dateLeft));
    const date2 = DateTime.fromJSDate(new Date(dateRight));
    const diff = date1.diff(date2, ['days']);
    return diff.toObject().days;
  }

  function differenceInMinutes(dateLeft: Date | number, dateRight: Date | number): number {
    const date1 = DateTime.fromJSDate(new Date(dateLeft));
    const date2 = DateTime.fromJSDate(new Date(dateRight));
    const diff = date1.diff(date2, ['minutes']);
    return diff.toObject().minutes;
  }

  function differenceInSeconds(dateLeft: Date | number, dateRight: Date | number): number {
    const date1 = DateTime.fromJSDate(new Date(dateLeft));
    const date2 = DateTime.fromJSDate(new Date(dateRight));
    const diff = date1.diff(date2, ['seconds']);
    return diff.toObject().seconds;
  }

  function endOfDay(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).endOf('day').toJSDate();
  }

  function endOfMonth(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).endOf('month').toJSDate();
  }

  function endOfWeek(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).endOf('week').toJSDate();
  }

  function getDay(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).day;
  }

  function getYear(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).year;
  }

  function getDate(date: Date | number): number {
    return new Date(date).getDate();
  }

  /* istanbul ignore next */
  function getMonth(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).month;
  }

  function isSameDay(dateLeft: Date | number, dateRight: Date | number): boolean {
    const dateTime1 = DateTime.fromJSDate(new Date(dateLeft));
    const dateTime2 = DateTime.fromJSDate(new Date(dateRight));
    return dateTime1.hasSame(dateTime2, 'day');
  }

  function isSameMonth(dateLeft: Date | number, dateRight: Date | number): boolean {
    const dateTime1 = DateTime.fromJSDate(new Date(dateLeft));
    const dateTime2 = DateTime.fromJSDate(new Date(dateRight));
    return dateTime1.hasSame(dateTime2, 'month');
  }

  function isSameSecond(dateLeft: Date | number, dateRight: Date | number): boolean {
    const dateTime1 = DateTime.fromJSDate(new Date(dateLeft));
    const dateTime2 = DateTime.fromJSDate(new Date(dateRight));
    return dateTime1.hasSame(dateTime2, 'second');
  }

  function max(dates: (Date | number)[]): Date {
    const dateTimeArray = dates.map(item => DateTime.fromJSDate(new Date(item)));
    return DateTime.max(...dateTimeArray).toJSDate();
  }

  function setHours(date: Date | number, hours: number): Date {
    return DateTime.fromJSDate(new Date(date)).set({ hour: hours }).toJSDate();
  }

  function setMinutes(date: Date | number, minutes: number): Date {
    return DateTime.fromJSDate(new Date(date)).set({ minute: minutes }).toJSDate();
  }

  function setDate(date: Date | number, dayOfMonth: number): Date {
    return DateTime.fromJSDate(new Date(date)).set({ day: dayOfMonth }).toJSDate();
  }

  function setMonth(date: Date | number, month: number): Date {
    return DateTime.fromJSDate(new Date(date)).set({ month: month }).toJSDate();
  }

  function setYear(date: Date | number, year: number): Date {
    return DateTime.fromJSDate(new Date(date)).set({ year: year }).toJSDate();
  }

  function startOfDay(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).startOf('day').toJSDate();
  }

  function startOfMinute(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).startOf('minute').toJSDate();
  }

  function startOfMonth(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).startOf('month').toJSDate();
  }

  function startOfWeek(date: Date | number): Date {
    return DateTime.fromJSDate(new Date(date)).startOf('week').toJSDate();
  }

  function getHours(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).get('hour');
  }

  function getMinutes(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).get('minute');
  }

  function getISOWeek(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).weekNumber;
  }

  function subDays(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).minus({ days: amount }).toJSDate();
  }

  function subWeeks(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).minus({ weeks: amount }).toJSDate();
  }

  function subMonths(date: Date | number, amount: number): Date {
    return DateTime.fromJSDate(new Date(date)).minus({ months: amount }).toJSDate();
  }

  function getTimezoneOffset(date: Date | number): number {
    return DateTime.fromJSDate(new Date(date)).offset * -1;
  }

  return {
    addDays,
    addWeeks,
    addMonths,
    subDays,
    subWeeks,
    subMonths,
    getISOWeek,
    setDate,
    setMonth,
    setYear,
    getDate,
    getYear,
    addHours,
    addMinutes,
    addSeconds,
    differenceInDays,
    differenceInMinutes,
    differenceInSeconds,
    endOfDay,
    endOfMonth,
    endOfWeek,
    getDay,
    getMonth,
    isSameDay,
    isSameMonth,
    isSameSecond,
    max,
    setHours,
    setMinutes,
    startOfDay,
    startOfMinute,
    startOfMonth,
    startOfWeek,
    getHours,
    getMinutes,
    getTimezoneOffset,
  };
}
