import { ButtonType } from '@shared/enums/button/button-type.enum';
import { IButton } from '@shared/interfaces/button/button.interface';

export class Button implements IButton {

  readonly label: string;
  readonly onClick: () => any;
  readonly class: string;
  readonly type: ButtonType;
  readonly isLoading: boolean;
  readonly icon: string;
  readonly routerLink: string;

  constructor(iButton: IButton) {
    this.label = iButton.label;
    this.onClick = iButton.onClick;
    this.class = iButton.class ? iButton.class : 'button';
    this.type = iButton.type ? iButton.type : ButtonType.BUTTON;
    this.isLoading = !!iButton.isLoading;
    this.icon = iButton.icon;
    this.routerLink = iButton.routerLink;
  }

}
