<div class="input-group">
  <input [formControl]="apiKeyControl" [readonly]="isInputReadonly" [type]="type" class="input-group-field" [placeholder]="placeholder" autocomplete="off">
  <div class="input-group-button">
    <generate-button [isDisabled]="isButtonDisabled" *ngIf="!noGenerateButton" [label]="buttonLabel" (onClick)="onClickGenerateButton()"></generate-button>
    <app-button
      *ngIf="buttonClicked || noGenerateButton"
      classes="button hollow primary"
      matTooltip="{{ tooltipText | translate }}"
      #tooltip="matTooltip"
      [isDisabled]="apiKeyControl.invalid || noGenerateButton && isButtonDisabled"
      [label]="buttonText"
      [matTooltipHideDelay]="matTooltipHideDelay"
      [matTooltipPosition]="matTooltipPosition"
      (onClick)="onClickButton($event)"
      (mouseenter)="$event.stopImmediatePropagation()">
    </app-button>
  </div>
</div>
<app-show-errors [control]="apiKeyControl"></app-show-errors>
