import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const accordionAnimation: AnimationTriggerMetadata =
  trigger('accordionAnimation', [
    state('collapsed', style({
      height: '0px',
      minHeight: '0',
      paddingTop: '0',
      paddingBottom: '0',
      marginTop: '0',
      marginBottom: '0',
    })),
    state('expanded', style({
      height: '*',
      paddingTop: '*',
      paddingBottom: '*',
      marginTop: '*',
      marginBottom: '*',
    })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]);

export const accordionExtendAnimation: AnimationTriggerMetadata =
  trigger('accordionExtendAnimation', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]);
