export const Boxes = `<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="boxes">
    <path id="Vector" d="M3.96008 17.7267C3.56643 17.9632 3.24052 18.2973 3.01393 18.6968C2.78734 19.0962 2.66775 19.5474 2.66675 20.0067V24.3267C2.66775 24.7859 2.78734 25.2371 3.01393 25.6365C3.24052 26.036 3.56643 26.3702 3.96008 26.6067L7.96008 29.0067C8.37483 29.2558 8.84956 29.3875 9.33341 29.3875C9.81726 29.3875 10.292 29.2558 10.7067 29.0067L16.0001 25.8333V18.5L9.33341 14.5L3.96008 17.7267Z" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_2" d="M9.33343 22.5L3.01343 18.7" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_3" d="M9.33325 22.5L15.9999 18.5" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_4" d="M9.33325 22.5V29.3933" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_5" d="M16 18.5V25.8333L21.2933 29.0067C21.7081 29.2558 22.1828 29.3875 22.6667 29.3875C23.1505 29.3875 23.6252 29.2558 24.04 29.0067L28.04 26.6067C28.4337 26.3702 28.7596 26.036 28.9861 25.6365C29.2127 25.2371 29.3323 24.7859 29.3333 24.3267V20.0067C29.3323 19.5474 29.2127 19.0962 28.9861 18.6968C28.7596 18.2973 28.4337 17.9632 28.04 17.7267L22.6667 14.5L16 18.5Z" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_6" d="M22.6667 22.5L16 18.5" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_7" d="M22.6667 22.5L28.9867 18.7" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_8" d="M22.6667 22.5V29.3933" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_9" d="M10.6266 6.39331C10.2329 6.62982 9.90702 6.96398 9.68044 7.36343C9.45385 7.76288 9.33426 8.21408 9.33325 8.67331V14.5L15.9999 18.5L22.6666 14.5V8.67331C22.6656 8.21408 22.546 7.76288 22.3194 7.36343C22.0928 6.96398 21.7669 6.62982 21.3733 6.39331L17.3733 3.99331C16.9585 3.74413 16.4838 3.61249 15.9999 3.61249C15.5161 3.61249 15.0413 3.74413 14.6266 3.99331L10.6266 6.39331Z" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_10" d="M15.9999 11.1666L9.67993 7.36664" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_11" d="M16 11.1666L22.32 7.36664" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_12" d="M16 18.5V11.1667" stroke="currentColor" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</svg>
`;
