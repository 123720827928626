import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'partnerMonogram',
})
export class PartnerMonogramPipe implements PipeTransform {

  transform(partnerName: string): any {
    const elements: string[] = partnerName.split(' ');
    if (elements.length === 1) {
      return elements[0].substr(0, 2);
    }
    return `${elements[0].substr(0, 1)}${elements[1].substr(0, 1)}`;
  }

}
