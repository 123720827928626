<div
  class="badge-wrapper flex-middle flex-center"
  [class.has-hover]="hasHover"
  [class.remain-circle]="isCircle">

  <!-- VALUE -->
  <span class="badge-value flex-middle flex-center">
    {{ value }}
  </span>

  <!-- HOVER STATE -->
  <span
    *ngIf="hasHover"
    class="badge-hover flex-middle flex-center"
    (click)="hoverClick.emit()">

    <!-- HOVER ICON -->
    <ui-kit-icon
      [icon]="'x'"
      [size]="14">
    </ui-kit-icon>

  </span>

</div>
