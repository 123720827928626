import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'tab-error-label',
  templateUrl: './tab-error-label.component.html',
  styleUrls: ['./tab-error-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabErrorLabelComponent {

  private readonly isCurrentTab$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  abstractControl: AbstractControl;

  showError$: Observable<boolean>;

  @Input() set tabFormControl(tabFormControl: AbstractControl) {
    if (tabFormControl) {
      this.abstractControl = tabFormControl;
      this.showError$ = this.getShowErrorStream();
    }
  }

  @Input() set isCurrentTab(isCurrentTab: boolean) {
    this.isCurrentTab$.next(isCurrentTab);
  }

  @Input() tabLabel: string;
  @Input() childControlNames: string[];

  private getShowErrorStream(): Observable<boolean> {
    return combineLatest([
      this.isCurrentTab$,
      this.getAbstractControlStatusChangesStream(),
    ])
      .pipe(
        skip(1),
        map(([isCurrentTab, isValid]: [boolean, boolean]) => !isCurrentTab && !isValid),
        distinctUntilChanged(),
        debounceTime(100),
        tap(isInvalid => isInvalid && this.validateAbstractControl(this.abstractControl)),
      );
  }

  private getAbstractControlStatusChangesStream(): Observable<boolean> {
    return this.abstractControl.statusChanges
      .pipe(
        startWith(this.abstractControl.valid ? 'VALID' : 'INVALID'),
        filter((status: any) => status === 'VALID' || status === 'INVALID'),
        map((status: any) => status === 'VALID' || this.abstractControl.disabled || this.areAllChildControlsValid())
      );
  }

  private areAllChildControlsValid(): boolean {
    return this.childControlNames?.every((controlName: string) => {
      const control = (this.abstractControl as UntypedFormGroup).controls[controlName];
      return control.valid || control.disabled;
    });
  }

  private updateControlValidity(control: AbstractControl): void {
    control.markAsTouched({ onlySelf: true });
    control.markAsDirty({ onlySelf: true });
    control.updateValueAndValidity({ onlySelf: true });
  }

  private validateAbstractControlArray(controls: AbstractControl[]): void {
    controls
      .filter(control => control.invalid && control.enabled)
      .forEach(control => this.validateAbstractControl(control));
  }

  private validateAbstractControl(control: AbstractControl): void {
    if (this.childControlNames?.length) {
      this.childControlNames.forEach(controlName => this.updateControlValidity((this.abstractControl as UntypedFormGroup).controls[controlName]));
    } else {
      this.updateControlValidity(control);

      if (control instanceof UntypedFormGroup) {
        this.validateAbstractControlArray(Object.values(control.controls));
      } else if (control instanceof UntypedFormArray) {
        this.validateAbstractControlArray(control.controls);
      }
    }
  }

}
