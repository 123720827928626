import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Utils } from '@interticket/core';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-progress-bar',
  templateUrl: './ui-kit-progress-bar.component.html',
  styleUrls: ['./ui-kit-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitProgressBarComponent {

  @Input() title?: string;
  @Input() legend?: string;
  @Input() current: number;
  @Input() total: number;
  @Input() percentage: number;
  @Input() showLegend = true;
  @Input() legendPosition: 'bottom' | 'inline' = 'bottom';
  @Input() showPercentage = false;
  @Input() palette: UiKitPalette = UiKitPalette.PRIMARY;

  get progressBarPercentage(): number {
    if (Utils.isNumeric(this.percentage)) {
      return this.percentage;
    }

    return this.total > 0 ? Math.min(this.current / this.total * 100, 100) : 0;
  }

}
