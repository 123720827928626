import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { getModulePermissions } from '@config/app-skeleton/app-skeleton.config';
import { IAuthRedirectParams } from '@core/interfaces/auth/auth-redirect-to-login-page.interface';
import { AUTH_TOKEN } from '@core/mocks/auth/auth-token.mock';
import { AuthLogoutRedirect } from '@core/models/auth/auth-logout-redirect.model';
import { AuthToken } from '@core/models/auth/auth-token.model';
import { AuthUser } from '@core/models/auth/auth-user.model';
import { APP_CONFIG, environment, IAppConfig } from '@env';
import { AAGUID, AppError, AuthService as CoreAuthService, Browser, EncodedHttpParams } from '@interticket/core';
import { IamService } from '@services/iam';
import { IamManagementPartnerService } from '@services/iam-management-partner';
import { IamManagementUserService } from '@services/iam-management-user';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AuthTokenStoreService } from '../store/auth-token-store.service';
import { AuthUserStoreService } from '../store/auth-user-store.service';

@Injectable()
export class AuthService {

  private readonly logoutURL = `${this.appConfig.accountSiteUrl}/connect/endsession`;
  private readonly loginURL = `${this.appConfig.accountSiteUrl}/connect/authorize`;
  private readonly logoutRedirectURL = '/auth/';
  private readonly loginParams = this.coreAuthService.adminClientLoginParams;

  readonly partnerIdStorageKey = 'partner_id';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private injector: Injector,
    private iamService: IamService,
    private iamManagementUserService: IamManagementUserService,
    private iamManagementPartnerService: IamManagementPartnerService,
    private permissionsService: NgxPermissionsService,
    private coreAuthService: CoreAuthService,
    private authTokenStore: AuthTokenStoreService,
    private authUserStore: AuthUserStoreService,
  ) { }

  /**
   * Inject Router manually to fix cyclic dependency issue when inject AuthService to APP_INITIALIZER in AppModule provider section
   * https://stackoverflow.com/a/39767492/9533324
   */
  private get router(): Router {
    return this.injector.get(Router);
  }

  /**
   * Authenticate user
   *
   * @method setAuth
   * @access private
   * @param {AuthUser} user
   */
  private setAuth(user: AuthUser): void {
    // Set current user data to store
    this.authUserStore.patchState(user);
    // Set user permissions
    this.setPermissionsService(user);
  }

  /**
   * Clear user authentication
   *
   * @method clearAuth
   * @access private
   */
  private clearAuth(): void {
    // Set auth token to null in the store
    // Set auth status to false in the store
    // Remove authorization token object from localStorage
    this.authTokenStore.resetState();
    // Set current user to null in the store
    this.authUserStore.resetState();
  }

  /**
   * Set user permissions service
   *
   * @method setPermissionsService
   * @access private
   * @param {AuthUser} user
   */
  private setPermissionsService(user: AuthUser) {
    // Clear current permission service
    this.permissionsService.flushPermissions();
    // Set permission service by user permissions
    this.permissionsService.addPermission(user.permissionList);
  }

  /**
   * Returns true if user has least one admin permission
   *
   * @method checkAdminPermissions
   * @access private
   * @param {AuthUser} user
   * @return {boolean}
   */
  private checkAdminPermissions(user: AuthUser): boolean {
    const modulePermissions: string[] = getModulePermissions();
    return user.permissionList.some((permission: string) => modulePermissions.indexOf(permission) > -1);
  }

  /**
   * Get current user profile datas, permissions and partner configs
   *
   * @method getUser
   * @access private
   * @return {Observable<AuthUser>}
   */
  private getUser(): Observable<AuthUser> {
    return combineLatest([
      this.iamManagementUserService.getProfile(),
      this.iamService.getPermissions(),
      this.iamManagementPartnerService.getConfig(),
    ])
      .pipe(
        take(1),
        map(([userProfile, permissionList, partnerConfig]) => new AuthUser({ userProfile, permissionList, partnerConfig }))
      );
  }

  @Browser()
  refresh(): Observable<any> {
    return this.coreAuthService.refreshAuthToken(
      this.authTokenStore.authToken.access_token,
      this.loginURL,
      this.loginParams
    )
      .pipe(
        tap(authToken => this.authTokenStore.authToken = authToken),
        catchError(e => {
          this.logout();
          return new Subject();
        })
      );
  }

  /**
   * Load user profile with LocalStorage stored authToken on application startup (in gards).
   *
   * @method load
   * @access public
   * @return {Observable<boolean>} // user is logged in success
   */
  load(): Observable<boolean> {
    if (this.authTokenStore.authToken) {
      // Try get user profile with OAuth access token
      return this.getUser()
        .pipe(
          take(1),
          map((user: AuthUser) => {
            // If backend user authorization is valid, authenticate user in frontend
            this.setAuth(user);
            return true;
          }),
          catchError(() => {
            // If backend user authorization is invalid, clear frontend auth service
            this.clearAuth();
            return of(false);
          })
        );

    } else {
      // If not exists authenticated flag in localstorage, clear frontend auth service
      this.clearAuth();
      return of(false);
    }
  }

  /**
   * Set access token from oAuth to get user profile and authenticate user on client side
   *
   * @param {AuthToken} authToken
   * @return {Observable<boolean>}
   */
  login(authToken: AuthToken): Observable<boolean> {
    // Set OAuth authorization token subject for Authorization header in the store, then in the interceptor
    this.authTokenStore.authToken = authToken;

    return this.getUser()
      .pipe(
        take(1),
        map((user: AuthUser) => {
          this.setAuth(user);
          return this.checkAdminPermissions(user);
        }),
        catchError((error: AppError) => {
          this.clearAuth();
          return throwError(error);
        })
      );
  }

  /**
   * Logout the user from frontend and forward to external logout page
   *
   * @method logout
   * @access public
   * @param {AuthLogoutRedirect} [_redirectParams]
   * @param {string} partnerId
   */
  logout(_redirectParams?: AuthLogoutRedirect, partnerId?: AAGUID): void {
    let redirectParams: AuthLogoutRedirect = _redirectParams;

    const accessToken: string = this.authTokenStore.authToken.access_token;
    this.clearAuth();

    if (partnerId) {
      redirectParams = new AuthLogoutRedirect({
        returnUrl: this.getLoginUrl(partnerId),
        externalRedirect: true,
      });

    } else if (redirectParams && redirectParams.clientOnly) {
      this.router.navigate([this.logoutRedirectURL], {
        queryParams: {
          ...redirectParams.params,
          returnUrl: redirectParams.returnUrl,
        },
      });
      return;
    }

    this.redirectToLogoutPage(accessToken, redirectParams);
  }

  /**
   * Compile login page URL
   *
   * @method getLoginUrl
   * @access public
   * @param {AAGUID} partnerId
   */
  getLoginUrl(partnerId?: AAGUID, redirectParams?: IAuthRedirectParams): string {
    let redirect_uri = `${this.document.location.origin}${this.loginParams.redirect_uri}`;

    if (redirectParams?.returnUrl && !/\/logout\//.test(redirectParams.returnUrl)) {
      redirect_uri += `?returnUrl=${encodeURIComponent(redirectParams.returnUrl)}`;
    }

    const httpLoginParams: HttpParams = new EncodedHttpParams({
      fromObject: {
        ...this.loginParams,
        ...partnerId && { partnerId },
        redirect_uri,
        ...redirectParams?.confirmIdentity && { identityconfirmed: 'true' },
        ...redirectParams?.configPartnerId && { configPartnerId: redirectParams.configPartnerId },
      },
    });

    return `${this.loginURL}?${httpLoginParams.toString()}`;
  }

  /**
   * Forward user to login page
   *
   * @method redirectToLoginPage
   * @access public
   * @param {AAGUID} partnerId
   */
  redirectToLoginPage(partnerId: AAGUID, redirectParams?: IAuthRedirectParams): void {
    if (environment.isMock) {
      this.router.navigate([this.loginParams.redirect_uri], { queryParams: AUTH_TOKEN });
      return;
    }
    this.document.location.href = this.getLoginUrl(partnerId, redirectParams);
  }

  /**
   * Forward user to logout page
   *
   * @method redirectToLogoutPage
   * @access public
   * @param {string} accessToken
   * @param {AuthLogoutRedirect} [redirectParams]
   */
  redirectToLogoutPage(accessToken: string, redirectParams?: AuthLogoutRedirect): void {
    if (environment.isMock) {
      this.router.navigate([this.logoutRedirectURL]);
      return;
    }

    let logoutParams = `id_token_hint=${accessToken}`;

    if (redirectParams && redirectParams.returnUrl) {
      let redirectUri = (redirectParams.externalRedirect ? '' : this.document.location.origin) + redirectParams.returnUrl;
      if (redirectParams.params) {
        const encodedParams: string = Object.keys(redirectParams.params).map((key: string) => `${key}=${redirectParams.params[key]}`).join('&');
        redirectUri += `#${encodedParams}`;
      }
      logoutParams += `&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;
    }

    this.document.location.href = `${this.logoutURL}?${logoutParams}`;
  }

  /**
   * Refresh current user profile datas in store
   *
   * @method refreshProfile
   * @access public
   * @return {Observable<AuthUser>}
   */
  refreshProfile(): Observable<AuthUser> {
    return this.getUser()
      .pipe(
        take(1),
        tap((user: AuthUser) => this.authUserStore.patchState(user))
      );
  }

  redirectToDiyPartnerRegistrationPage(partnerId: AAGUID, cancelUrl?: string): void {
    const queryParams: HttpParams = new EncodedHttpParams({
      fromObject: {
        cancelUrl: cancelUrl || this.document.location.origin,
        returnUrl: `${this.document.location.origin}${this.loginParams.redirect_uri}`,
        partnerId: partnerId,
      },
    });

    this.document.location.href = `${this.appConfig.accountSiteUrl}/#/create-partner?${queryParams.toString()}`;
  }

  getPartnerIdFromJwtAuthUrl(rawUrl: string, isRegion = false): string | null {
    const token = this.coreAuthService.getJwtFromUrl(rawUrl);

    if (typeof token === 'string') {
      return this.coreAuthService.getJwtPartnerId(token, isRegion);
    }

    return null;
  }

}
