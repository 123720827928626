import { ICalloutPermission } from '@shared/interfaces/callout/callout-permission.interface';

export class CalloutPermission implements ICalloutPermission {

  readonly button: string | string[];
  readonly description: string | string[];

  constructor(iCalloutPermission: ICalloutPermission) {
    this.button = iCalloutPermission.button;
    this.description = iCalloutPermission.description;
  }

}
