// Workaround for disabling ripple effect on MatChip to avoid disappearing elements inside the chip
import { AfterViewInit, Directive, ElementRef, Optional, Renderer2, Self } from '@angular/core';
import { MatChip } from '@angular/material/chips';

@Directive({
  selector: '[uiKitChipRippleDisable]',
})
export class UiKitChipRippleDisableDirective implements AfterViewInit {

  constructor(
    @Optional() @Self() private matChip: MatChip,
    @Optional() @Self() private hostElement: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    if (this.hostElement?.nativeElement && this.matChip?.disableRipple) {
      this.renderer.removeAttribute(this.hostElement.nativeElement, 'mat-ripple-loader-uninitialized');
    }
  }

}
