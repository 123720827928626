<div class="main">

  <!-- Title -->
  <div class="row align-spaced">
    <div class="columns text-center">
      <h2 mat-dialog-title>{{ 'booking_calendar_chooser_question' | translate }}</h2>
    </div>
  </div>

  <form [formGroup]="form">
    <!-- RADIO BUTTONS -->
    <div class="row align-center">
      <mat-radio-group formControlName="createType">
        <mat-radio-button checked="true" value="booking"> {{ 'booking_label' | translate }}</mat-radio-button>
        <mat-radio-button checked="false" value="work-break">{{ 'work_break_label' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Buttons -->
    <div class="row align-center margin-top-2">
      <button
        class="button secondary margin-right-1"
        [mat-dialog-close]="{ confirmed: false, formData: null }">
        {{ 'label_cancel' | translate }}
      </button>

      <button
        class="button"
        [mat-dialog-close]="{ confirmed: true, formData: form.value }">
        {{ 'label_continue_next' | translate }}
      </button>
    </div>
  </form>

</div>
