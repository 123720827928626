import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AAGUID } from '@interticket/core';
import { ResourceHeader } from '@services/bookings';
import { CalendarPermission } from '@services/permissions';
import { adapterFactory as luxon } from '@shared/modules/calendar/adapters/luxon-adapter';
import { DateTime } from 'luxon';
import { IBookingWorkbreakDialogConfig } from '../../interfaces/booking-workbreak-dialog-config.interface';

@Component({
  selector: 'booking-work-break-dialog',
  templateUrl: './booking-work-break-dialog.component.html',
  styleUrls: ['./booking-work-break-dialog.component.scss'],
})
export class BookingWorkBreakDialogComponent implements OnInit {

  form: FormGroup;
  resourcesFormControl: FormControl;
  checkboxList = [];
  calendarPermission = CalendarPermission;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBookingWorkbreakDialogConfig,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialog,
  ) { }

  get resourcesArray(): FormArray {
    return this.form.get('resources') as FormArray;
  }

  ngOnInit(): void {
    !this.data.event ? this.setInitialFormForCreate() : this.setInitialFormForEdit();
  }

  setInitialResourceCheckboxes(): void {
    this.data.resourceList.forEach((_resource: ResourceHeader) => {
      const formControl = new FormControl();
      formControl.setValue(false);
      this.checkboxList.push(formControl);
    });

    this.form = this.formBuilder.group(
      {
        ...this.form.value,
        name: [this.form.value.name, Validators.required],
        checkboxes: this.formBuilder.array(this.checkboxList),
        resources: this.formBuilder.array(this.data.resourceId ? [this.data.resourceId] : [], Validators.required),
      }
    );
  }

  setInitialFormForCreate(): void {
    this.form = this.formBuilder.group({
      start: [DateTime.fromJSDate(this.data.eventDate), Validators.required],
      end: [DateTime.fromJSDate(luxon().addHours(this.data.eventDate, 1)), Validators.required],
      name: [null, Validators.required],
      isGeneral: [false],
      resources: this.formBuilder.array([], Validators.required),
    });
    this.resourcesFormControl = new FormControl([]);
  }

  setInitialFormForEdit(): void {
    this.form = this.formBuilder.group({
      id: [this.data.event.event.event.id],
      start: [DateTime.fromJSDate(this.data.event.event.event.start), Validators.required],
      end: [DateTime.fromJSDate(this.data.event.event.event.end), Validators.required],
      name: [this.data.event.event.event.title, Validators.required],
      isGeneral: [this.data.event.event.event.meta.isGeneral],
      resources: this.formBuilder.array([], Validators.required),
    });
    this.resourcesFormControl = new FormControl(this.data.assignedResources);

    if (this.data.assignedResources) {
      this.data.assignedResources.forEach((resourceId: AAGUID) => this.resourcesArray.push(this.formBuilder.control(resourceId)));
    }
  }

  onChangeResourceCheckbox(resourceId: AAGUID, resourceChecked: boolean): void {
    if (resourceChecked) {
      this.resourcesArray.push(this.formBuilder.control(resourceId));
    } else {
      this.resourcesArray.removeAt(this.resourcesArray.value.findIndex((resource: AAGUID) => resource === resourceId));
    }
  }

  onChangeIsGeneral(event: MatCheckboxChange): void {
    event.checked ? this.selectAllResourceCheckboxes() : this.unSelectAllResourceCheckboxes();
  }

  selectAllResourceCheckboxes(): void {
    const allResourceIds = this.data.resourceList.map((resource: ResourceHeader) => resource.id);
    this.resourcesFormControl = new FormControl(allResourceIds);
    this.resourcesArray.clear();
    allResourceIds.forEach((resourceId: AAGUID) => this.resourcesArray.push(this.formBuilder.control(resourceId)));
  }

  unSelectAllResourceCheckboxes(): void {
    this.resourcesFormControl = new FormControl([]);
    this.resourcesArray.clear();
  }

  onClickDelete(): void {
    this.data.dialogService.openConfirmDialog((confirmed: boolean) => {
      if (confirmed) {
        this.dialogRef.closeAll();
        this.data.deleteWorkBreakCallback(this.data.event.event.event.id);
      }
    }, { data: { title: 'confirm_delete_work_break' } }
    );
  }

}
