import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AAGUID, CountryCode, countryListByRegion, RegionCode, TranslateService, Utils } from '@interticket/core';
import { IRegionsFeatureSelectItem } from '../../../../select-group/interfaces/regions-feature-select-item.interface';
import { RegionsFeatureSelectGroup } from '../../../../select-group/models/regions-feature-select-group.model';
import { REGION_SORT_PATTERN } from '../../../configs/dialog.config';
import { ISelectGroupDialogConfig } from '../../../interfaces/select-group-dialog-config.interface';
import { SelectGroupDialogBase } from '../select-group-dialog-base.component';

@Component({
  selector: 'country-select-group-dialog',
  templateUrl: './country-select-group-dialog.component.html',
  styleUrls: ['./country-select-group-dialog.component.scss'],
})
export class CountrySelectGroupDialogComponent extends SelectGroupDialogBase<RegionCode, CountryCode> {

  isAllSelected: boolean;
  allFeatureIds: CountryCode[] = [].concat(...Object.values(countryListByRegion));

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: ISelectGroupDialogConfig,
    protected dialogRef: MatDialogRef<SelectGroupDialogBase<RegionCode, CountryCode>>,
    protected translate: TranslateService
  ) {
    super();
    this.dialogData.list = this.getCountryList();
  }

  private getCountryList(): RegionsFeatureSelectGroup<RegionCode>[] {
    const countryList: RegionsFeatureSelectGroup[] = Object.keys(countryListByRegion)
      .map((regionCode: RegionCode) => this.getRegionGroup(
        regionCode,
        Utils.sortByAlphabet(countryListByRegion[regionCode].map(countryCode => this.getCountryListItem(countryCode)), 'translation', this.translate.currentLang)
      ));

    return Utils.sortByPattern(countryList, 'id', REGION_SORT_PATTERN);
  }

  private getCountryListItem(countryCode: CountryCode): IRegionsFeatureSelectItem {
    return {
      id: countryCode as AAGUID,
      name: countryCode,
      label: 'label_country_' + countryCode,
      translation: this.translate.get('label_country_' + countryCode),
    };
  }

  private getRegionGroup(regionCode: RegionCode, countries: IRegionsFeatureSelectItem[]): RegionsFeatureSelectGroup<RegionCode> {
    return new RegionsFeatureSelectGroup<RegionCode>({
      id: regionCode,
      label: 'label_region_' + regionCode,
      features: countries,
      translation: this.translate.get('label_region_' + regionCode),
    });
  }

}
