import { ILocaleFilter } from '@core/interfaces/datetime/locale-filter.interface';
import { FilterParams, LanguageCode, TimeZone } from '@interticket/core';

export class LocaleFilter extends FilterParams implements ILocaleFilter {

  readonly lang: LanguageCode;
  readonly timezone: TimeZone;

  constructor(iLocaleFilter: ILocaleFilter) {
    super();
    this.lang = iLocaleFilter.lang;
    this.timezone = iLocaleFilter.timezone;
  }

}
