import { Pipe, PipeTransform } from '@angular/core';
import { IOTRSTaxClassView, OTRSUtil } from '@services/otrs';

@Pipe({
  name: 'taxclass',
})
export class TaxClassPipe implements PipeTransform {

  transform(taxClass: IOTRSTaxClassView): string {
    return OTRSUtil.createTaxClassLabel(taxClass);
  }

}
