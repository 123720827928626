import { IBankAccount } from '../../interfaces/bank-account/bank-account.interface';

export class BankAccount implements IBankAccount {

  readonly bankName: string;
  readonly bankAccountNumber: string;
  readonly iban: string;
  readonly swift: string;

  constructor(iBankAccount: IBankAccount) {
    this.bankName = iBankAccount.bankName;
    this.bankAccountNumber = iBankAccount.bankAccountNumber;
    this.iban = iBankAccount.iban;
    this.swift = iBankAccount.swift;
  }

}
