<div class="icon-list">
  <ui-kit-button-icon
    *ngFor="let icon of iconList"
    [isDisabled]="disabledIconMap[icon]"
    [class.selected]="control.getRawValue() === icon"
    [size]="'medium'"
    [palette]="'primary'"
    [icon]="icon"
    (onClick)="selectIcon(icon)">
  </ui-kit-button-icon>
</div>
<app-show-errors [control]="control"></app-show-errors>
