import { IUserView } from '../interfaces/user-view.interface';
import { UserListItem } from './user-list-item.model';

export class UserView extends UserListItem implements IUserView {

  readonly phoneNumber: string;

  constructor(iUserView: IUserView) {
    super(iUserView);
    this.phoneNumber = iUserView.phoneNumber;
  }

}
