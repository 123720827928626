import { HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AAGUID, CoreImageService, HttpService, Mock, MockPoll } from '@interticket/core';
import { IFolderedImageList, IFolderedImageUpload } from '@interticket/template-layout-editor-angular';
import { Observable } from 'rxjs';
import { MediaImageType } from '../enums/media-image-type.enum';
import { IFileChunkUploaderRequest } from '../interfaces/file-chunk-uploader-request.interface';
import { IMediaType } from '../interfaces/media-type.interface';
import { IMediaUploadResponse } from '../interfaces/media-upload-response.interface';
import { IMediaVideoStatus } from '../interfaces/media-video-status.interface';
import { MEDIA_BLOB_RESPONSE, MEDIA_IMAGE_FOLDER, MEDIA_IMAGE_FOLDER_IMAGE_LIST, MEDIA_IMAGE_GUID, MEDIA_IMAGE_ID, MEDIA_STREAM_KEY, MEDIA_TYPE_INTERFACE, MEDIA_UPLOAD_VIDEO_URL, MEDIA_VIDEO_STATUS } from '../mocks/media.mock';
import { MediaServiceVersion } from '../types/media-service-version.type';

@Injectable({
  providedIn: 'root',
})
export class MediaSourceService extends CoreImageService {

  constructor(
    private httpService: HttpService
  ) {
    super();
  }

  /* eslint-disable require-decorator/require-decorator */

  private getBaseUrl(version: MediaServiceVersion = 'v2'): string {
    return `/${version}/media`;
  }

  private createBaseFormData(file: File | Blob): FormData {
    const formData: FormData = new FormData();
    formData.append('image', file);
    formData.append('mimeType', file.type);
    return formData;
  }

  getLegacyImageUrl(guid?: AAGUID): string {
    return guid ? `/api${this.getBaseUrl('v1')}/${guid}` : '';
  }

  /* eslint-enable require-decorator/require-decorator */

  @Mock(MEDIA_IMAGE_ID)
  uploadImage(file: File | Blob): Observable<IMediaUploadResponse> {
    return this.httpService.post<IMediaUploadResponse>(this.getBaseUrl('v2'), this.createBaseFormData(file));
  }

  @Mock(null)
  uploadImageWithProgress(file: File): Observable<HttpEvent<IMediaUploadResponse>> {
    return this.httpService.post<HttpEvent<IMediaUploadResponse>>(this.getBaseUrl('v2'), this.createBaseFormData(file), {
      reportProgress: true,
      observe: 'events',
    });
  }

  @Mock(MEDIA_TYPE_INTERFACE)
  uploadMedia(file: File): Observable<IMediaType> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('mimeType', file.type);

    return this.httpService.post<IMediaType>(this.getBaseUrl('v3'), formData);
  }

  @Mock(MEDIA_BLOB_RESPONSE)
  getMedia(fileId: AAGUID): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.httpService.get<HttpResponse<Blob>>(`${this.getBaseUrl('v2')}/${fileId}`, options);
  }

  @Mock(MEDIA_IMAGE_GUID)
  uploadImageWithType(file: File, type: MediaImageType): Observable<{ imageDataGuid: AAGUID }> {
    return this.httpService.post<{ imageDataGuid: AAGUID }>(`${this.getBaseUrl('v1')}/${type}`, this.createBaseFormData(file));
  }

  @Mock(MEDIA_IMAGE_FOLDER)
  uploadFolderedImage(folder: string, file: File): Observable<IFolderedImageUpload> {
    const formData = this.createBaseFormData(file);
    formData.append('folder', folder);

    return this.httpService.post<IFolderedImageUpload>(`${this.getBaseUrl('v3')}/folder`, formData);
  }

  @Mock()
  deleteImage(guid: AAGUID, version: MediaServiceVersion): Observable<Response> {
    return this.httpService.delete<Response>(`${this.getBaseUrl(version)}/${guid}`);
  }

  @Mock()
  deleteMedia(guid: AAGUID, version: MediaServiceVersion): Observable<Response> {
    return this.httpService.delete<Response>(`${this.getBaseUrl(version)}/${guid}`);
  }

  @Mock(MEDIA_BLOB_RESPONSE)
  downloadImage(id: AAGUID, version: MediaServiceVersion): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.httpService.get<HttpResponse<Blob>>(`${this.getBaseUrl(version)}/${id}`, options);
  }

  @Mock(MEDIA_BLOB_RESPONSE)
  downloadSurveyImage(id: AAGUID, version: MediaServiceVersion): Observable<HttpResponse<Blob>> {
    const options = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.httpService.get<HttpResponse<Blob>>(`${this.getBaseUrl(version)}/survey/${id}`, options);
  }

  @Mock(null)
  uploadVideo(chunk: Blob, chunkData: IFileChunkUploaderRequest, videoUrl: string): Observable<HttpEvent<Response>> {
    const chunkEnd: number = chunkData.rangeStart + chunk.size - 1;

    let header: HttpHeaders = new HttpHeaders();
    header = header
      .append('content-range', `bytes ${chunkData.rangeStart}-${chunkEnd}/${chunkData.size}`)
      .append('content-type', chunk.type);

    return this.httpService.put<HttpEvent<Response>>(videoUrl, chunk, {
      reportProgress: true,
      observe: 'events',
      headers: header,
    }, { static: true });
  }

  @MockPoll(MEDIA_VIDEO_STATUS, 'Poll - Video status')
  getVideoStatus(programOrEventId: AAGUID, version: MediaServiceVersion): Observable<IMediaVideoStatus> {
    return this.httpService.get<IMediaVideoStatus>(`${this.getBaseUrl(version)}/stream/${programOrEventId}/status`);
  }

  @Mock()
  deleteVideo(programOrEventId: AAGUID, version: MediaServiceVersion): Observable<Response> {
    return this.httpService.delete<Response>(`${this.getBaseUrl(version)}/stream/${programOrEventId}`);
  }

  @Mock(MEDIA_STREAM_KEY)
  getStreamKey(eventId: AAGUID): Observable<{ streamKey: string }> {
    return this.httpService.get<{ streamKey: string }>(`${this.getBaseUrl('v2')}/stream/key/${eventId}`);
  }

  @Mock(MEDIA_UPLOAD_VIDEO_URL)
  getUploadVideoUrl(programOrEventId: AAGUID, fileName: string, version: MediaServiceVersion): Observable<{ url: string }> {
    if (version === 'v2') {
      return this.httpService.get<{ url: string }>(`${this.getBaseUrl('v2')}/stream/video-upload-url/${programOrEventId}`);
    }
    return this.httpService.post<{ url: string }>(`${this.getBaseUrl(version)}/stream/video-upload-url/${programOrEventId}`, { fileName });
  }

  @Mock(MEDIA_IMAGE_FOLDER_IMAGE_LIST)
  getFolderedImageList(folder: string): Observable<IFolderedImageList> {
    const params = new HttpParams({ fromObject: { folder } });
    return this.httpService.get<IFolderedImageList>(`${this.getBaseUrl('v3')}/folder`, { params });
  }

}
