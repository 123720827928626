import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthTokenStoreService } from '@core/services/store/auth-token-store.service';

@Injectable()
export class NoAuthGuard {

  constructor(
    private router: Router,
    private authTokenStore: AuthTokenStoreService,
  ) { }

  canActivate(): boolean {
    if (this.authTokenStore.isAuthenticated) {
      this.router.navigate(['admin']);
    }

    return !this.authTokenStore.isAuthenticated;
  }

}
