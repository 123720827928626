export enum AuditoriumsPermission {
  LIST = 'venues.list.and.search.auditorium',
  DETAILS = 'venues.view.auditorium',
  EDIT = 'venues.edit.auditorium',
  DELETE = 'venues.delete.auditorium',
  CREATE_DYNAMIC = 'venues.create.new.venue.and.dynamic.auditorium',
  CREATE_STRUCTURED = 'venues.create.new.venue.and.structured.auditorium',

  // ToDo
  PRICING_TEMPLATE_GET_ALL = 'venue.auditoriums.pricetemplate.list',
  PRICING_TEMPLATE_GET_BY_ID = 'venue.auditoriums.pricetemplate.view',
  PRICING_TEMPLATE_CREATE = 'venue.auditoriums.pricetemplate.create',
  PRICING_TEMPLATE_UPDATE = 'venue.auditoriums.pricetemplate.update',
  PRICING_TEMPLATE_DELETE = 'venue.auditoriums.pricetemplate.delete'
}
