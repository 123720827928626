import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICONS_CONFIG } from '@shared/configs/icons.config';

type DisabledIconMap = Record<string, true>;

@Component({
  selector: 'ui-kit-icon-selector',
  templateUrl: './ui-kit-icon-selector.component.html',
  styleUrls: ['./ui-kit-icon-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitIconSelectorComponent {

  @Input() control: FormControl<string>;
  @Input() set disabledIcons(disabledIcons: string[]) {
    this.disabledIconMap = this.buildDisabledIconMap(disabledIcons);
  }

  disabledIconMap: DisabledIconMap = {};

  readonly iconList: string[] = ICONS_CONFIG.map(value => value.name);

  selectIcon(icon: string): void {
    if (!this.control) {
      return;
    }
    this.control.patchValue(icon);
    this.control.markAsDirty();
  }

  private buildDisabledIconMap(disabledIcons: string[]): DisabledIconMap {
    return disabledIcons.reduce((result, icon) => {
      result[icon] = true;
      return result;
    }, {} as DisabledIconMap);
  }

}
