import { Component } from '@angular/core';

@Component({
  selector: 'custom-sidebar-backdrop',
  templateUrl: './custom-sidebar-backdrop.component.html',
  styleUrls: ['./custom-sidebar-backdrop.component.scss'],
})
export class CustomSidebarBackdropComponent {

}
