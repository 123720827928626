import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { UiKitPalette } from '../../../enums/ui-kit-palette.enum';
import { UiKitSize } from '../../../enums/ui-kit-size.enum';
import { UiKitButtonComponent } from '../ui-kit-button/ui-kit-button.component';

@Component({
  selector: 'ui-kit-button-icon',
  templateUrl: '../ui-kit-button/ui-kit-button.component.html',
  styleUrls: ['../ui-kit-button/ui-kit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'no-margin' },
})
export class UiKitButtonIconComponent extends UiKitButtonComponent {

  @Input() readonly palette: UiKitPalette = UiKitPalette.SECONDARY;
  @Input() readonly size: UiKitSize = UiKitSize.MEDIUM;
  @Input() readonly isIconOnly: boolean = true;
  @Input() readonly isFloat: boolean = true;
  @Input() readonly isRounded: boolean = true;
  @Input() readonly tooltip: string = '';
  @Input() readonly tooltipPosition: TooltipPosition = 'below';

}
