import { Injectable } from '@angular/core';
import { MapGeocoder, MapGeocoderResponse } from '@angular/google-maps';
import { Address } from '@interticket/core';
import { Observable } from 'rxjs';

// declare var google: any

@Injectable()
export class GoogleMapsService {

  // constructor(private loader: MapsAPILoader) {}
  constructor(private geocoder: MapGeocoder) { }

  /**
   * Geocoding
   *
   * Geocoding is the process of converting addresses (like "1600 Amphitheatre Parkway, Mountain View, CA")
   * into geographic coordinates (like latitude 37.423021 and longitude -122.083739),
   * which you can use to place markers or position the map.
   *
   * {@link} https://developers.google.com/maps/documentation/javascript/geocoding
   */
  public getLatLng(address: Address): Observable<any> {
    return new Observable((observer: any) => {
      try {
        // at this point the variable google may be still undefined (google maps scripts still loading)
        // so load all the scripts, then...
        // this.loader.load().then(() => {
        // const geocoder = new google.maps.Geocoder()
        this.geocoder
          .geocode({ address: address.boundForMap() })
          .subscribe(({ results, status }: MapGeocoderResponse) => {
            // (results: any, status: any) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const coordinate = results[0].geometry.location.toJSON();
              observer.next(coordinate);
              observer.complete();
            } else {
              if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                observer.error('Address not found!');
              } else {
                observer.error(status);
              }
              observer.complete();
            }
            // },
          });
        // });
      } catch (error) {
        observer.error('error getLatLng ' + error);
        observer.complete();
      }
    });
  }

}
