import { Pipe, PipeTransform } from '@angular/core';
import { AAGUID } from '@interticket/core';
import { IChangeCounterResult } from '../interfaces/change-counter/change-counter-result.interface';

@Pipe({
  name: 'changeCounter',
})
export class ChangeCounterPipe implements PipeTransform {

  transform(from: AAGUID[], to: AAGUID[]): IChangeCounterResult {
    let deleted = 0;
    let uploaded = 0;

    const toIdsSet = new Set(to);
    from.forEach((id) => {
      if (!toIdsSet.has(id)) {
        deleted++;
      }
    });

    const fromIdsSet = new Set(from);
    to.forEach((id) => {
      if (!fromIdsSet.has(id)) {
        uploaded++;
      }
    });

    return { deleted, uploaded };
  }

}
