import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-kit-preview-status-icon-row',
  templateUrl: './ui-kit-preview-status-icon-row.component.html',
  styleUrls: ['./ui-kit-preview-status-icon-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitPreviewStatusIconRowComponent {

  @Input() readonly isEnabled: boolean = true;
  @Input() readonly labels: { enabled: string; disabled: string };

}
