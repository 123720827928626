export const QrCode = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.66667 2.00024H2.66667C2.29848 2.00024 2 2.29872 2 2.66691V4.66691C2 5.0351 2.29848 5.33358 2.66667 5.33358H4.66667C5.03486 5.33358 5.33333 5.0351 5.33333 4.66691V2.66691C5.33333 2.29872 5.03486 2.00024 4.66667 2.00024Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.3337 2.00024H11.3337C10.9655 2.00024 10.667 2.29872 10.667 2.66691V4.66691C10.667 5.0351 10.9655 5.33358 11.3337 5.33358H13.3337C13.7018 5.33358 14.0003 5.0351 14.0003 4.66691V2.66691C14.0003 2.29872 13.7018 2.00024 13.3337 2.00024Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.66667 10.6669H2.66667C2.29848 10.6669 2 10.9653 2 11.3335V13.3335C2 13.7017 2.29848 14.0002 2.66667 14.0002H4.66667C5.03486 14.0002 5.33333 13.7017 5.33333 13.3335V11.3335C5.33333 10.9653 5.03486 10.6669 4.66667 10.6669Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.0003 10.6669H12.0003C11.6467 10.6669 11.3076 10.8073 11.0575 11.0574C10.8075 11.3074 10.667 11.6466 10.667 12.0002V14.0002" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 14.0002V14.0069" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.00033 4.66687V6.66687C8.00033 7.02049 7.85985 7.35963 7.6098 7.60968C7.35975 7.85973 7.02061 8.0002 6.66699 8.0002H4.66699" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2 8.00024H2.00667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 2.00024H8.00667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.6669V10.6735" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.667 8.00024H11.3337" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14 8.00024V8.00691" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 14.0003V13.3336" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
