export enum ProgramsPermission {
  ASSIGN_CUSTOM_FIELDS = 'programsv2.assign.custom.fields.to.program.-.v2',
  CREATE = 'programs.create.new.program',
  CREATE_LIVESTREAM = 'programs.create.livestream.program',
  CREATE_LIVESTREAM_VIDEO = 'programs.create.livestream.video.program',
  CREATE_VIDEO = 'programs.create.video.program',
  DELETE = 'programs.delete.program',
  DETAILS = 'programs.view.programs',
  EDIT = 'programs.edit.programs',
  LIST = 'programs.list.and.search.programs',
  MANAGE_VIDEO = 'programsv2.upload.and.delete.video.to.program.-.v2',
  REGIONAL_RESTRICTION_SET = 'programs.set.regional.restriction.for.program',
  REGIONAL_RESTRICTION_VIEW = 'programs.view.regional.restriction.at.program',
  UPLOAD_VIDEO = 'programs.upload.video.to.program',
  VIEW_CUSTOM_FIELDS = 'programsv2.view.custom.fields.-.v2',
}
