import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-badge',
  templateUrl: './ui-kit-badge.component.html',
  styleUrls: ['./ui-kit-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitBadgeComponent {

  @Input() readonly value: string | number;
  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;
  @Input() readonly isCircle: boolean = true;
  @Input() readonly hasHover: boolean = false;

  @Output() readonly hoverClick = new EventEmitter<void>();

  @HostBinding('style') get paletteColors(): SafeStyle {
    const palette: UiKitPalette = this.palette || UiKitPalette.PRIMARY;
    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-badge-color: var(--${palette}-color);
    `);
  }

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

}
