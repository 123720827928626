import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '@shared/models/icon/icon.model';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPickerComponent implements OnInit {

  selectedIcon: Icon;

  @Input() readonly icons: Icon[];
  @Input() set selectedIconName(selectedIconName: string) {
    if (selectedIconName && this.icons && this.icons.length) {
      this.selectedIcon = this.icons.find((icon: Icon) => icon.name === selectedIconName) || null;
    } else {
      this.selectedIcon = null;
    }
  }

  @Output() selectedIconNameChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    if (!this.selectedIcon) {
      this.selectedIconName = this.icons[0].name;
    }
  }

  changeIcon(iconName: string): void {
    this.selectedIconName = iconName;
    this.selectedIconNameChange.emit(iconName);
  }

}
