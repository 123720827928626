<ng-container *ngFor="let group of list">

  <!-- GROUP FILTERING CONDITION -->
  <ng-container *ngIf="hasNoFilter || !group.hidden">

    <div class="select-group" *ngIf="showMode === showModes.SELECTED ? group.hasSelected : showMode === showModes.UNSELECTED ? !group.selected : true">

      <!-- GROUP NAME -->
      <div class="select-row select-header bold flex-space-between"
        [class.disable-edit]="disableEdit"
        [class.selected]="showMode === showModes.ALL && group.selected"
        (click)="!disableEdit && toggleGroup(group)">
        {{ group.label | translate }}
        <mat-icon *ngIf="!disableEdit">{{ (showMode === showModes.ALL && !group.selected) ? 'add_circle' : 'cancel' }}</mat-icon>
      </div>

      <!-- ITEMS -->
      <ng-container *ngFor="let item of group.features">

        <!-- ITEM FILTERING CONDITION -->
        <ng-container *ngIf="hasNoFilter || !item.hidden || group.match">

          <!-- ITEM NAME -->
          <div class="select-row flex-space-between"
            *ngIf="showMode === showModes.SELECTED ? item.selected : showMode === showModes.UNSELECTED ? !item.selected : true"
            [class.disable-edit]="disableEdit"
            [class.selected]="showMode === showModes.ALL && item.selected"
            (click)="!disableEdit && toggleItem(item, group)">
            {{ item.label | translate }}
            <mat-icon class="grey" *ngIf="!disableEdit">{{ item.selected ? 'cancel' : 'add_circle' }}</mat-icon>
          </div>

        </ng-container>

      </ng-container>

    </div>

  </ng-container>

</ng-container>
