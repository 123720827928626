import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@interticket/core';

@Injectable()
export class PaginatorInlService extends MatPaginatorIntl {

  translate: TranslateService;
  itemsPerPageLabel = 'Items per page';
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';
  getRangeLabel = function (page, pageSize, _length): string {
    const of = this.translate ? this.translate.get('stem_general_paginator_of') : 'stem_general_paginator_of';
    if (_length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + _length;
    }

    const length = Math.max(_length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService): void {
    this.translate = translate;

    this.translate.onLangChanged.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translate.get('stem_general_paginator_items_per_page');
    this.nextPageLabel = this.translate.get('stem_general_paginator_next_page');
    this.previousPageLabel = this.translate.get('stem_general_paginator_prev_page');
    this.firstPageLabel = this.translate.get('stem_general_paginator_first_page');
    this.lastPageLabel = this.translate.get('stem_general_paginator_last_page');
  }

}
