export enum TranslationsManagementPermission {
  LIST = 'translations.list.translations',
  CREATE = 'translations.create.translation',
  UPDATE = 'translations.update.translation',
  DELETE = 'translations.delete.translation',
  CREATE_TAG = 'translations.create.tag',
  LIST_LANGUAGES = 'translations.list.languages',
  CREATE_LANGUAGE = 'translations.create.language',
  UPDATE_LANGUAGE = 'translations.update.language',
  AUTO_TRANSLATE_MANY = 'translations.auto.translate.language',
  AUTO_TRANSLATE_BY_ID = 'translations.auto.translate.label',
  IMPORT = 'translations.import.region.translations',
  IMPORT_PLATFORM = 'translations.import.platform.translations',
  EXPORT = 'translations.export.translations',
  DISPLAY_LANGUAGE_LABELS = 'translations.display.language.labels',
}
