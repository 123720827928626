import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[uiKitStickyListener]',
})
export class UiKitStickyListenerDirective implements AfterViewInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();

  @Input() applyClassWhenSticky = 'element-sticky';
  @Input() scrollDirection: 'top-to-bottom' | 'bottom-to-top' = 'top-to-bottom';

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.scrollDirection === 'top-to-bottom') {
          const { top } = this.element.nativeElement.getBoundingClientRect();
          const offset = Number.parseInt(getComputedStyle(this.element.nativeElement).top, 10);

          this.renderer[top <= offset ? 'addClass' : 'removeClass'](this.element.nativeElement, this.applyClassWhenSticky);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
