import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'save-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'success'];
  @Input() readonly icon: string = 'done';
  @Input() readonly label: string = 'general_lang_save';
  @Input() readonly type: 'button' | 'submit' = 'submit';

}
