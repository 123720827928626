export enum OrganizerPermission {
  EDIT_CART_ITEM_HOLDER_SETTINGS= 'organizer.edit.cart.item.holder.settings',
  EDIT_DEFAULT_SETTINGS = 'organizer.edit.default.settings',
  EDIT_NTAK_SETTINGS = 'organizer.edit.ntak.settings',
  EDIT_SALES_SETTINGS = 'organizer.edit.sales.settings',
  EDIT_FACEBOOK_TRACKING_SETTINGS = 'organizer.edit.facebook.tracking.settings',
  EDIT_GOOGLE_TRACKING_SETTINGS = 'organizer.edit.tracking.settings',
  EDIT_WEBSITE_SETTINGS = 'organizer.edit.public.website.settings',
  EDIT_CHECKOUT_SURVEY_DETAILS= 'organizer.edit.checkout.survey.details',
}
