export enum DataPumpPipelinePermission {
  LIST = 'datapump.list.pipelines',
  LIST_TEMPLATES = 'datapump.list.pipeline.templates',
  VIEW = 'datapump.view.pipelines',
  CREATE = 'datapump.create.pipelines',
  EDIT = 'datapump.update.pipelines',
  COPY = 'datapump.copy.pipelines',
  DELETE = 'datapump.delete.and.restore.pipelines',
  RE_TRIGGER = 'datapump.retrigger.pipelines',
  VIEW_EXECUTIONS = 'datapump.view.pipeline.executions',
  VIEW_EXECUTIONS_LOGS = 'datapump.view.pipeline.executions.logs',
  START= 'datapump.start.pipelines',
}
