import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase, HttpStatusCode } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ApiErrorResponse, HTTP_AUTHORIZATION_REQUIRED, IApiError, RefreshStrategy, TranslateService } from '@interticket/core';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ErrorReportService } from '../error-handler/error-report.service';
import { AuthTokenStoreService } from '../store/auth-token-store.service';

/**
 * HTTP Interceptor
 *
 * HTTP Interceptor enables you to catch HTTP requests and responses so that you can modify them.
 * It is very useful especially when you want to add extra headers to all outgoing requests
 * or catch error responses from the server.
 *
 * {@link} https://theinfogrid.com/tech/developers/angular/building-http-interceptor-angular-5/
 */
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  private refreshStrategy: RefreshStrategy;

  constructor(
    private injector: Injector,
    private authService: AuthService,
    private authTokenStore: AuthTokenStoreService,
    private translateService: TranslateService,
  ) {
    this.refreshStrategy = new RefreshStrategy(
      () => this.authService.refresh(),
      request => this.authorizeRequest(request)
    );
  }

  private addXLangHeader(request: HttpRequest<any>): HttpRequest<any> {
    if (request.context.get(HTTP_AUTHORIZATION_REQUIRED) && this.translateService.currentLang) {
      return request.clone({ setHeaders: { 'X-Lang': this.translateService.currentLang } });
    }
    return request;
  }

  private addXTimezoneHeader(request: HttpRequest<any>): HttpRequest<any> {
    const timeZone = DateTime.local().zoneName;

    if (request.context.get(HTTP_AUTHORIZATION_REQUIRED)) {
      return request.clone({ setHeaders: { 'X-Timezone': timeZone } });
    }

    return request;
  }

  private authorizeRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (request.context.get(HTTP_AUTHORIZATION_REQUIRED) && this.authTokenStore.authToken) {
      return request.clone({ setHeaders: { Authorization: 'Bearer ' + this.authTokenStore.authToken.access_token } });
    }

    return request;
  }

  private isOAuthErrorResponse(errorResponse: HttpErrorResponse): boolean {
    return errorResponse.url?.includes('/oauth/');
  }

  private logHttpEvent(request: HttpRequest<any>, event: HttpEvent<any> | HttpResponseBase) {
    if (event instanceof HttpResponseBase) {
      const errorReportService = this.injector.get(ErrorReportService);
      errorReportService.pushHttpEvent({ request, response: event });
    }
  }

  private parseOAuthErrorResponse(errorResponse: HttpErrorResponse): HttpErrorResponse {
    const errorMessage: IApiError = {
      messages: [{ label: errorResponse.error.error_description }],
    };
    return {
      ...errorResponse,
      error: errorMessage,
    };
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithLang = this.addXLangHeader(request);
    const reqWithLangAndTimezone = this.addXTimezoneHeader(reqWithLang);
    const authReq = this.authorizeRequest(reqWithLangAndTimezone);

    return next.handle(authReq)
      .pipe(
        tap((event: HttpEvent<any>) => this.logHttpEvent(authReq, event)),
        catchError((error: HttpErrorResponse) => {
          if (this.authTokenStore.authToken && HttpStatusCode.Unauthorized === error.status) {
            return this.refreshStrategy.refresh(next, request, new ApiErrorResponse(error));
          }

          return throwError(error);
        }),
        catchError((error: HttpErrorResponse) => {
          // Log error event
          this.logHttpEvent(authReq, error);

          // If the user is not authenticated, log off him and redirect to Login page with the return url
          if (this.isOAuthErrorResponse(error)) {
            const oauthErrorResponse: HttpErrorResponse = this.parseOAuthErrorResponse(error);
            return throwError(new ApiErrorResponse(oauthErrorResponse));
          }

          // Return the error to the method that called it
          return throwError(new ApiErrorResponse(error));
        })
      ) as any;
  }

}
