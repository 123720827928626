<div class="row align-spaced text-center">
  <div class="columns small-12">
    <h2 mat-dialog-title>{{ data.title | translate }}</h2>
  </div>
  <div class="columns small-12">
    <form [formGroup]="modalForm" appForm novalidate autocomplete="off">
      <textarea appFormControl placeholder="{{ 'stemx_card_reason_label' | translate }}" class="textarea-field" formControlName="reason" autocomplete="off" autosize></textarea>
      <app-show-errors [control]="reason"></app-show-errors>

      <label *ngIf="data.revokeStatuses">{{ 'general_lang_status' | translate }}
        <mat-select class="custom-select" formControlName="status">
          <mat-option value="">{{ 'general_lang_select' | translate }}</mat-option>
          <mat-option *ngFor="let status of data.revokeStatuses" [value]="status.name">
            {{ status.label | translate }}
          </mat-option>
        </mat-select>
      </label>

    </form>
  </div>

  <div class="button-container">
    <button class="button alert" [mat-dialog-close]="null">{{ 'label_no' | translate }}</button>
    <button class="button success" [disabled]="reason.invalid" [mat-dialog-close]="{reason: reason.value, status: status?.value}">{{ 'label_yes' | translate }}</button>
  </div>
</div>
