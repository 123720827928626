import { ChangeDetectionStrategy, Component, HostListener, input, output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { SafeStyle } from '@angular/platform-browser';
import { UiKitIconBaseCardComponent } from '../ui-kit-icon-base-card/ui-kit-icon-base-card.component';

@Component({
  selector: 'ui-kit-icon-card',
  templateUrl: './ui-kit-icon-card.component.html',
  styleUrls: ['./ui-kit-icon-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[style]': 'paletteColors' },
})
export class UiKitIconCardComponent extends UiKitIconBaseCardComponent {

  readonly isSelected = input<boolean>();
  readonly isDisabled = input<boolean>();
  readonly isColumnLayout = input<boolean>(false);
  readonly isVisibleCornerIndicator = input<boolean>(false);
  readonly tooltip = input<string>('');
  readonly tooltipPosition = input<TooltipPosition>('above');

  readonly onClick = output<void>();

  override get paletteColors(): SafeStyle {
    const styles: string[] = [];

    if (this.palette()) {
      styles.push(
        `--card-color: var(--${this.palette()}-color);`,
        `--card-color-active: var(--${this.palette()}-color-active);`,
        `--card-color-active-outline: var(--${this.palette()}-color-active);`,
        `--card-color-background: var(--${this.palette()}-color-background);`
      );
    }

    return this.sanitizer.bypassSecurityTrustStyle(styles.join('\n'));
  }

  @HostListener('click') handleClick(): void {
    !this.isDisabled() && this.onClick.emit();
  }

}
