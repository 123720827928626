import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'password',
})
export class PasswordPipe implements PipeTransform {

  transform(password: string, asteriskChar?: string): string {
    return ((asteriskChar && asteriskChar[0]) || '*').repeat(password?.length);
  }

}
