import { computed, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AuthUserStoreService } from '@core/services/store/auth-user-store.service';
import { LanguageCode, StoreService, TranslateService, TranslationMap, UtilHtml } from '@interticket/core';
import { distinctUntilChanged } from 'rxjs';
import { IContentTranslationIndicatorListStrategy } from '../interfaces/content-translation-indicator-list-strategy.interface';
import { IContentTranslationStoreState } from '../interfaces/content-translation-store-state.interface';
import { ContentTranslationDefaultIndicatorList } from '../models/content-translation-default-indicator-list.model';

@Injectable({
  providedIn: 'root',
})
export class ContentTranslationStoreService extends StoreService<IContentTranslationStoreState> {

  private indicatorStrategy: IContentTranslationIndicatorListStrategy;
  private controlValueTransformer: (value: string, contentTranslationId?: string) => string;

  readonly translations$ = this.shareState((state) => state?.translations || {});
  readonly $translations = toSignal(this.translations$);
  readonly selectedLang$ = this.shareState((state) => state?.selectedLang).pipe(distinctUntilChanged());
  readonly $selectedLang = toSignal(this.selectedLang$);
  readonly indicators$ = this.shareState((state) => state?.indicators || []);
  readonly $indicators = toSignal(this.indicators$);
  readonly $placeholder = (contentTranslationId: string): Signal<string> => computed(() => {
    if (this.$selectedLang() === this.defaultLanguage) {
      return '';
    }
    return this.htmlContentCleaner(this.getTranslationValue(contentTranslationId, this.defaultLanguage));
  });

  constructor(
    private readonly authUserStore: AuthUserStoreService,
    private readonly translate: TranslateService,
  ) {
    super();
    this.setIndicatorStrategy();
    this.setState(this.defaultState);
  }

  get defaultState(): IContentTranslationStoreState {
    const languages = this.createLanguageList();
    return {
      indicators: [],
      languages,
      selectedLang: languages[0],
      translations: {},
    };
  }

  get translations(): Record<string, TranslationMap> {
    return this.state?.translations || {};
  }

  get languages(): LanguageCode[] {
    return this.state?.languages || [];
  }

  get defaultLanguage(): LanguageCode {
    return this.authUserStore.defaultLanguageCode;
  }

  get isDefaultLanguage(): boolean {
    return this.state.selectedLang === this.defaultLanguage;
  }

  get indicators(): LanguageCode[] {
    return this.state.indicators || [];
  }

  resetState(): void {
    this.setState(this.defaultState);
  }

  beforePatchValue(value: string, contentTranslationId: string): string {
    return this.controlValueTransformer?.(value, contentTranslationId) || value?.trim() || '';
  }

  htmlContentCleaner(content: string): string {
    if (typeof content !== 'string') {
      return '';
    }

    return UtilHtml.getTextContent(content);
  }

  setValueTransformer(transformer: (value: string, contentTranslationId?: string) => string = null): void {
    this.controlValueTransformer = transformer;
  }

  setIndicatorStrategy(strategy: IContentTranslationIndicatorListStrategy = new ContentTranslationDefaultIndicatorList()): void {
    this.indicatorStrategy = strategy;
  }

  createIndicatorList(translations: Record<string, TranslationMap>, contentTranslationId: string): LanguageCode[] {
    return this.indicatorStrategy.createIndicatorList(translations, contentTranslationId);
  }

  recalculateIndicators(contentTranslationId?: string): void {
    const indicators = this.createIndicatorList(this.translations, contentTranslationId);
    this.patchState({ indicators });
  }

  addTranslationToSelectedLang(contentTranslationId: string, value: string): void {
    const updatedTranslations = {
      ...this.translations,
      [contentTranslationId]: {
        ...this.translations[contentTranslationId],
        [this.state.selectedLang]: value,
      },
    };

    this.patchState({
      translations: updatedTranslations,
      indicators: this.createIndicatorList(updatedTranslations, contentTranslationId),
    });
  }

  addTranslationsMultiple(contentTranslationId: string, translations: TranslationMap): void {
    const updatedTranslations = {
      ...this.translations,
      [contentTranslationId]: translations,
    };

    this.patchState({
      translations: updatedTranslations,
      indicators: this.createIndicatorList(updatedTranslations, contentTranslationId),
    });
  }

  removeTranslations(contentTranslationId: string): void {
    const { [contentTranslationId]: _, ...rest } = this.translations;
    this.patchState({ translations: rest, indicators: [], selectedLang: this.languages[0] });
  }

  getTranslations(contentTranslationId: string): TranslationMap {
    const translations = this.translations[contentTranslationId] || {};
    return Object.fromEntries(
      Object.entries(translations).filter(([_, value]) => value?.trim())
    ) as TranslationMap;
  }

  getTranslationValue(contentTranslationId: string, lang: LanguageCode): string {
    return this.translations[contentTranslationId]?.[lang] || '';
  }

  hasMissingDefaultLanguage(contentTranslationId: string): boolean {
    const translations = this.getTranslations(contentTranslationId);
    return !translations[this.defaultLanguage];
  }

  setDefaultLangRequiredError(contentTranslationId: string, control: FormControl, changeToDefaultLang = true): void {
    const shouldSetError = !this.isDefaultLanguage && this.hasMissingDefaultLanguage(contentTranslationId);

    if (shouldSetError && control) {
      changeToDefaultLang && this.patchState({ selectedLang: this.defaultLanguage });
      control.untouched && control.markAsTouched();
      control.setErrors({ requiredDefaultLanguage: { label: 'label_required_in_default_lang' } });
    }
  }

  private createLanguageList(): LanguageCode[] {
    const translateLanguages = this.translate.getLanguages();
    const hasEnglish = translateLanguages.includes(LanguageCode.EN);

    return Array.from(new Set([
      this.defaultLanguage,
      ...(hasEnglish ? [LanguageCode.EN] : []),
      ...translateLanguages,
    ]));
  }

}
