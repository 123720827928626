import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getColors } from 'app/modules/crm/report-category/configs/report-category-color.config';

@Component({
  selector: 'ui-kit-color-selector',
  templateUrl: './ui-kit-color-selector.component.html',
  styleUrls: ['./ui-kit-color-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitColorSelectorComponent {

  @Input() control: FormControl<string>;

  readonly Colors = getColors();

  selectColor(color: string): void {
    if (this.control) {
      this.control.patchValue(color);
      this.control.markAsDirty();
    }
  }

}
