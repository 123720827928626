import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[uiKitChipActive]',
  standalone: true,
})
export class UiKitChipActiveDirective implements AfterViewInit {

  constructor(
    private elementRef: ElementRef,
    private focus: FocusMonitor,
  ) { }

  ngAfterViewInit(): void {
    const matChip = this.elementRef.nativeElement.querySelector('mat-chip');

    if (matChip) {
      this.focus.stopMonitoring(matChip);
    }
  }

}
