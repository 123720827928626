import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-info-tooltip',
  templateUrl: './ui-kit-info-tooltip.component.html',
  styleUrls: ['./ui-kit-info-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitInfoTooltipComponent {

  @Input() readonly tooltipTitle?: string;

  @Input() readonly tooltipContent: string;

  @Input() readonly tooltipTitleIcon?: string;

  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;

  showTooltip = false;

  popupStyle = {};

  get iconStyle(): SafeStyle {
    const palette: UiKitPalette = this.palette || UiKitPalette.DEFAULT;

    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-info-tooltip-color: var(--${palette}-color);
    `);
  }

  constructor(private readonly sanitizer: DomSanitizer) { }

  onMouseEnter(event: MouseEvent) {
    this.showTooltip = true;

    const clientRect = (event.target as HTMLElement).getBoundingClientRect();

    this.popupStyle = {
      left: `${clientRect.left}px`,
      top: `${clientRect.top}px`,
    };
  }

  onMouseLeave() {
    this.showTooltip = false;
  }

}
