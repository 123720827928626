import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocaleFilter } from '@core/models/datetime/locale-filter.model';
import { AAGUID, EncodedHttpParams, HttpService, IHttpOptions, IListResult, IStatus, Mock, SearchParams } from '@interticket/core';
import { Observable } from 'rxjs';
import { UserStatus } from '../enums/user-status.enum';
import { IChangePassword } from '../interfaces/change-password.interface';
import { IUserInvite } from '../interfaces/user-invite.interface';
import { IUserListItem } from '../interfaces/user-list-item.interface';
import { IUserProfile } from '../interfaces/user-profile.interface';
import { IUserProfileEditor } from '../interfaces/user-profile-editor.interface';
import { IUserSearchResponse } from '../interfaces/user-search-response.interface';
import { IUserSettings } from '../interfaces/user-settings.interface';
import { IUserView } from '../interfaces/user-view.interface';
import { USER_LIST, USER_LIST_EXPORT_MOCK, USER_PROFILE, USER_SEARCH_RESPONSE, USER_STATUS_LIST, USER_VIEW } from '../mocks/iam-management-user.mock';
import { UserFilter } from '../models/user-filter.model';
import { UserSearchFilter } from '../models/user-search-filter.model';

@Injectable({
  providedIn: 'root',
})
export class IamManagementUserSourceService {

  private readonly serviceBaseUrlV1: string = `/v1/iam-management/user`;
  private readonly serviceBaseUrlV2: string = `/v2/iam-management/user`;

  constructor(
    private httpService: HttpService
  ) { }

  @Mock()
  setUserSettings(venueId: AAGUID): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV1}/settings/venue`, { venueId });
  }

  @Mock()
  setPlaceToUser(placeId: AAGUID, auditoriumId: AAGUID): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/settings/place`, {
      placeId,
      auditoriumId,
    });
  }

  @Mock(USER_PROFILE?.userSettings)
  setColumnsForAdmissionReportExport(columns: string[]): Observable<IUserSettings> {
    return this.httpService.put<IUserSettings>(`${this.serviceBaseUrlV1}/settings/admission-report`, columns);
  }

  @Mock(USER_PROFILE?.userSettings)
  setColumnsForFinancialReportExport(columns: string[]): Observable<IUserSettings> {
    return this.httpService.put<IUserSettings>(`${this.serviceBaseUrlV1}/settings/financial-report`, columns);
  }

  @Mock(USER_PROFILE?.userSettings)
  setColumnsForGiftCardReportExport(columns: string[]): Observable<IUserSettings> {
    return this.httpService.put<IUserSettings>(`${this.serviceBaseUrlV1}/settings/gift-card-report`, columns);
  }

  @Mock(USER_PROFILE?.userSettings)
  setColumnsForTransactionReportExport(columns: string[]): Observable<IUserSettings> {
    return this.httpService.put<IUserSettings>(`${this.serviceBaseUrlV1}/settings/transaction-report`, columns);
  }

  @Mock(USER_LIST)
  getList(searchParams?: SearchParams, filterParams?: UserFilter): Observable<IListResult<IUserListItem>> {
    let params: HttpParams = searchParams ? searchParams.getHttpParams() : new EncodedHttpParams();
    if (filterParams) {
      params = filterParams.getHttpParams(params);
    }
    return this.httpService.get<IListResult<IUserListItem>>(this.serviceBaseUrlV1, { params });
  }

  @Mock(USER_LIST_EXPORT_MOCK)
  exportList(filterParams?: UserFilter, localeFilter?: LocaleFilter): Observable<HttpResponse<Blob>> {
    let params: HttpParams = localeFilter.getHttpParams();
    if (filterParams) {
      params = filterParams.getHttpParams(params);
    }

    const options: IHttpOptions = {
      params,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders(),
    };

    return this.httpService.get<HttpResponse<Blob>>(`${this.serviceBaseUrlV1}/export/csv`, options);
  }

  @Mock()
  deleteRegistration(userId: AAGUID): Observable<Response> {
    return this.httpService.delete<Response>(`${this.serviceBaseUrlV1}/delete-registration/${userId}`);
  }

  @Mock(USER_VIEW)
  getOne(userGuid: AAGUID): Observable<IUserView> {
    return this.httpService.get<IUserView>(`${this.serviceBaseUrlV1}/${userGuid}`);
  }

  @Mock(USER_PROFILE)
  getProfile(): Observable<IUserProfile> {
    return this.httpService.get<IUserProfile>(`${this.serviceBaseUrlV1}/me`);
  }

  @Mock(USER_STATUS_LIST)
  getStatuses(): Observable<IStatus<UserStatus>[]> {
    return this.httpService.get<IStatus<UserStatus>[]>(`${this.serviceBaseUrlV1}/statuses`);
  }

  @Mock()
  invite(userInvite: IUserInvite): Observable<Response> {
    return this.httpService.post<Response>(`${this.serviceBaseUrlV1}/invite`, userInvite);
  }

  @Mock()
  resend(userId: AAGUID): Observable<Response> {
    return this.httpService.post<Response>(`${this.serviceBaseUrlV1}/resendinvitation`, { userId });
  }

  @Mock(USER_PROFILE)
  editProfile(userProfile: IUserProfileEditor): Observable<IUserProfile> {
    return this.httpService.put<IUserProfile>(`${this.serviceBaseUrlV1}/me`, userProfile);
  }

  @Mock()
  changePassword(changePassword: IChangePassword): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV1}/password`, changePassword);
  }

  @Mock(USER_SEARCH_RESPONSE)
  search(searchFilter: UserSearchFilter): Observable<IUserSearchResponse> {
    const params: HttpParams = searchFilter.getHttpParams();
    return this.httpService.get<IUserSearchResponse>(`${this.serviceBaseUrlV1}/search`, { params });
  }

}
