import { CheckoutStatus } from '@core/enums/checkout/checkout-status.enum';
import { RegionPackageType } from '@services/iam';
import { AdmissionResult } from '@services/report';
import { UiKitPalette } from '@ui-kit/enums';
import { StatusIconConfig } from '../interfaces/status-icon-configs.interface';

export const STATUS_ICON_CONFIG_CHECKOUT: StatusIconConfig<CheckoutStatus> = {
  [CheckoutStatus.IN_PROGRESS]: { icon: 'in-progress', palette: UiKitPalette.INFO },
  [CheckoutStatus.SUCCEEDED]: { icon: 'check-circle', palette: UiKitPalette.SUCCESS },
  [CheckoutStatus.STUCKED]: { icon: 'alert-circle', palette: UiKitPalette.ERROR },
  [CheckoutStatus.FAILED]: { icon: 'x-circle', palette: UiKitPalette.ERROR },
  [CheckoutStatus.CANCELLED]: { icon: 'minus-circle', palette: UiKitPalette.WARNING },
};

export const STATUS_ICON_CONFIG_FEATURE_PACKAGE: StatusIconConfig<RegionPackageType> = {
  [RegionPackageType.BASIC]: { icon: 'box', palette: UiKitPalette.PRIMARY },
  [RegionPackageType.ADVANCED]: { icon: 'boxes', palette: UiKitPalette.PRIMARY },
  [RegionPackageType.CUSTOM]: { icon: 'custom', palette: UiKitPalette.PRIMARY },
};

export const STATUS_ICON_CONFIG_ADMISSION_REPORT: StatusIconConfig<AdmissionResult> = {
  [AdmissionResult.SUCCEEDED]: { icon: 'check-circle', palette: UiKitPalette.SUCCESS },
  [AdmissionResult.FAILED]: { icon: 'x-circle', palette: UiKitPalette.ERROR },
};
