import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AAGUID } from '@interticket/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectGroupShowModes } from '../../enums/select-group-show-modes.enum';
import { RegionsFeatureSelectGroup } from '../../models/regions-feature-select-group.model';
import { SelectGroupService } from '../../services/select-group.service';

@Component({
  selector: 'app-select-group-editor',
  templateUrl: './select-group-editor.component.html',
  styleUrls: ['./select-group-editor.component.scss'],
})
export class SelectGroupEditorComponent implements OnInit {

  private readonly destroy$ = new Subject<void>();

  readonly showModes = SelectGroupShowModes;

  searchTextControl: UntypedFormControl = this.formBuilder.control('');

  @Input() featureIdsControl: UntypedFormControl;
  @Input() featureList: RegionsFeatureSelectGroup[];
  @Input() disableEdit = false;
  @Input() allFeatureShowMode: SelectGroupShowModes = SelectGroupShowModes.ALL;
  @Input() selectedFeatureShowMode: SelectGroupShowModes = SelectGroupShowModes.SELECTED;
  @Input() selectedColumnLabel: string;
  @Input() featureColumnLabel = 'label_features';

  @Output() featureToggled: EventEmitter<AAGUID[]> = new EventEmitter();
  @Output() isReady: EventEmitter<void> = new EventEmitter();

  @ViewChild('searchTextInput') searchTextInput: ElementRef<HTMLInputElement>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private selectGroupService: SelectGroupService
  ) { }

  ngOnInit(): void {
    this.subscribeToFeatureIdChanges();
    this.featureIdsControl.updateValueAndValidity();
    this.isReady.emit();
  }

  private subscribeToFeatureIdChanges(): void {
    this.featureIdsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (featureIds: AAGUID[]) => {
          this.selectGroupService.selectFeatures(this.featureList, featureIds, true);
        },
      });
  }

  clear(): void {
    this.searchTextControl.reset();
    this.searchTextInput.nativeElement.focus();
  }

  onFeatureToggled(featureIds: AAGUID[]): void {
    this.featureIdsControl.patchValue(featureIds, { emitEvent: false });
    this.featureIdsControl.markAsDirty();
    this.featureIdsControl.updateValueAndValidity();
    this.featureToggled.emit(featureIds);
  }

}
