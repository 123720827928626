export enum PartnerPermission {
  ACCEPT_STEM4_GIFTCARD = 'giftcard.add.stem4.giftcard',
  BECOME_DIY = 'partners.become.diy.partner.from.admin',
  CREATE = 'partners.create.new.partner',
  CREATE_REGION_SUB_PAGE = 'partners.create.region.portal.sub.page',
  DETAILS = 'partners.view.partner.details',
  EDIT = 'partners.edit.partner.details',
  EDIT_PARTNER_DEFAULT_SETTINGS = 'partners.edit.partner.default.settings',
  EDIT_PARTNER_GENERAL_SETTINGS = 'partners.edit.partner.general.settings',
  EDIT_PARTNER_INVOICING_SETTINGS = 'partners.edit.partner.invoicing.settings',
  EDIT_PARTNER_SALES_SETTINGS = 'partners.edit.partner.sales.settings',
  EDIT_PARTNER_WEBSITE_SETTINGS = 'partners.edit.partner.website.settings',
  LIST = 'partners.list.and.search.partners',
  PAGE = 'regions.edit.region.partner',
  PARTNER_GET_ME = 'iam.partners.me.get',
  PARTNER_UPDATE_ME = 'iam.partners.me.update',
  CREATE_SMART_CITY_PARTNER = 'partners.smart.city.create.new.partner',
}
