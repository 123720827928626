import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const scaleOpacityAnimation: AnimationTriggerMetadata =
  trigger('scaleOpacityAnimation', [
    state('true', style({ opacity: 1 })),
    transition(':enter', [
      style({ opacity: 0 }),
      animate(200),
    ]),
    transition(':leave', [
      animate(200, style({ opacity: 0 })),
    ]),
  ]);
