<div class="row">
  <!-- TITLE -->
  <div class="columns small-12 text-center">
    <h2 mat-dialog-title>{{ dialogData.title | translate }}</h2>
  </div>
</div>

<div class="row">
  <ng-container *ngIf="(isLoading$ | async) === false else loading">
    <!-- CHIPS -->
    <div class="columns small-12 chip-column">
      <span class="chip" *ngFor="let field of selectedFields; trackBy: trackByFn">
        <span>{{ field.label | translate }}</span>
        <i class="material-icons" (click)="deleteSelectedField(field.value)">close</i>
      </span>
    </div>

    <!-- DROPDOWN -->
    <div class="columns small-12">
      <label>{{ 'label_add_column' | translate }}<br>
        <mat-select class="custom-select" [formControl]="selectedFieldsControl" multiple placeholder="{{ 'label_add_column' | translate }}">
          <mat-option *ngFor="let field of this.dialogData.fields" [value]="field.value">{{ field.label | translate }}</mat-option>
        </mat-select>
        <app-show-errors [control]="selectedFieldsControl"></app-show-errors>
      </label>
    </div>

    <div class="columns small-12">
      <div class="button-container">
        <!-- CANCEL -->
        <cancel-button mat-dialog-close [icon]="'close'" [label]="'general_lang_cancel'"></cancel-button>

        <div class="button-wrapper">
          <!-- UPDATE -->
          <app-button
            [classes]="'button primary'"
            [label]="'general_lang_save'"
            [icon]="'create'"
            [isDisabled]="selectedFieldsControl.pristine || selectedFieldsControl.invalid || (isLoadingExport$ | async) || (isLoadingUpdate$ | async)"
            [isLoading]="isLoadingUpdate$ | async"
            (onClick)="updateSelectedFields()">
          </app-button>

          <!-- EXPORT -->
          <save-button
            [type]="'button'"
            [label]="'label_export'"
            [icon]="'cloud_download'"
            [isDisabled]="selectedFieldsControl.invalid || (isLoadingExport$ | async) || (isLoadingUpdate$ | async)"
            [isLoading]="isLoadingExport$ | async"
            (onClick)="export()">
          </save-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loader-container">
    <div class="it-loader-center"></div>
  </div>
</ng-template>
