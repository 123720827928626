import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'cancel-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'hollow', 'secondary'];
  @Input() readonly icon: string = 'arrow_back_ios';
  @Input() readonly label: string = 'stemx_back_to_list';

}
