import { Pipe, PipeTransform } from '@angular/core';
import { EventExtendedView, EventVenueType } from '@services/product-catalog';
import { EventListItem } from 'app/modules/event/models/event/event-list-item.model';
import { ProgramCreatorBasic } from 'app/modules/event/modules/programs/models/program-creator-basic.model';

@Pipe({
  name: 'eventType',
})
export class EventTypePipe implements PipeTransform {

  transform(event: EventExtendedView | EventListItem | ProgramCreatorBasic): any {
    return event.type === EventVenueType.NORMAL ? 'event_type_' + event.type : 'online_event_type_' + event.onlineEventType.toLowerCase();
  }

}
