import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/auth-guard.service';
import { ModuleRouterComponent } from '@shared/components/module-router/module.router';
import { AppSkeletonRouterComponent } from './modules/app-skeleton/app-skeleton.router';
import { AuthRouterComponent } from './modules/auth/auth.router';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'admin/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthRouterComponent,
    loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'admin',
    component: AppSkeletonRouterComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    data: { breadcrumb: 'label_home' },
    loadChildren: () => import('app/modules/app-skeleton/app-skeleton.module').then(m => m.AppSkeletonModule),
  },
  {
    path: 'error',
    component: ModuleRouterComponent,
    loadChildren: () => import('@interticket/core').then(m => m.ErrorPageModule),
  },
  {
    path: '**',
    component: ModuleRouterComponent,
    loadChildren: () => import('@interticket/core').then(m => m.ErrorPageModule),
  },
];

export const AppRouter: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  appRoutes,
  {
    useHash: true,
    onSameUrlNavigation: 'reload',
  }
);
