import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@interticket/core';

@Pipe({
  name: 'capacity',
})
export class CapacityPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) { }

  transform(piece: number | string, endLabel?: string, noLimitLabel = 'stemx_boxoffice_unlimited'): string {
    const numberPiece = endLabel ? `${piece} ${this.translate.get(endLabel)}` : `${piece}`;
    return piece === null || piece === undefined ? this.translate.get(noLimitLabel) : numberPiece;
  }

}
