import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FeatherComponent } from 'angular-feather';

@Component({
  selector: 'ui-kit-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./ui-kit-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitIconComponent extends FeatherComponent {

  /**
   * List of available icons: {@link} https://www.feathericons.com
   *
   * Add icons to {@link UiKitIconPack} so you can use them
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('icon') name: string;

  /** In pixels */
  @Input() readonly size: number;

  @HostBinding('style.--icon-size') get iconSize(): string {
    return this.size && `${this.size}px`;
  }

}
