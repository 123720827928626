export const Key2 = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_3175_212)">
  <path d="M1.33337 12V14C1.33337 14.4 1.60004 14.6667 2.00004 14.6667H4.66671V12.6667H6.66671V10.6667H8.00004L8.93337 9.73336C9.85993 10.0561 10.8686 10.0549 11.7944 9.72986C12.7201 9.40483 13.5082 8.77525 14.0296 7.94411C14.551 7.11297 14.775 6.12947 14.6648 5.15452C14.5546 4.17956 14.1168 3.27086 13.423 2.57707C12.7292 1.88328 11.8205 1.44548 10.8455 1.33528C9.87059 1.22509 8.8871 1.44902 8.05596 1.97045C7.22482 2.49188 6.59524 3.27994 6.27021 4.20571C5.94518 5.13147 5.94394 6.14014 6.26671 7.06669L1.33337 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11 4.5C11.2761 4.5 11.5 4.72386 11.5 5C11.5 5.27614 11.2761 5.5 11 5.5C10.7239 5.5 10.5 5.27614 10.5 5C10.5 4.72386 10.7239 4.5 11 4.5Z" fill="currentColor" stroke="currentColor" stroke-linejoin="round"/>
</g>
<defs>
  <clipPath id="clip0_3175_212">
    <rect width="16" height="16" fill="transparent"/>
  </clipPath>
</defs>
</svg>`;
