import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output, Renderer2, RendererStyleFlags2 } from '@angular/core';

@Directive({
  selector: '[uiKitModalStyleHandler]',
  standalone: true,
})
export class UiKitModalStyleHandlerDirective implements AfterViewInit, OnDestroy {

  private observer: MutationObserver;

  @Output() onReady = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    this.setStyles();

    this.observer = new MutationObserver(() => this.setStyles());
    this.observer.observe(this.elementRef.nativeElement, { childList: true, subtree: true });

    setTimeout(() => this.onReady.emit(), 50);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private setStyles(): void {
    const modalHeight = this.document.querySelector('mat-dialog-container')?.getBoundingClientRect().height;
    const headerHeight = this.elementRef.nativeElement.querySelector('.modal-header')?.getBoundingClientRect().height;

    if (headerHeight && modalHeight) {
      this.renderer.setStyle(this.elementRef.nativeElement, '--ui-kit-modal-header-height', `${Math.floor(headerHeight)}px`, RendererStyleFlags2.DashCase);
      this.renderer.setStyle(this.elementRef.nativeElement, '--ui-kit-modal-height', `${Math.floor(modalHeight)}px`, RendererStyleFlags2.DashCase);
    }
  }

}
