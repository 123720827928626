import { Injectable } from '@angular/core';
import { OwlDateTimeFormats, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { DateTimeFormat, TranslateService } from '@interticket/core';
import { IUiKitDateTimePickerModuleConfig } from '../interfaces/ui-kit-date-time-picker-module-config.interface';

/**
 * See the Luxon docs for the meaning of these formats:
 * {@link} https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * {@link} https://danielykpan.github.io/date-time-picker/#locale-formats
 */
const OWL_DATE_TIME_FORMATS: OwlDateTimeFormats = {
  parseInput: 'f',
  fullPickerInput: 'f',
  datePickerInput: 'D',
  timePickerInput: 't',
  monthYearLabel: 'MMMM yyyy',
  dateA11yLabel: 'DDD',
  monthYearA11yLabel: 'MMMM yyyy',
};

export function owlDateTimeFormatFactory(config: IUiKitDateTimePickerModuleConfig): OwlDateTimeFormats {
  const dateTimeFormat = config.format;

  if (dateTimeFormat) {
    return {
      parseInput: dateTimeFormat[DateTimeFormat.DATETIME_SHORT],
      fullPickerInput: dateTimeFormat[DateTimeFormat.DATETIME_SHORT],
      datePickerInput: dateTimeFormat[DateTimeFormat.DATE_SHORT],
      timePickerInput: dateTimeFormat[DateTimeFormat.TIME_MED],
      monthYearLabel: dateTimeFormat[DateTimeFormat.YEAR_MONTH_FULL],
      dateA11yLabel: dateTimeFormat[DateTimeFormat.DATE_FULL],
      monthYearA11yLabel: dateTimeFormat[DateTimeFormat.YEAR_MONTH_FULL],
    };
  }

  return OWL_DATE_TIME_FORMATS;
}

/**
 * Here is the default text string
 * {@link} https://danielykpan.github.io/date-time-picker/#locale-formats
 * */
@Injectable()
export class DateTimeIntl extends OwlDateTimeIntl {

  /** A label for the up second button (used by screen readers).  */
  upSecondLabel = 'Add a second';

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel = 'Minus a second';

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel = 'Add a minute';

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel = 'Minus a minute';

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel = 'Add a hour';

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel = 'Minus a hour';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Previous month';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Next month';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Previous year';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Next year';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = 'Previous 21 years';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = 'Next 21 years';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Change to month view';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = 'Choose month and year';

  /** A label for the cancel button */
  cancelBtnLabel = 'Cancel';

  /** A label for the set button */
  setBtnLabel = 'Set';

  /** A label for the range 'from' in picker info */
  rangeFromLabel = 'From';

  /** A label for the range 'to' in picker info */
  rangeToLabel = 'To';

  /** A label for the hour12 button (AM) */
  hour12AMLabel = 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel = 'PM';

  constructor(
    private translate: TranslateService
  ) {
    super();
    this.translateLabels();
    this.translate.onLangChanged.subscribe(() => {
      this.translateLabels();
    });
  }

  translateLabels() {
    this.cancelBtnLabel = this.translate.get('general_lang_cancel');
    this.setBtnLabel = this.translate.get('general_lang_set');
    this.rangeFromLabel = this.translate.get('label_from');
    this.rangeToLabel = this.translate.get('label_to');
  }

}
