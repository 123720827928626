<div class="card-container ui-kit-size-{{ size() }}">

  <!-- ICON -->
  <div class="card-icon">
    <ui-kit-icon
      [icon]="icon()"
      [size]="effectiveIconSize()" />
  </div>

  <div class="d-flex flex-column">

    <!-- TITLE -->
    <div class="card-title">{{ title() | translate }}</div>

    <!-- DESCRIPTION -->
    @if (description()) {
      <div class="card-description">{{ description() | translate }}</div>
    }

  </div>

</div>
