import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiKitHistoryValueVariant } from '../../enums/ui-kit-history-value-variant.enum';

@Component({
  selector: 'ui-kit-history-value',
  templateUrl: './ui-kit-history-value.component.html',
  styleUrls: ['./ui-kit-history-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitHistoryValueComponent {

  readonly fallback = '\u2013';

  @Input() readonly icon?: string;
  @Input() readonly label?: string;
  @Input() readonly variant = UiKitHistoryValueVariant.OLD;

}
