import { ChangeDetectionStrategy, Component } from '@angular/core';
import { flyInOutAnimation } from '@ui-kit/animations';
import { Toast } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-kit-notification',
  templateUrl: './ui-kit-notification.component.html',
  styleUrls: ['./ui-kit-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [flyInOutAnimation],
})
export class UiKitNotificationComponent extends Toast {

  duplicatesCount$: Observable<number> = this.toastPackage.toastRef.countDuplicate();

}
