<div
  class="image-container"
  [class.show-overlay]="showOverlay"
  [class.has-aspect-ratio]="!!aspectRatio">

  <!-- IMAGE -->
  <img *ngIf="imageId else imagePlaceholder" [src]="imageId | imageSrc: transform">

  <!-- PLACEHOLDER -->
  <ng-template #imagePlaceholder>
    <div class="placeholder-image" [class.light-variant]="placeholderVariant === uiKitTheme.LIGHT">
      <ui-kit-icon [icon]="'image'" [class.icon-light]="placeholderVariant === uiKitTheme.LIGHT" />
    </div>
  </ng-template>

  <ng-content />

</div>
