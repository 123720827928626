import { Injectable } from '@angular/core';
import { AAGUID, TranslateService } from '@interticket/core';
import { IFeatureGroup, IFeatureGroups, IFeatureItem } from '@services/feature';
import { RegionsFeatureSelectGroup } from '../models/regions-feature-select-group.model';
import { RegionsFeatureSelectItem } from '../models/regions-feature-select-item.model';

@Injectable()
export class SelectGroupService {

  constructor(
    private translate: TranslateService
  ) { }

  private compileSelectItems(features: IFeatureItem[], featureIds?: AAGUID[]): RegionsFeatureSelectItem[] {
    return features.map((item: IFeatureItem) => new RegionsFeatureSelectItem({
      ...item,
      selected: featureIds && featureIds.includes(item.id),
      translation: this.translate.get(item.label).toLowerCase(),
    })).sort((a: RegionsFeatureSelectItem, b: RegionsFeatureSelectItem) => a.label < b.label ? -1 : 1);
  }

  private getSelectedFeatureItems(featureGroup: RegionsFeatureSelectGroup): AAGUID[] {
    return featureGroup.features.reduce((featureIds: AAGUID[], item: RegionsFeatureSelectItem) => item.selected ? [...featureIds, item.id] : featureIds, []);
  }

  compileSelectGroups(featureList: IFeatureGroups, featureIds?: AAGUID[]): RegionsFeatureSelectGroup[] {
    return featureList.featureGroups.map((group: IFeatureGroup) => new RegionsFeatureSelectGroup({
      ...group,
      features: this.compileSelectItems(group.features, featureIds),
      translation: this.translate.get(group.label).toLowerCase(),
    })).sort((a: RegionsFeatureSelectGroup, b: RegionsFeatureSelectGroup) => a.label < b.label ? -1 : 1);
  }

  getSelectedFeatures(featureGroups: RegionsFeatureSelectGroup[]): AAGUID[] {
    return featureGroups.reduce((featureIds: AAGUID[], group: RegionsFeatureSelectGroup) => group.hasSelected ? [...featureIds, ...this.getSelectedFeatureItems(group)] : featureIds, []);
  }

  selectFeatures(groups: RegionsFeatureSelectGroup[], featureIds: AAGUID[], deselectIfNotInList = false): void {
    const idList: AAGUID[] = Array.isArray(featureIds) ? featureIds : [];

    groups.forEach((group: RegionsFeatureSelectGroup) => {
      group.features.forEach((item: RegionsFeatureSelectItem) => {
        if (idList.includes(item.id)) {
          item.select();
        } else if (deselectIfNotInList) {
          item.deselect();
        }
      });
      group.updateState();
    });
  }

  getFeatureIds(featureList: IFeatureGroups): AAGUID[] {
    return featureList.featureGroups.reduce((featureIdList: AAGUID[], featureGroup: IFeatureGroup) => {
      const idList = featureGroup.features.map((featureItem: IFeatureItem) => featureItem.id);

      return [...featureIdList, ...idList];
    }, []);
  }

  getFeatureNames(featureList: IFeatureGroups): string[] {
    return featureList.featureGroups.reduce((featureIdList: AAGUID[], featureGroup: IFeatureGroup) => {
      const idList = featureGroup.features.map((featureItem: IFeatureItem) => featureItem.name);
      return [...featureIdList, ...idList];
    }, []);
  }

}
