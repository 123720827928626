import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AAGUID, IImageTransform } from '@interticket/core';
import { UiKitTheme } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-image',
  templateUrl: './ui-kit-image.component.html',
  styleUrls: ['./ui-kit-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitImageComponent {

  @Input() imageId: AAGUID;
  @Input() transform: IImageTransform;
  @Input() showOverlay = false;
  @Input() @HostBinding('style.--ui-kit-image-aspect-ratio') aspectRatio: number;
  @Input() placeholderVariant = UiKitTheme.DARK;

  readonly uiKitTheme = UiKitTheme;

}
