import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-empty-state',
  templateUrl: './ui-kit-empty-state.component.html',
  styleUrls: ['./ui-kit-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitEmptyStateComponent {

  @Input() readonly title: string;
  @Input() readonly description: string;
  @Input() readonly isLoading: boolean;
  @Input() readonly iconSize = 60;
  @Input() readonly iconPalette: UiKitPalette;
  @Input() readonly spinnerPalette: UiKitPalette = UiKitPalette.PRIMARY;

  /** {@link} https://feathericons.com/ */
  @Input() readonly icon: string;

  @HostBinding('style.--empty-state-icon-color') get iconColorStyle(): string | null {
    return this.iconPalette && `var(--${this.iconPalette}-color)`;
  }

}
