<div class="main">

  <!-- Title -->
  <div class="row align-spaced">
    <div class="columns text-center">
      <h2 mat-dialog-title>{{ 'booking_calendar_new_booking_title' | translate }}</h2>
    </div>
  </div>

  <form [formGroup]="bookingForm">

    <!-- SERVICES -->
    <div class="row">
      <div class="columns small-12">
        <label>{{ 'booking_calendar_new_booking_service' | translate }}
          <mat-select class="custom-select" [placeholder]="'booking_calendar_new_booking_service' | translate" [formControlName]="'serviceId'">
            <mat-option [value]="service.id" *ngFor="let service of data.serviceList; trackBy: trackById">{{ service.name }}</mat-option>
          </mat-select>
        </label>
      </div>
    </div>

    <!-- RESOURCES -->
    <div class="row">
      <div class="columns small-12">
        <label>{{ 'booking_resource' | translate }}
          <mat-select class="custom-select" [placeholder]="'booking_resource' | translate" [formControlName]="'resourceId'">
            <mat-option [value]="resource.id" *ngFor="let resource of data.resourceList">{{ resource.name }}</mat-option>
          </mat-select>
        </label>
      </div>
    </div>

    <!-- DATE TIME -->
    <div class="row">
      <div class="columns small-12">
        <label>{{ 'booking_calendar_new_booking_date' | translate }}
          <div class="input-group">
            <input type="text" class="input-group-field" appFormControl formControlName="start" placeholder="{{ 'booking_calendar_new_booking_date' | translate }}" autocomplete="off"
              [owlDateTimeTrigger]="dateTimePicker" [owlDateTime]="dateTimePicker" [selectMode]="'single'">
            <owl-date-time #dateTimePicker [startView]="'month'" [firstDayOfWeek]="1" [stepMinute]="5"></owl-date-time>
            <div class="input-group-button">
              <button type="button" class="button" [owlDateTimeTrigger]="dateTimePicker">
                <i class="material-icons">calendar_today</i>
              </button>
            </div>
          </div>
        </label>
      </div>
    </div>

    <!-- Customer -->
    <div class="row">
      <div class="columns small-12 medium-expand">
        <label class="text-ellipsis" title="{{ 'booking_calendar_new_booking_customer' | translate }}">{{ 'booking_calendar_new_booking_customer' | translate }}</label>
        <autocomplete-dropdown
          [list]="data.userList"
          [selectedItem]="bookingForm.get('userId').value"
          (selectedItemChange)="onOptionSelected($event)">
        </autocomplete-dropdown>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row align-center margin-top-2">
      <button class="button secondary margin-right-1" [mat-dialog-close]="{ confirmed: false, formData: null }">{{ 'label_cancel' | translate }}</button>
      <button class="button" [mat-dialog-close]="{ confirmed: true, formData: bookingForm.value }" [disabled]="bookingForm.status === 'INVALID'">{{ 'label_create' | translate }}</button>
    </div>

  </form>

</div>
