import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorResponse, AppError, TranslateService } from '@interticket/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class NotifierService {

  private readonly labels = {
    generalError: 'general_lang_error',
    clientError: 'client_error',
    serverError: 'server_error',
    errorSave: 'label_error_saving',
    errorFetch: 'global_fetch_error',
    successSave: 'label_admin_save_success',
    timeoutError: 'timeout_error',
    permissionError: 'permission_error',
    errorCode: 'general_error_code',
  };

  private readonly errorNotificationConfig: Partial<IndividualConfig> = {
    closeButton: true,
    disableTimeOut: true,
    enableHtml: true,
  };

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService
  ) { }

  /** show error notification from error objects */
  private _showError(error: AppError, title: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const message: string | string[] = this.getErrorMessages(error);

    if (message === null) {
      return this.clientError();
    }

    // Show error notification by HTTP status code
    if (error instanceof HttpErrorResponse && !message.length) {
      switch (error.status) {
        case HttpStatusCode.Forbidden: return this.error(null, this.labels.permissionError);
        case HttpStatusCode.GatewayTimeout: return this.error(null, this.labels.timeoutError);
      }
    }

    return this.error(message, title, override);
  }

  /** translate title and messages */
  private translate(message: string | string[], title?: string): { title: string; message: string } {
    const messageArray = Array.isArray(message) ? message : [message];
    return {
      title: this.translateService.get(title),
      message: messageArray.map((msg: string) => this.translateService.get(msg)).join('<br>'),
    };
  }

  /** get message from error object */
  getErrorMessages(error: AppError): string | string[] {
    if (error instanceof ApiErrorResponse) {
      return error.getErrorLabels();
    } else if (error instanceof HttpErrorResponse) {
      return error.message;
    } else {
      // Client side errors
      return null;
    }
  }

  /** show successful toast */
  success(message: string | string[], title: string = this.labels.successSave, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const translates = this.translate(message, title);
    return this.toastr.success(translates.message, translates.title, override);
  }

  /** show error toast */
  error(message: string | string[], title: string = this.labels.generalError, override: Partial<IndividualConfig> = this.errorNotificationConfig): ActiveToast<any> {
    const translates = this.translate(message, title);
    return this.toastr.error(translates.message, translates.title, override);
  }

  /** show info toast */
  info(message: string | string[], title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const translates = this.translate(message, title);
    return this.toastr.info(translates.message, translates.title, override);
  }

  /** show warning toast */
  warning(message: string | string[], title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const translates = this.translate(message, title);
    return this.toastr.warning(translates.message, translates.title, override);
  }

  /** For successful saves */
  successSave(message?: string | string[], override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.success(message, this.labels.successSave, override);
  }

  /** For client errors */
  clientError(message?: string | string[], override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.error(message, this.labels.clientError, override);
  }

  /** For server errors */
  showError(error: AppError, title: string = this.labels.serverError, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this._showError(error, title, override);
  }

  /** For server errors on save */
  errorSave(error: AppError, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this._showError(error, this.labels.errorSave, override);
  }

  /** For server errors during fetch data */
  errorFetch(error: AppError, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this._showError(error, this.labels.errorFetch, override);
  }

}
