import { IPartnerOrganizerCheckoutSurveySettings } from '../interfaces/partner-organizer-checkout-survey-settings.interface';

export class PartnerOrganizerCheckoutSurveySettings implements IPartnerOrganizerCheckoutSurveySettings {

  readonly surveyItemId: string | null;
  readonly surveyRequired: boolean | null;

  constructor(iConfig: IPartnerOrganizerCheckoutSurveySettings) {
    this.surveyItemId = iConfig?.surveyItemId || null;
    this.surveyRequired = iConfig?.surveyRequired || null;
  }

}
