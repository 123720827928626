import { InjectionToken } from '@angular/core';
import { DateTimeFormat, LanguageCode, TimeZone } from '@interticket/core';

export interface IUiKitDateTimePickerModuleConfig {
  locale?: LanguageCode;
  format?: { [format in DateTimeFormat]: string };
  timeZone?: TimeZone;
}

export const UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG = new InjectionToken<IUiKitDateTimePickerModuleConfig>('UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG');
