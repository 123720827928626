export enum EventsPermission {
  LIST = 'events.list.and.search.event',
  DETAILS = 'events.view.event',
  CREATE = 'events.create.new.event',
  CREATE_VIDEO = 'events.create.video.event',
  CREATE_LIVESTREAM = 'events.create.livestream.event',
  CREATE_LIVESTREAM_VIDEO = 'events.create.livestream.video.event',
  EDIT = 'events.edit.event',
  DELETE = 'events.delete.event',
  UPLOAD_VIDEO = 'events.upload.video',
  STREAM_KEY = 'events.start.livestream',
  GUEST_LIST = 'events.guest.list',
  REGIONAL_RESTRICTION_VIEW = 'events.view.regional.restriction.at.event',
  REGIONAL_RESTRICTION_SET = 'events.set.regional.restriction.for.event',
  RESTRICT_TICKET_USAGE = 'events.restrict.ticket.usage.according.to.purchase',
  TIME_SLOT = 'events.time.slot',
}
