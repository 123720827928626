import { Component, Input } from '@angular/core';
import { CustomBadgeStatus, EmailComponentStatus, EmailTemplateStatus } from '@services/notification';

@Component({
  selector: 'status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent {

  @Input() status: EmailTemplateStatus | EmailComponentStatus | CustomBadgeStatus;

}
