<div class="title-container flex-middle">
  <ng-container *ngIf="!titleTemplate; else titleTemplate">
    <!-- ICON -->
    <ui-kit-icon *ngIf="icon" [icon]="icon" [size]="30"></ui-kit-icon>

    <div>
      <!-- TITLE -->
      <h2 *ngIf="title">{{ title | translate }}</h2>

      <!-- DESCRIPTION -->
      <p *ngIf="description">{{ description | translate }}</p>
    </div>
  </ng-container>
</div>

<!-- CONTENT -->
<div class="es-content">
  <ng-content></ng-content>
</div>
