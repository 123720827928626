import { Component, Inject, ViewContainerRef } from '@angular/core';
import { usedIcons } from '@config/icon/icon.config';
import { APP_CONFIG } from '@env';
import { IconService } from '@interticket/customer-facing-ui-kit';
import { IAppConfig } from '../environments';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppRouterComponent {

  constructor(
    private iconService: IconService,
    public vcRef: ViewContainerRef,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
  ) {
    this.loadExternalScript(
      `https://maps.googleapis.com/maps/api/js?key=${this.appConfig.googleMapsKey}`
    );

    this.iconService.registrateIcons(usedIcons);
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

}
