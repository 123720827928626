import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocaleFilter } from '@core/models/datetime/locale-filter.model';
import { AuthUserStoreService } from '@core/services/store/auth-user-store.service';
import { AAGUID, IListResult, IStatus, ListResult, SearchParams, Status } from '@interticket/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserStatus } from '../enums/user-status.enum';
import { IChangePassword } from '../interfaces/change-password.interface';
import { IUserInvite } from '../interfaces/user-invite.interface';
import { IUserListItem } from '../interfaces/user-list-item.interface';
import { IUserProfile } from '../interfaces/user-profile.interface';
import { IUserProfileEditor } from '../interfaces/user-profile-editor.interface';
import { IUserSearchResponse } from '../interfaces/user-search-response.interface';
import { IUserSettings } from '../interfaces/user-settings.interface';
import { UserFilter } from '../models/user-filter.model';
import { UserListItem } from '../models/user-list-item.model';
import { UserSearchFilter } from '../models/user-search-filter.model';
import { UserView } from '../models/user-view.model';
import { IamManagementUserSourceService } from './iam-management-user-source.service';

@Injectable({
  providedIn: 'root',
})
export class IamManagementUserService {

  constructor(
    private iamManagementUserSourceService: IamManagementUserSourceService,
    private authUserStore: AuthUserStoreService,
  ) { }

  setUserSettings(venueId: AAGUID): Observable<Response> {
    return this.iamManagementUserSourceService.setUserSettings(venueId);
  }

  setPlaceToUser(placeId: AAGUID, auditoriumId: AAGUID): Observable<Response> {
    return this.iamManagementUserSourceService.setPlaceToUser(placeId, auditoriumId);
  }

  setColumnsForAdmissionReportExport(columns: string[]): Observable<IUserSettings> {
    return this.iamManagementUserSourceService.setColumnsForAdmissionReportExport(columns);
  }

  setColumnsForFinancialReportExport(columns: string[]): Observable<IUserSettings> {
    return this.iamManagementUserSourceService.setColumnsForFinancialReportExport(columns);
  }

  setColumnsForGiftCardReportExport(columns: string[]): Observable<IUserSettings> {
    return this.iamManagementUserSourceService.setColumnsForGiftCardReportExport(columns);
  }

  setColumnsForTransactionReportExport(columns: string[]): Observable<IUserSettings> {
    return this.iamManagementUserSourceService.setColumnsForTransactionReportExport(columns);
  }

  /**
   * Return filtered user list
   */
  getList(searchParams?: SearchParams, filterParams?: UserFilter): Observable<ListResult<IUserListItem, UserListItem>> {
    return this.iamManagementUserSourceService.getList(searchParams, filterParams)
      .pipe(
        map((iListResult: IListResult<IUserListItem>) => new ListResult<IUserListItem, UserListItem>(iListResult, UserListItem))
      );
  }

  exportList(filterParams?: UserFilter, localeFilter?: LocaleFilter): Observable<HttpResponse<Blob>> {
    return this.iamManagementUserSourceService.exportList(filterParams, localeFilter);
  }

  deleteRegistration(userId: AAGUID): Observable<Response> {
    return this.iamManagementUserSourceService.deleteRegistration(userId);
  }

  /**
   * Return one user
   */
  getOne(userGuid: AAGUID): Observable<UserView> {
    return this.iamManagementUserSourceService.getOne(userGuid)
      .pipe(
        map((iUser: UserView) => new UserView(iUser))
      );
  }

  /**
   * Get current user profile datas
   */
  getProfile(): Observable<IUserProfile> {
    return this.iamManagementUserSourceService.getProfile();
  }

  /**
   * Current user edits her/his own profile datas and refresh store
   */
  editProfile(userProfileEditor: IUserProfileEditor): Observable<IUserProfile> {
    return this.iamManagementUserSourceService.editProfile(userProfileEditor)
      .pipe(
        map((userProfile: IUserProfile) => {
          this.authUserStore.patchState({ userProfile });
          return userProfile;
        })
      );
  }

  /**
   * Change user password
   */
  changePassword(changePassword: IChangePassword): Observable<Response> {
    return this.iamManagementUserSourceService.changePassword(changePassword);
  }

  /**
   * Return possible list of user status
   */
  getStatuses(): Observable<Status<UserStatus>[]> {
    return this.iamManagementUserSourceService.getStatuses()
      .pipe(
        map((iStatuses: IStatus<UserStatus>[]) => iStatuses.map((iStatus: IStatus<UserStatus>) => new Status<UserStatus>(iStatus)))
      );
  }

  /**
   * Invite user sending email, name and roles
   */
  invite(userInvite: IUserInvite): Observable<Response> {
    return this.iamManagementUserSourceService.invite(userInvite);
  }

  /**
   * Resend invitation email to user
   */
  resend(userId: AAGUID): Observable<Response> {
    return this.iamManagementUserSourceService.resend(userId);
  }

  search(searchFilter: UserSearchFilter): Observable<IUserSearchResponse> {
    return this.iamManagementUserSourceService.search(searchFilter);
  }

}
