import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonIconPosition } from '@shared/enums/button/button-icon-position.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  readonly iconPositions = ButtonIconPosition;

  @Input() readonly classes: string | string[] = null;
  @Input() readonly fullWidth: boolean = false;
  @Input() readonly icon: string = null;
  @Input() readonly iconPosition: ButtonIconPosition = ButtonIconPosition.BEFORE;
  @Input() readonly isDisabled = false;
  @Input() readonly isLoading = false;
  @Input() readonly margin: boolean = true;
  @Input() readonly noBottomMargin: boolean = false;
  @Input() readonly isRotated: boolean = false;
  @Input() readonly label: string = null;
  @Input() readonly type: 'button' | 'submit' = 'button';
  @Input() readonly isWhiteLabel: boolean = false;

  @Output() readonly onClick: EventEmitter<void> = new EventEmitter();

}
