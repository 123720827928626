<!-- CLOSE -->
@if (isCloseable) {
  <ui-kit-button-icon
    class="callout-close"
    [icon]="'x'"
    (onClick)="onClose()" />
}

<!-- BADGE -->
<div class="callout-badge">
  <ui-kit-icon [icon]="icon" [size]="24" />
</div>

<section
  class="callout-content"
  [class.is-centered]="isCenteredContent">

  <!-- TITLE -->
  @if (title) {
    <h1>{{ title | translate }}</h1>
  }

  <!-- DESCRIPTION -->
  @for (description of descriptions; track description) {
    <p>{{ description | translate }}</p>
  }

  <!-- CUSTOM CONTENT -->
  <ng-content />

</section>
