/**
 * ITDS Icon groups
 * {@link} https://www.figma.com/design/WOSWwiLtSxupJFmOHbwAHw/%E2%AD%90-Icons---Interticket-Design-Language?node-id=1-5200&m=dev
 */
export enum ITDSIconGroup {
  FILES = 'Files',
  FORMATTING = 'Formatting',
  NAVIGATION = 'Navigation',
  STATUS = 'Status',
  TOGGLE_ICONS = 'ToggleIcons'
}
