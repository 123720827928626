import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from '@core/services/notification/notifier.service';
import { AuthUserStoreService } from '@core/services/store/auth-user-store.service';
import { arrayLengthValidator, TranslateService } from '@interticket/core';
import { ISelectOption } from '@shared/interfaces/select/select-option.interface';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IExportFieldDialogConfig } from '../../interfaces/export-field-dialog-config.interface';
import { ExportFieldFilter } from '../../models/export-field-filter.model';

@Component({
  selector: 'export-field-dialog',
  templateUrl: './export-field-dialog.component.html',
  styleUrls: ['./export-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportFieldDialogComponent<T> implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();

  selectedFieldsControl: UntypedFormControl = new UntypedFormControl([], arrayLengthValidator);
  selectedFields: ISelectOption<T>[] = [];
  isLoading$: Subject<boolean> = new Subject();
  isLoadingExport$: Subject<boolean> = new Subject();
  isLoadingUpdate$: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IExportFieldDialogConfig<T>,
    private authUserStore: AuthUserStoreService,
    private translate: TranslateService,
    private notifier: NotifierService,
  ) { }

  ngOnInit(): void {
    this.subscribeToSelectedFieldsControlValueChanges();
    this.fetchSelectedFields();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  deleteSelectedField(selectedField: T): void {
    this.selectedFieldsControl.patchValue(this.getSelectedFieldsControlValue().filter(field => field !== selectedField));
    this.selectedFieldsControl.markAsDirty();
  }

  updateSelectedFields(): void {
    this.isLoadingUpdate$.next(true);
    this.dialogData.updateSelectedFields$(this.getSelectedFieldsControlValue())
      .pipe(finalize(() => this.isLoadingUpdate$.next(false)))
      .subscribe({
        next: () => {
          this.selectedFieldsControl.markAsPristine();
          this.notifier.successSave();
        },
      });
  }

  export(): void {
    const filter: ExportFieldFilter<T> = new ExportFieldFilter({
      lang: this.translate.currentLang,
      timezone: this.authUserStore.defaultTimezone,
      columns: this.getSelectedFieldsControlValue(),
    });

    this.isLoadingExport$.next(true);
    this.dialogData.exportSelectedFields$(filter)
      .pipe(finalize(() => this.isLoadingExport$.next(false)))
      .subscribe({
        next: (data: HttpResponse<Blob>) => {
          const fileName = data.headers.get('content-disposition').match(/filename=(.+);/i)[1];
          saveAs(data.body, fileName);
        },
      });
  }

  trackByFn(index: number): number {
    return index;
  }

  private getSelectedFieldsControlValue(): T[] {
    return this.selectedFieldsControl.value || [] as T[];
  }

  private setSelectedFields(selectedFields: T[]): void {
    this.selectedFields = this.dialogData.fields.filter(field => selectedFields.includes(field.value)) || [];
  }

  private initSelectedFields(selectedFields: T[]): void {
    this.selectedFieldsControl.patchValue(selectedFields);
  }

  private fetchSelectedFields(): void {
    this.isLoadingUpdate$.next(true);
    this.dialogData.getSelectedFields$
      .pipe(finalize(() => this.isLoading$.next(false)))
      .subscribe({
        next: selectedFields => this.initSelectedFields(selectedFields),
      });
  }

  private subscribeToSelectedFieldsControlValueChanges(): void {
    this.selectedFieldsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (selectedFields: T[]) => this.setSelectedFields(selectedFields),
      });
  }

}
