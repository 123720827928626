import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiKitEmptyStateComponent } from '../ui-kit-empty-state/ui-kit-empty-state.component';

@Component({
  selector: 'ui-kit-error-state',
  templateUrl: '../ui-kit-empty-state/ui-kit-empty-state.component.html',
  styleUrls: ['../ui-kit-empty-state/ui-kit-empty-state.component.scss', './ui-kit-error-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex-fill-container' },
})
export class UiKitErrorStateComponent extends UiKitEmptyStateComponent {

  @Input() readonly description: string;

  readonly icon = 'x-circle';
  readonly title = 'global_fetch_error';

}
