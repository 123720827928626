import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'delete-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'alert'];
  @Input() readonly icon = 'delete';
  @Input() readonly label = 'general_lang_delete';

}
