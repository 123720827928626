import { RegionPackageType } from '../enums/region-package-type.enum';
import { IRegionPackageCreator } from '../interfaces/region-package-creator.interface';
import { RegionPackageEditor } from './region-package-editor.model';

export class RegionPackageCreator extends RegionPackageEditor implements IRegionPackageCreator {

  readonly type: RegionPackageType;

  constructor(iRegionPackageCreator: IRegionPackageCreator) {
    super(iRegionPackageCreator);
    this.type = iRegionPackageCreator.type;
  }

}
