import { Pipe, PipeTransform } from '@angular/core';
import { AuthUserStoreService } from '@core/services/store/auth-user-store.service';
import { DATE_TIME_FORMATS, DateTimeFormat, formatDateWithTimezone, LanguageCode, TranslateService } from '@interticket/core';

@Pipe({
  name: 'timezone',
})
export class TimezonePipe implements PipeTransform {

  private userLocale = {
    locale: this.translate.currentLang,
    format: DATE_TIME_FORMATS[this.translate.currentLang],
    timeZone: this.authUserStore.defaultTimezone,
  };

  constructor(
    private authUserStore: AuthUserStoreService,
    private translate: TranslateService,
  ) {
  }

  transform(dateString: string, format: DateTimeFormat | string = DateTimeFormat.DATETIME_FULL, locale: LanguageCode = this.userLocale.locale, timezone = this.userLocale.timeZone): string {
    return formatDateWithTimezone(dateString, format, locale, timezone);
  }

}
