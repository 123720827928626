import { Injectable } from '@angular/core';
import { IAuthTokenState } from '@core/interfaces/auth/auth-token-state.interface';
import { IAuthToken, StoreService } from '@interticket/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenStoreService extends StoreService<IAuthTokenState> {

  private readonly AUTH_TOKEN_STORAGE_KEY = 'auth_token';
  private readonly defaultState: IAuthTokenState = {
    authToken: null,
  };

  readonly authToken$: Observable<IAuthToken> = this.shareState(({ authToken }) => authToken);
  readonly isAuthenticated$: Observable<boolean> = this.shareState(({ authToken }) => !!authToken);

  constructor() {
    super();
    this.patchState({ authToken: this.getItem(this.AUTH_TOKEN_STORAGE_KEY) });
  }

  set authToken(authToken: IAuthToken) {
    this.setItem(this.AUTH_TOKEN_STORAGE_KEY, authToken);
    this.patchState({ authToken });
  }

  get authToken(): IAuthToken {
    return this.state.authToken;
  }

  get isAuthenticated(): boolean {
    return !!this.state.authToken;
  }

  resetState(): void {
    this.setItem(this.AUTH_TOKEN_STORAGE_KEY, null);
    this.patchState({ ...this.defaultState });
  }

}
