import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { TranslateService, UtilString } from '@interticket/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, map, mapTo, startWith } from 'rxjs/operators';
import { IUiKitBreadcrumb } from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'ui-kit-breadcrumbs',
  templateUrl: './ui-kit-breadcrumbs.component.html',
  styleUrls: ['./ui-kit-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitBreadcrumbsComponent implements OnInit {

  private readonly inputBreadcrumbs$: BehaviorSubject<IUiKitBreadcrumb[]> = new BehaviorSubject(null);
  private readonly ROUTE_DATA_BREADCRUMB = 'pageBreadcrumb';
  private readonly rootBreadcrumb: IUiKitBreadcrumb = {
    label: 'label_home',
    url: '/admin',
    params: null,
  };

  breadcrumbs$: Observable<IUiKitBreadcrumb[]>;

  @Input() set breadcrumbs(pageBreadcrumbs: IUiKitBreadcrumb[]) {
    this.inputBreadcrumbs$.next([this.rootBreadcrumb, ...pageBreadcrumbs]);
  }

  get breadcrumbs(): IUiKitBreadcrumb[] {
    return this.inputBreadcrumbs$.getValue();
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.breadcrumbs) {
      this.breadcrumbs$ = this.getBreadcrumbsStream();
    } else {
      this.breadcrumbs$ = this.getBreadcrumbsStreamByRoute();
    }
  }

  private getBreadcrumbsStream(): Observable<IUiKitBreadcrumb[]> {
    return merge(
      this.translate.onLangChanged,
      this.inputBreadcrumbs$,
    )
      .pipe(
        mapTo(this.breadcrumbs),
      );
  }

  private getBreadcrumbsStreamByRoute(): Observable<IUiKitBreadcrumb[]> {
    return merge(
      this.translate.onLangChanged,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
    )
      .pipe(
        startWith(true),
        map(() => this.getBreadcrumbs(this.activatedRoute.root))
      );
  }

  /**
   * Returns array of IUiKitBreadcrumb objects that represent the breadcrumb
   * @link http://brianflove.com/2016/10/23/angular2-breadcrumb-using-router/
   */
  private getBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: IUiKitBreadcrumb[] = []): IUiKitBreadcrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      const breadcrumbLabel: string = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];

      // get the route's URL segment
      let routeURL = `${url}/${child.snapshot.url.map(segment => segment.path)}`;

      // hack view/:id
      routeURL = UtilString.trimChar(routeURL.replace(/,/gi, '/'), '/', 'right');

      // verify the custom data property "breadcrumb" is specified on the route or not empty
      if (!breadcrumbLabel) {

        // clear breadcrumbs (keep original reference)
        if (breadcrumbLabel === null) {
          breadcrumbs.length = 0;
        }

        return this.getBreadcrumbs(child, routeURL, breadcrumbs);
      }

      // add breadcrumb
      const breadcrumb: IUiKitBreadcrumb = {
        label: breadcrumbLabel,
        params: child.snapshot.params,
        url: routeURL,
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, routeURL, breadcrumbs);
    }
  }

}
