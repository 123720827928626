export const Blockchain = `
<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4.54199V8.54199" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M12 21.542V12.3215" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M19.5 8.54199L12 12.542L4 8.54199" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M10.5 3.54199L4 7.04199" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M13.5 3.54199L20 7.04199" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M3 9.54199V16.542" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M21 9.54199V16.542" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M4 19.042L10.5 23.042" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M20 19.042L13.5 23.042" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M8 15.542V10.542L12 8.54199L16 10.542V15.542L12 18.042L8 15.542Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M13.75 23.042C13.75 22.0755 12.9665 21.292 12 21.292C11.0335 21.292 10.25 22.0755 10.25 23.042C10.25 24.0085 11.0335 24.792 12 24.792C12.9665 24.792 13.75 24.0085 13.75 23.042Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M22.75 18.042C22.75 17.0755 21.9665 16.292 21 16.292C20.0335 16.292 19.25 17.0755 19.25 18.042C19.25 19.0085 20.0335 19.792 21 19.792C21.9665 19.792 22.75 19.0085 22.75 18.042Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M4.75 18.042C4.75 17.0755 3.9665 16.292 3 16.292C2.0335 16.292 1.25 17.0755 1.25 18.042C1.25 19.0085 2.0335 19.792 3 19.792C3.9665 19.792 4.75 19.0085 4.75 18.042Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M4.75 8.04199C4.75 7.07549 3.9665 6.29199 3 6.29199C2.0335 6.29199 1.25 7.07549 1.25 8.04199C1.25 9.00849 2.0335 9.79199 3 9.79199C3.9665 9.79199 4.75 9.00849 4.75 8.04199Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M22.75 8.04199C22.75 7.07549 21.9665 6.29199 21 6.29199C20.0335 6.29199 19.25 7.07549 19.25 8.04199C19.25 9.00849 20.0335 9.79199 21 9.79199C21.9665 9.79199 22.75 9.00849 22.75 8.04199Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M13.75 3.04199C13.75 2.07549 12.9665 1.29199 12 1.29199C11.0335 1.29199 10.25 2.07549 10.25 3.04199C10.25 4.00849 11.0335 4.79199 12 4.79199C12.9665 4.79199 13.75 4.00849 13.75 3.04199Z" stroke="currentColor" stroke-width="1.5"/>
<path d="M16 15.042L20 17.542" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M8 15.042L4 17.542" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
</svg>
`;
