import { Injectable } from '@angular/core';
import { AAGUID, IListResult, ListResult, parseArray } from '@interticket/core';
import { IType } from '@shared/interfaces/type/type.interface';
import { Type } from '@shared/models/type/type.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegionPackageType } from '../enums/region-package-type.enum';
import { IAdminPartner } from '../interfaces/admin-partner.interface';
import { IRegionPackage } from '../interfaces/region-package.interface';
import { IUserIdList } from '../interfaces/user-id-list.interface';
import { IUserPartnerAssociation } from '../interfaces/user-partner-association.interface';
import { IUserPartnerEditor } from '../interfaces/user-partner-editor.interface';
import { RegionPackage } from '../models/region-package.model';
import { RegionPackageCreator } from '../models/region-package-creator.model';
import { RegionPackageEditor } from '../models/region-package-editor.model';
import { UserPartnerAssociation } from '../models/user-partner-association.model';
import { UserPartnerRoleFilter } from '../models/user-partner-role-filter.model';
import { IamSourceService } from './iam-source.service';

@Injectable({
  providedIn: 'root',
})
export class IamService {

  constructor(
    private iamSourceService: IamSourceService
  ) { }

  /**
   * Returns all existing package types.
   */
  getPackageTypes(): Observable<Type<RegionPackageType>[]> {
    return this.iamSourceService.getPackageTypes()
      .pipe(
        map((iResult: IType<RegionPackageType>[]) => parseArray(iResult, Type) as Type<RegionPackageType>[])
      );
  }

  /**
   * Returns only the already created packages' types for the current partner.
   */
  getRegionPackageTypes(): Observable<Type<RegionPackageType>[]> {
    return this.iamSourceService.getRegionPackageTypes()
      .pipe(
        map((iResult: IType<RegionPackageType>[]) => parseArray(iResult, Type) as Type<RegionPackageType>[])
      );
  }

  getRegionPackages(): Observable<RegionPackage[]> {
    return this.iamSourceService.getRegionPackages()
      .pipe(
        map((iResult: IRegionPackage[]) => parseArray(iResult, RegionPackage))
      );
  }

  getRegionPackageList(regionId: AAGUID): Observable<ListResult<IRegionPackage, RegionPackage>> {
    return this.iamSourceService.getRegionPackageList(regionId)
      .pipe(
        map((iResult: IListResult<IRegionPackage>) => new ListResult<IRegionPackage, RegionPackage>(iResult, RegionPackage))
      );
  }

  getRegionPackage(packageId: AAGUID): Observable<RegionPackage> {
    return this.iamSourceService.getRegionPackage(packageId)
      .pipe(
        map((iResult: IRegionPackage) => new RegionPackage(iResult))
      );
  }

  createRegionPackage(regionPackageCreator: RegionPackageCreator, regionId: AAGUID): Observable<Response> {
    return this.iamSourceService.createRegionPackage(regionPackageCreator, regionId);
  }

  updateRegionPackage(regionPackageEditor: RegionPackageEditor, packageId: AAGUID): Observable<Response> {
    return this.iamSourceService.updateRegionPackage(regionPackageEditor, packageId);
  }

  /**
   * Return permission list of current user
   */
  getPermissions(): Observable<string[]> {
    return this.iamSourceService.getPermissions();
  }

  getPartnerRolesForUser(userGuid: AAGUID): Observable<ListResult<IUserPartnerAssociation, UserPartnerAssociation>> {
    return this.iamSourceService.getPartnerRolesForUser(userGuid)
      .pipe(
        map((iResult: IListResult<IUserPartnerAssociation>) =>
          new ListResult<IUserPartnerAssociation, UserPartnerAssociation>(iResult, UserPartnerAssociation))
      );
  }

  saveUserRoles(userPartnerEditor: IUserPartnerEditor): Observable<Response> {
    return this.iamSourceService.saveUserRoles(userPartnerEditor);
  }

  getAdminPartners(): Observable<IAdminPartner[]> {
    return this.iamSourceService.getAdminPartners();
  }

  getUserPartnerRole(filterParams: UserPartnerRoleFilter): Observable<IUserIdList> {
    return this.iamSourceService.getUserPartnerRole(filterParams);
  }

}
