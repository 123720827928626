import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ui-kit-step-tracker',
  templateUrl: './ui-kit-step-tracker.component.html',
  styleUrls: ['./ui-kit-step-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitStepTrackerComponent<T> {

  @Input() steps: T[] = [];
  @Input() currentStep: T;
  @Input() form: FormGroup;

  @Output() onStepChange: EventEmitter<T> = new EventEmitter();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  changeStep(step: T): void {
    this.onStepChange.emit(step);

    this.cdr.markForCheck();
  }

  isLastStep(index: number): boolean {
    return index !== this.steps.length - 1;
  }

  isValidStep(stepName: keyof T): boolean {
    const control = this.form.get(stepName as string);
    return control ? control.valid : false;
  }

  trackByFn(index: number): number {
    return index;
  }

}
