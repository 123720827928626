export enum PlacePermission {
  PLACE_LIST = 'smartcity.place.module.list',
  PLACE_READ_TYPE = 'smartcity.place.module',
  PLACE_DELETE = 'smartcity.place.module.delete',
  PLACE_DELETE_COLLECTION = 'smartcity.place.module.delete.places.of.a.service',
  PLACE_CREATE_DRAFT = 'smartcity.place.module.create.draft',
  PLACE_CREATE_PUBLISHED = 'smartcity.place.module.create.published',
  PLACE_UPDATE_DRAFT = 'smartcity.place.module.update.draft.place',
  PLACE_UPDATE_PUBLISHED = 'smartcity.place.module.update.published.place',
  PLACE_DETAILS = 'smartcity.place.module.details',
  PLACE_CREATE_RATING = 'smartcity.place.module.create.rating',
  PLACE_UPDATE_RATING = 'smartcity.place.module.delete.rating',
}
