<div class="chip-list">

  <ui-kit-chip-option
    *ngFor="let chip of chips; let idx = index; trackBy: trackByFn"
    [isActiveDisabled]="true"
    [isRippleDisabled]="true"
    [isHoverDisabled]="isHoverDisabled"
    [isDisabled]="chip.isDisabled || isDisabledSet"
    [isRemovable]="(removableSet === 'uniquePerChip' && chip.isRemovable) || removableSet === 'all'"
    [id]="chip.id"
    [value]="chip.value"
    [activePalette]="chip.palette || palette"
    [variant]="chip.variant || variant"
    [removeIcon]="removeIcon"
    (remove)="onRemoved(idx)"
    (click)="$event.preventDefault()">
  </ui-kit-chip-option>

</div>
