<ui-kit-icon
  class="info-icon icon"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave()"
  [ngStyle]="iconStyle"
  [icon]="'info'"
  [size]="18">
</ui-kit-icon>

<div class="popup-container" [class.show]="showTooltip" [ngStyle]="popupStyle">
  <div class="popup">
    <div class="tooltip-header" *ngIf="tooltipTitle">
      <ui-kit-icon *ngIf="tooltipTitle && tooltipTitleIcon" class="icon mr-1" [ngClass]="iconClass" [icon]="tooltipTitleIcon" [size]="16">
      </ui-kit-icon>
      <span class="info-label-text label-text">
        {{tooltipTitle}}
      </span>
    </div>
    <div class="info-label-text tooltip-content">
      {{tooltipContent}}
    </div>
  </div>
</div>
