export enum SurveyPermission {
  SURVEY_LIST = 'smartcity.survey.module.list',
  SURVEY_CREATE_DRAFT = 'smartcity.survey.module.create.draft',
  SURVEY_UPDATE_DRAFT = 'smartcity.survey.module.update.draft',
  SURVEY_PUBLISH = 'smartcity.survey.module.publish',
  SURVEY_CLOSE = 'smartcity.survey.module.close',
  SURVEY_LIST_PUBLISHED = 'smartcity.survey.module.list.published',
  SURVEY_DETAILS = 'smartcity.survey.module.details',
  SURVEY_ANSWER = 'smartcity.survey.module.answer',
  SURVEY_EXPORT = 'smartcity.survey.module.export',
  SURVEY_STATISTICS = 'smartcity.survey.module.statistics',
  ARCHIVE_SURVEY = 'smartcity.survey.module.archived',
  DUPLICATE = 'smartcity.survey.module.create.copy',
  ADD_TAGS = 'smartcity.survey.module.assign.tags.to.survey',
}
