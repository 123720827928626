import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AAGUID } from '@interticket/core';
import { IAutocompleteDropdownItem } from '@shared/interfaces/autocomplete-dropdown/autocomplete-dropdown-item.interface';
import { autocompleteDropdownValidator } from '@shared/modules/validators/autocomplete-dropdown.validator';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { IAutocompleteAsyncOptions } from '../../interfaces/autocomplete-async-options.interface';

@Component({
  selector: 'autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteDropdownComponent<ListItem> implements OnInit {

  private _asyncList: ListItem[] = [];
  private _list: IAutocompleteDropdownItem[] = [];
  private _selectedItem: IAutocompleteDropdownItem = null;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);
  filteredList$: Observable<IAutocompleteDropdownItem[]>;
  dropdownControl: UntypedFormControl = this.formBuilder.control('');

  @ViewChild('autocompleteInput') venueAutocompleteInput: ElementRef<HTMLInputElement>;
  @ContentChild(TemplateRef) listItemRef: TemplateRef<any>;

  @Input() listParam: boolean;
  @Input() label: string;
  @Input() placeholder = 'general_lang_select';
  @Input() resetValue = false;
  @Input() panelClass: string;
  @Input() asyncOptions: IAutocompleteAsyncOptions<ListItem>;
  @Input()
  set list(list: IAutocompleteDropdownItem[]) {
    this.setList(list);
  }

  @Input()
  set selectedItem(selectedItem: IAutocompleteDropdownItem) {
    this.dropdownControl.patchValue(selectedItem && selectedItem.name);
    this._selectedItem = selectedItem;
  }

  get selectedItem(): IAutocompleteDropdownItem {
    return this._selectedItem;
  }

  @Input()
  set selectedItemValue(selectedItemValue: string) {
    this.dropdownControl.patchValue(selectedItemValue);
    this._selectedItem = { id: selectedItemValue, name: selectedItemValue };
  }

  get selectedItemValue(): string {
    return this._selectedItem.id;
  }

  get isAsync(): boolean {
    return !!this.asyncOptions;
  }

  get isEmptyList(): boolean {
    return this.isAsync && !this.isLoading$.getValue() && this.dropdownControl.value?.length >= this.asyncOptions.minSearchLength && !this._list?.length;
  }

  @Output() selectedItemChange = new EventEmitter<IAutocompleteDropdownItem>();
  @Output() selectedAsyncItemChange = new EventEmitter<ListItem>();

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    if (this.isAsync) {
      this.createFilteredAsyncListObservable();
    } else {
      this.createFilteredListObservable();
    }
  }

  private setList(list: IAutocompleteDropdownItem[]): void {
    const dropdownList = Array.isArray(list) ? list : [];
    this._list = dropdownList;

    if (!this.isAsync) {
      this.dropdownControl.setValidators(autocompleteDropdownValidator(dropdownList));
      if (!dropdownList.length) {
        this.dropdownControl.disable();
      }
    }
  }

  private createFilteredListObservable(): void {
    this.filteredList$ = this.dropdownControl.valueChanges
      .pipe(
        startWith(this.dropdownControl.value),
        map((searchString: string) => this.filterItems(searchString || ''))
      );
  }

  private createFilteredAsyncListObservable(): void {
    this.filteredList$ = this.dropdownControl.valueChanges
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        filter((searchString: string) => searchString?.length >= 3),
        distinctUntilChanged(),
        tap(() => this.isLoading$.next(true)),
        debounceTime(300),
        switchMap((searchString: string) => this.getAsyncList(searchString)),
        map((list: ListItem[]) => list.map(item => this.asyncOptions.getProps(item))),
        tap(() => this.isLoading$.next(false)),
        tap(list => this.list = list),
      );
  }

  private getAsyncList(searchString: string): Observable<ListItem[]> {
    return this.asyncOptions.getList(searchString || '')
      .pipe(
        catchError(() => of([])),
        tap((list: ListItem[]) => this._asyncList = list),
      );
  }

  private filterItems(searchString: string): IAutocompleteDropdownItem[] {
    return this._list.filter((item: IAutocompleteDropdownItem) => item.name.toLowerCase().includes(searchString.toLowerCase()));
  }

  private revertAutocompleteInputValue(): void {
    if (this.dropdownControl.value) {
      if (this.selectedItem && this.selectedItem.name !== this.dropdownControl.value) {
        this.dropdownControl.patchValue(this.selectedItem.name);
      } else if (!this.selectedItem) {
        this.resetDropdownControl();
      }
    } else {
      this.selectedItem = null;
      this.emitItemChange(null);
      this.resetDropdownControl();
    }
  }

  private resetDropdownControl(): void {
    this.dropdownControl.patchValue('');

    if (this.isAsync) {
      this.createFilteredAsyncListObservable();
    }
  }

  private emitItemChange(selectedItem: IAutocompleteDropdownItem): void {
    if (this.isAsync) {
      const asyncItem: ListItem = this.getAsyncListItem(selectedItem?.id);
      this.selectedAsyncItemChange.emit(asyncItem);
    }

    this.selectedItemChange.emit(selectedItem);
  }

  getAsyncListItem(id: AAGUID): ListItem {
    return this._asyncList.find(item => this.asyncOptions.getProps(item).id === id);
  }

  onOptionSelected(id: AAGUID): void {
    this.venueAutocompleteInput?.nativeElement.blur();

    const selectedItem: IAutocompleteDropdownItem = this._list.find((item) => item.id === id);
    this.selectedItem = selectedItem;
    this.emitItemChange(selectedItem);

    if (this.resetValue) {
      this.resetDropdownControl();
    }
  }

  onCloseDropdown(): void {
    if (!this.resetValue) {
      this.revertAutocompleteInputValue();
    }
  }

}
