import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-kit-show-more',
  templateUrl: './ui-kit-show-more.component.html',
  styleUrls: ['./ui-kit-show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitShowMoreComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() readonly text: string;
  @Input() readonly shouldConstrainFullText = false;
  @Input() readonly fullTextConstraint = 255;

  @ViewChild('descriptionText', { static: true })
  private readonly descriptionText: ElementRef;

  showMoreLinkVisible = false;
  showMore = false;
  visibleText: string;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.calculateText();
  }

  ngAfterViewInit(): void {
    this.calculateVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text?.previousValue !== changes.text?.currentValue) {
      this.calculateVisibility();
    }

    this.calculateText();
  }

  onToggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  private calculateVisibility(): void {
    setTimeout(() => {
      this.showMoreLinkVisible = this.descriptionText.nativeElement.scrollHeight > this.descriptionText.nativeElement.offsetHeight;
      this.cdr.detectChanges();
    });
  }

  private calculateText(): void {
    if (!this.shouldConstrainFullText || this.text.length <= this.fullTextConstraint) {
      this.visibleText = this.text;
    } else {
      this.visibleText = `${this.text.substring(0, this.fullTextConstraint)}...`;
    }

    this.cdr.detectChanges();
  }

}
