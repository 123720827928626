import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ISlackMessage } from '@core/interfaces/error-handler/slack-message.interface';
import { APP_CONFIG, environment, IAppConfig } from '@env';
import { AppError, HttpService } from '@interticket/core';
import { BehaviorSubject } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import * as SourceMappedStackTrace from 'sourcemapped-stacktrace';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import { ErrorService } from './error.service';

@Injectable()
export class ErrorLoggerService {

  // Slack
  private readonly slackMessages$: BehaviorSubject<ISlackMessage[]> = new BehaviorSubject([]);
  private lastSlackMessage: string;

  // Stackdriver
  private stackdriverReporter: StackdriverErrorReporter;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private httpService: HttpService,
    private errorService: ErrorService,
  ) {
    this.appConfig.stackdriverApiKey && this.initStackDriver();
    this.appConfig.slackWebhookUrl && this.subscribeToSlackLogging();
  }

  get isLocalhost(): boolean {
    return !!window.location.port;
  }

  logError(error: AppError): void {
    const message: string = this.errorService.getErrorMessage(error).toString();
    const stack: string = error instanceof HttpErrorResponse ? '' : error.stack;

    SourceMappedStackTrace.mapStackTrace(stack, (mappedStack: string[]) => {
      const stackTrace: string = this.getMappedStackTrace(mappedStack);

      this.logToConsole(message, stackTrace);

      if (!this.isLocalhost) {
        this.appConfig.stackdriverApiKey && this.logToStackdriver(stackTrace && error);
        this.appConfig.slackWebhookUrl && this.createSlackMessage(message, stackTrace);
      }
    });
  }

  private initStackDriver(): void {
    this.stackdriverReporter = new StackdriverErrorReporter();
    this.stackdriverReporter.start({
      key: this.appConfig.stackdriverApiKey,
      projectId: this.appConfig.stackdriverProjectId,
      service: 'frontend-admin',
      version: `${this.appConfig.env.toUpperCase()} - v${environment.version}`,
      disabled: false,
    });
  }

  private subscribeToSlackLogging(): void {
    this.slackMessages$
      .pipe(
        filter((slackMessages: ISlackMessage[]) => !!slackMessages?.length),
        throttleTime(1000),
      )
      .subscribe({
        next: (slackMessages: ISlackMessage[]) => {
          this.logToSlack(slackMessages);
          this.slackMessages$.next([]);
        },
      });
  }

  private logToSlack(slackMessages: ISlackMessage[]): void {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      responseType: 'text',
    };

    this.httpService.post<Response>(this.appConfig.slackWebhookUrl, { attachments: slackMessages }, options, { static: true })
      .subscribe();
  }

  private createSlackMessage(message: string, stack = ''): void {
    const slackMessage: ISlackMessage = {
      pretext: `StemX admin - ${this.appConfig.env.toUpperCase()}:v${environment.version}`,
      title: message,
      text: stack,
      color: 'danger',
      author_name: window.location.href,
      author_link: window.location.href,
    };

    const stringifiedMessage = JSON.stringify(slackMessage);

    if (stringifiedMessage !== this.lastSlackMessage) {
      this.slackMessages$.next([...this.slackMessages$.getValue(), slackMessage]);
    }

    this.lastSlackMessage = stringifiedMessage;
  }

  private logToConsole(message: string, stack: string): void {
    console.error(`%cErrorLoggerService\n`, 'font-weight: bold', `${message}\n`, stack);
  }

  private logToStackdriver(error: AppError): void {
    error && this.stackdriverReporter.report(error);
  }

  private getMappedStackTrace(stack: string[]): string {
    const stackTrace: string[] = Array.isArray(stack) ? stack : [stack];
    return stackTrace.join('\n');
  }

}
