import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AAGUID, IImageTransform, SafePipe } from '@interticket/core';
import { MediaService } from '../services/media.service';

@Pipe({
  name: 'imageSrc',
})
export class ImageSrcPipe implements PipeTransform {

  constructor(
    private mediaService: MediaService,
    private safePipe: SafePipe,
  ) { }

  transform(imageGuid: AAGUID, transform?: IImageTransform): SafeResourceUrl {
    return this.safePipe.transform(this.mediaService.getImageUrl(imageGuid, transform), 'url');
  }

}
