<div class="row">
  <div class="columns small-12 medium-6">

    <h2>{{ featureColumnLabel | translate }}</h2>

    <!-- SEARCH TEXT -->
    <label for="searchTextInput">{{ 'general_lang_search' | translate }}
      <div class="flex-center-middle">
        <input type="text" (keydown.enter)="$event.preventDefault()" placeholder="{{ 'general_lang_search' | translate }}" autocomplete="off" id="searchTextInput" [formControl]="searchTextControl" #searchTextInput>
        <button type="button" class="button hollow icon small" (click)="clear()" matTooltip="{{ 'label_form_reset' | translate }}">
          <i class="material-icons">clear</i>
        </button>
      </div>
    </label>

    <!-- ALL FEATURES -->
    <app-select-group
      [list]="featureList"
      [disableEdit]="disableEdit"
      [showMode]="allFeatureShowMode"
      [searchText]="searchTextControl.value"
      (featureToggled)="onFeatureToggled($event)">
    </app-select-group>

  </div>
  <div class="columns small-12 medium-6">

    <h2>{{ selectedColumnLabel | translate }}</h2>

    <div class="custom-content">
      <ng-content></ng-content>
    </div>

    <!-- SELECTED FEATURES -->
    <app-select-group
      [list]="featureList"
      [disableEdit]="disableEdit"
      [showMode]="selectedFeatureShowMode"
      (featureToggled)="onFeatureToggled($event)">
    </app-select-group>
    <app-show-errors class="independent-error" [control]="featureIdsControl"></app-show-errors>

  </div>
</div>
