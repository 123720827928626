import { BillingInformationView } from '@core/models/billing/billing-information-view.model';
import { Company } from '@core/models/company/company.model';
import { Contact } from '@core/models/contact/contact.model';
import { AAGUID, parseArray } from '@interticket/core';
import { IRegionPackage, RegionPackage } from '@services/iam';
import { IIamManagementRegionPartnerAdmin } from '@services/iam-management-region-partner';
import { IPartnerCreatorWebConfig } from '../interfaces/partner-creator-web-config.interface';
import { IPartnerInvite } from '../interfaces/partner-invite.interface';

export class PartnerInvite implements IPartnerInvite {

  readonly baseTemplateId: AAGUID;
  readonly billingInformation: BillingInformationView;
  readonly company: Company;
  readonly contacts: Contact[];
  readonly flatRate: number;
  readonly package: Partial<RegionPackage>;
  readonly partnerAdmin: IIamManagementRegionPartnerAdmin;
  readonly partnerName: string;
  readonly percentage: number;
  readonly slug: string;
  readonly web: IPartnerCreatorWebConfig;

  constructor(iPartnerInvite: IPartnerInvite) {
    this.baseTemplateId = iPartnerInvite.baseTemplateId;
    this.billingInformation = new BillingInformationView(iPartnerInvite.billingInformation);
    this.company = new Company(iPartnerInvite.company);
    this.contacts = parseArray(iPartnerInvite.contacts, Contact);
    this.flatRate = iPartnerInvite.flatRate;
    this.package = new RegionPackage(iPartnerInvite.package as IRegionPackage);
    this.partnerAdmin = iPartnerInvite.partnerAdmin;
    this.partnerName = iPartnerInvite.partnerName;
    this.percentage = iPartnerInvite.percentage;
    this.slug = iPartnerInvite.slug;
    this.web = iPartnerInvite.web;
  }

}
