import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

const slideDefaultParams = {
  duration: '200ms',
  delay: '0ms',
  opacity: '0',
  x: '0',
  y: '0',
};

export const slideInRight: AnimationTriggerMetadata = trigger('slideInRight', [
  state('0, false, hide', style({ opacity: '{{ opacity }}', transform: 'translateX({{ x }})' }), { params: slideDefaultParams }),
  state('1, true, show', style({ opacity: 1, transform: 'translateX(0)' })),
  transition('hide => show', animate('{{ duration }} {{ delay }} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*')), { params: slideDefaultParams }),
  transition('false => true', animate('{{ duration }} {{ delay }} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*')), { params: slideDefaultParams }),
  transition('0 => 1', animate('{{ duration }} {{ delay }} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*')), { params: slideDefaultParams }),
]);
