import { Component } from '@angular/core';

@Component({
  selector: 'custom-sidebar',
  templateUrl: './custom-sidebar.component.html',
  styleUrls: ['./custom-sidebar.component.scss'],
})
export class CustomSidebarComponent {

}
