import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiKitLoaderComponent } from '@interticket/core';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-spinner',
  templateUrl: './ui-kit-spinner.component.html',
  styleUrls: ['./ui-kit-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitSpinnerComponent extends UiKitLoaderComponent {

  @Input() readonly palette: UiKitPalette;

  @HostBinding('class') get getPaletteClass() {
    return this.palette && `ui-kit-palette-${this.palette}`;
  }

  @HostBinding('style.height') get height() {
    return this.size ? `${this.size}px` : '100%';
  }

}
