export enum BlockchainPermission {
  // Blockchain config
  BLOCKCHAIN_CONFIG_CREATE = 'blockchain.create.blockchain.configuration',
  BLOCKCHAIN_CONFIG_DELETE = 'blockchain.delete.blockchain.configuration',
  BLOCKCHAIN_CONFIG_EDIT = 'blockchain.edit.blockchain.configuration',
  BLOCKCHAIN_CONFIG_LIST = 'blockchain.list.and.search.blockchain.configuration',
  BLOCKCHAIN_CONFIG_VIEW = 'blockchain.view.blockchain.configuration',

  // Smart Contract
  SMART_CONTRACT_CREATE = 'blockchain.create.smart.contracts',
  SMART_CONTRACT_DEPLOY_ERC20 = 'blockchain.deploy.erc-20.smart.contract',
  SMART_CONTRACT_DEPLOY_ERC721 = 'blockchain.deploy.erc-721.smart.contract',
  SMART_CONTRACT_DELETE = 'blockchain.delete.smart.contracts',
  SMART_CONTRACT_EDIT = 'blockchain.edit.smart.contracts',
  SMART_CONTRACT_LIST = 'blockchain.list.and.search.smart.contracts',
  SMART_CONTRACT_VIEW = 'blockchain.view.smart.contracts',

  // Service
  SERVICE_CREATE = 'blockchain.create.services',
  SERVICE_LIST = 'blockchain.list.and.search.services',
  SERVICE_VIEW = 'blockchain.view.services',
  SERVICE_EDIT = 'blockchain.edit.services',
  SERVICE_DELETE = 'blockchain.delete.services',
}
