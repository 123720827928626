export enum ReportingPermission {
  // Admission
  USE_ADMISSION_REPORT = 'reports.use.admission.report',
  ADMISSION_REPORT_EXPORT_TO_EXCEL = 'reports.export.admission.report',

  // Gift card
  USE_GIFT_CARD_REPORT = 'reports.use.giftcard.report',
  USE_GIFT_CARD_EXPORT_TO_EXCEL = 'reports.export.giftcard.report',

  // Financial summary
  FINANCIAL_REPORT_LIST = 'reports.list.and.search.financial.summary.report',
  FINANCIAL_REPORT_EXPORT_TO_CSV = 'reports.export.financial.summary.report.results.to.excel',
}
