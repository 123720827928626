import { FilterParams } from '@interticket/core';
import { IUserSearchFilter } from '../interfaces/user-search-filter.interface';

export class UserSearchFilter extends FilterParams implements IUserSearchFilter {

  readonly address: string;
  readonly areaInterest: string;

  constructor(iFilterParams: IUserSearchFilter) {
    super();
    this.address = iFilterParams.address || null;
    this.areaInterest = iFilterParams.areaInterest || null;
  }

}
