import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AAGUID, IImageTransform } from '@interticket/core';

@Component({
  selector: 'ui-kit-thumbnail',
  templateUrl: './ui-kit-thumbnail.component.html',
  styleUrls: ['./ui-kit-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitThumbnailComponent {

  @Input() imageId: AAGUID;
  @Input() transform: IImageTransform;
  @Input() showOverlay = false;
  @Input() onDialog = false;
  @Input() width: 'unset' | number = 200;

  addThumbnailSizeToSrc(imageId: AAGUID): string {
    const query = this.width === 'unset' ? '' : `?tr=w-${this.width}`;

    return `${imageId}${query}`;
  }

}
