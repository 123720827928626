<label class="autocomplete-label">{{ label | translate }}
  <mat-spinner *ngIf="isLoadingOptionsList$ | async" [diameter]="16"></mat-spinner>
  <div class="custom-row" [ngClass]="!isChipsInside? 'flex-row': ''">
    <div [ngClass]="!isChipsInside ? 'chips-input' : ''">

      <mat-form-field class="legacy-form-field" appearance="outline" [floatLabel]="'never'">
        <mat-chip-grid #chipGrid [disabled]="searchTextControl.disabled">

          <!-- CHIPS -->
          <div *ngIf="isChipsInside">
            <ng-container *ngFor="let option of chipDropdownOptions$ | async; trackBy: trackByFn">
              <mat-chip-row *ngIf="option.isSelected" (removed)="toggleChipOption(option)">
                <span title="{{ option.name }}">{{ option.displayableName ? option.displayableName: option.name }}</span>
                <mat-icon *ngIf="isChipClosable" matChipRemove>cancel</mat-icon>
              </mat-chip-row>
            </ng-container>
          </div>

          <!-- INPUT -->
          <input
            #searchInput
            [readonly]="!hasPermissionToEdit"
            [formControl]="searchTextControl"
            [matAutocomplete]="autoComp"
            [matChipInputFor]="chipGrid"
            [matChipInputAddOnBlur]="true"
            placeholder="{{ placeholder | translate }}">

          <div matSuffix *ngxPermissionsOnly="chipCreatablePermissions">
            <span *ngxPermissionsOnly="chipEditablePermissions">
              <span *ngIf="isChipCreatable" class="add-item" [class.disabled]="!searchTextControl.valid" (click)="addTag(searchTextControl.value)">{{ 'stemx_add_new' | translate }}</span>
            </span>
          </div>
        </mat-chip-grid>

        <!-- AUTOCOMPLETE -->
        <mat-autocomplete #autoComp="matAutocomplete" [displayWith]="displayFn" (optionSelected)="toggleChipOption($event.option.value)">
          <!-- OPTIONS WITHOUT CATEGORY-->
          <ng-container *ngFor="let option of chipDropdownOptions$ | async; trackBy: trackByFn">
            <mat-option *ngIf="!option.category && option.isVisible" [value]="option">
              <div (click)="optionClicked($event,option)">
                <mat-checkbox [checked]="option.isSelected">
                  {{ option.name }}
                </mat-checkbox>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- CHIPS OUTSIDE -->
    <mat-chip-set class="chips-outside" *ngIf="!isChipsInside" (click)="$event.preventDefault()">
      <ng-container
        *ngFor=" let option of chipDropdownOptions$ | async; trackBy: trackByFn"
        (click)="$event.preventDefault()">
        <mat-chip
          class="chip-label"
          *ngIf="option.isSelected"
          (removed)="toggleChipOption(option)">
          {{ option.displayableName ? option.displayableName: option.name }}
          <mat-icon
            matChipRemove
            *ngIf="isChipClosable && hasPermissionToEdit"
            (click)="$event.preventDefault()">
            cancel
          </mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-set>
  </div>

  <div class="flex-middle">
    <mat-error *ngIf="showError$ | async" [@showErrorAnimation]>{{ 'stemx_boxoffice_customer_search_empty' | translate }}</mat-error>
    <app-show-errors [control]="searchTextControl"></app-show-errors>
  </div>

</label>
