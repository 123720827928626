import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiKitLabelComponent } from '../ui-kit-label/ui-kit-label.component';

@Component({
  selector: 'ui-kit-info-label',
  templateUrl: './ui-kit-info-label.component.html',
  styleUrls: ['./ui-kit-info-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitInfoLabelComponent extends UiKitLabelComponent {

  readonly isRounded: boolean = true;

}
