import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IImageDialogConfig } from '../../interfaces/image-dialog-config.interface';

@Component({
  selector: 'image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: IImageDialogConfig,
    public dialogRef: MatDialogRef<ImageDialogComponent>,
  ) { }

  close() {
    this.dialogRef.close();
  }

}
