/* eslint-disable simple-import-sort/imports */
import { AppRouter } from './app.routes';

// Angular Modules
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Locale datas
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import localeHuExtra from '@angular/common/locales/extra/hu';
import localePlExtra from '@angular/common/locales/extra/pl';
import localeHu from '@angular/common/locales/hu';
import localePl from '@angular/common/locales/pl';

// Extrenal Node Modules
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

// Configs
import { toastrGlobalConfig } from '@config/toastr/toastr.config';
import { APP_CONFIG, IAppConfig } from '@env';

// App Core Components
import { AppRouterComponent } from './app.router';
import { AppSkeletonRouterComponent } from './modules/app-skeleton/app-skeleton.router';
import { AuthRouterComponent } from './modules/auth/auth.router';

// Shared services
import { GlobalErrorHandler } from '@core/services/error-handler/global-error-handler.service';
import { AppHttpInterceptor } from '@core/services/http/http-interceptor';
import { PaginatorInlService } from '@core/services/paginator-intl/paginator-intl.service';
import { HTTP_MODULE_CONFIG, IHttpModuleConfig, MediaUrlService, TranslateService } from '@interticket/core';

// Core Modules
import { CoreModule } from '@core/core.module';
import { appInitializerFactory, AppInitializerService } from '@core/services/app-initializer/app-initializer.service';
import { UiKitElementsModule } from '@ui-kit/elements';

// Material imports
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions, MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

registerLocaleData(localeHu, 'hu', localeHuExtra);
registerLocaleData(localeDa, 'da', localeDaExtra);
registerLocaleData(localePl, 'pl', localePlExtra);

@NgModule({
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRouter,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(toastrGlobalConfig),
    GoogleMapsModule,
    UiKitElementsModule,
  ],

  declarations: [
    AppRouterComponent,
    AuthRouterComponent,
    AppSkeletonRouterComponent,
  ],

  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => translate.getLocaleId(),
      deps: [TranslateService],
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'never' } },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new PaginatorInlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
    {
      provide: HTTP_MODULE_CONFIG,
      useFactory: (config: IAppConfig): IHttpModuleConfig => ({ serviceUrl: config.serviceUrl }),
      deps: [APP_CONFIG],
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { formFieldAppearance: 'fill' } as MatPaginatorDefaultOptions,
    },
    {
      provide: MediaUrlService,
      useFactory: (config: IAppConfig) => new MediaUrlService({
        cdnBaseUrl: config.cdnReferenceUrl,
      }),
      deps: [APP_CONFIG],
    },
    provideEnvironmentNgxMask(),
  ],

  bootstrap: [
    AppRouterComponent,
  ],
})
export class AppModule { }
