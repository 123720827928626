import { AfterViewInit, Directive, Host, Optional, Self } from '@angular/core';
import { OwlDateTimeInlineComponent } from '@danielmoncada/angular-datetime-picker';
import { DateTime } from 'luxon';

@Directive({
  selector: '[owlDateTimeToLabelSelectOverride]',
})
export class OwlDateTimeToLabelSelectOverrideDirective implements AfterViewInit {

  constructor(@Host() @Self() @Optional() private owlDateTimeInline: OwlDateTimeInlineComponent<DateTime>) { }

  ngAfterViewInit(): void {
    // has to be on the AfterViewInit so the container (ViewChild) is existing
    this.overrideOwlDateTimeContainerMethods();
  }

  private overrideOwlDateTimeContainerMethods(): void {
    const owlDateTimeContainer = this.owlDateTimeInline['container'];

    if (owlDateTimeContainer) {
      this.addIsToLabelSelected(owlDateTimeContainer);
      this.overrideHandleClickOnInfoGroup(owlDateTimeContainer);
      this.overrideDateSelectedInRangeMode(owlDateTimeContainer);
    } else {
      console.error('owlDateTimeContainerComponent not found.');
    }
  }

  private addIsToLabelSelected(owlDateTimeContainer: typeof this.owlDateTimeInline['container']): void {
    owlDateTimeContainer['isToLabelSelected'] = false;
  }

  private overrideHandleClickOnInfoGroup(owlDateTimeContainer: typeof this.owlDateTimeInline['container']): void {
    const originalHandleClickOnInfoGroup = owlDateTimeContainer.handleClickOnInfoGroup;
    owlDateTimeContainer.handleClickOnInfoGroup = (event: any, index: number): void => {
      owlDateTimeContainer['isToLabelSelected'] = index === 1;

      originalHandleClickOnInfoGroup.call(owlDateTimeContainer, event, index);
    };
  }

  // the dateSelectedInRangeMode is copied here from OwlDateTimeContainerComponent, the only differences is the new condition and the new line (the commented lines)
  private overrideDateSelectedInRangeMode(owlDateTimeContainer: typeof this.owlDateTimeInline['container']): void {
    owlDateTimeContainer['dateSelectedInRangeMode'] = (date: DateTime): DateTime[] | null => {
      let from = owlDateTimeContainer.picker.selecteds[0];
      let to = owlDateTimeContainer.picker.selecteds[1];

      const result = owlDateTimeContainer['updateAndCheckCalendarDate'](date);

      const owlDateTimeContainerPrivateMethods = (owlDateTimeContainer as any);

      if (!result) {
        return null;
      }

      if (owlDateTimeContainer.picker.selectMode === 'range') {
        if (owlDateTimeContainer.picker.selecteds &&
          owlDateTimeContainer.picker.selecteds.length &&
          (!to || owlDateTimeContainer['isToLabelSelected']) && // New condition
          from &&
          owlDateTimeContainerPrivateMethods.dateTimeAdapter.differenceInCalendarDays(result, from) >= 0) {
          owlDateTimeContainer['isToLabelSelected'] = false; // New line
          if (owlDateTimeContainer.picker.endAt && !owlDateTimeContainerPrivateMethods.retainEndTime) {
            to = owlDateTimeContainerPrivateMethods.dateTimeAdapter.createDate(
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getYear(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMonth(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getDate(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getHours(owlDateTimeContainer.picker.endAt),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMinutes(owlDateTimeContainer.picker.endAt),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getSeconds(owlDateTimeContainer.picker.endAt));
          } else if (owlDateTimeContainerPrivateMethods.retainEndTime) {
            to = owlDateTimeContainerPrivateMethods.dateTimeAdapter.createDate(
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getYear(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMonth(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getDate(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getHours(owlDateTimeContainerPrivateMethods.retainEndTime),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMinutes(owlDateTimeContainerPrivateMethods.retainEndTime),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getSeconds(owlDateTimeContainerPrivateMethods.retainEndTime));
          } else {
            to = result;
          }
          owlDateTimeContainer.activeSelectedIndex = 1;
        } else {
          if (owlDateTimeContainer.picker.startAt && !owlDateTimeContainerPrivateMethods.retainStartTime) {
            from = owlDateTimeContainerPrivateMethods.dateTimeAdapter.createDate(
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getYear(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMonth(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getDate(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getHours(owlDateTimeContainer.picker.startAt),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMinutes(owlDateTimeContainer.picker.startAt),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getSeconds(owlDateTimeContainer.picker.startAt)
            );
          } else if (owlDateTimeContainerPrivateMethods.retainStartTime) {
            from = owlDateTimeContainerPrivateMethods.dateTimeAdapter.createDate(
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getYear(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMonth(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getDate(result),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getHours(owlDateTimeContainerPrivateMethods.retainStartTime),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getMinutes(owlDateTimeContainerPrivateMethods.retainStartTime),
              owlDateTimeContainerPrivateMethods.dateTimeAdapter.getSeconds(owlDateTimeContainerPrivateMethods.retainStartTime));
          } else {
            from = result;
          }
          to = null;
          owlDateTimeContainer.activeSelectedIndex = 0;
        }
      } else if (owlDateTimeContainer.picker.selectMode === 'rangeFrom') {
        from = result;

        if (to && owlDateTimeContainerPrivateMethods.dateTimeAdapter.compare(from, to) > 0) {
          to = null;
        }
      } else if (owlDateTimeContainer.picker.selectMode === 'rangeTo') {
        to = result;

        if (from && owlDateTimeContainerPrivateMethods.dateTimeAdapter.compare(from, to) > 0) {
          from = null;
        }
      }

      return [from, to];
    };
  }

}
