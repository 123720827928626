export enum EmailTemplatesPermission {
  DELETE = 'email-templates.delete',
  DETAILS = 'email-templates.view',
  CREATE_MASTER = 'email-templates.create.master',
  CREATE_CUSTOM = 'email-templates.create.custom',
  EDIT_MASTER = 'email-templates.edit.master',
  EDIT_CUSTOM = 'email-templates.edit.custom',
  COPY = 'email-templates.copy',
  SEND = 'email-templates.send.test.email',
  LIST = 'email-templates.list.and.search',
  RESTORE = 'email-templates.restore',
}
