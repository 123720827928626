import { IBillingInformationView } from '@core/interfaces/billing/billing-information-view.interface';
import { ICompany } from '@core/interfaces/company/company.interface';
import { IRegionPackageCreator } from '@services/iam';
import { IIamManagementRegionPartnerAdmin } from '@services/iam-management-region-partner';
import { IPartnerCreatorWebConfig } from '../interfaces/partner-creator-web-config.interface';
import { IPartnerInvite } from '../interfaces/partner-invite.interface';
import { IPartnerCreationForm } from '../types/partner-creation-form.type';

export class PartnerCreatePayload implements IPartnerInvite {

  readonly billingInformation: IBillingInformationView;
  readonly company: ICompany;
  readonly package: IRegionPackageCreator;
  readonly partnerAdmin: IIamManagementRegionPartnerAdmin;
  readonly partnerName: string;
  readonly slug: string;
  readonly web: IPartnerCreatorWebConfig;

  constructor(createForm: IPartnerCreationForm) {
    const generalTab = createForm.generalTab;
    const hasPartnerPage = !!generalTab.partnerPageDomain;
    const hasRegionSubPage = !!generalTab.regionSubPageSlug;
    const legalEntityTab = createForm.legalEntityTab;

    this.billingInformation = legalEntityTab.billingInformation;
    this.company = legalEntityTab.company;
    this.package = createForm.featureTab;
    this.partnerAdmin = {
      email: generalTab.email,
      firstName: generalTab.firstName,
      lastName: generalTab.lastName,
    };
    this.partnerName = generalTab.partnerName;
    this.slug = generalTab.regionPartnerSlug;
    this.web = {
      partnerPageActivationAt: hasPartnerPage ? generalTab.partnerPageActivationAt?.startOf('minute')?.toISO() : null,
      partnerPageDomain: hasPartnerPage ? generalTab.partnerPageDomain : null,
      partnerPageEnabled: hasPartnerPage,
      partnerPageTemplate: hasPartnerPage ? generalTab.partnerPageTemplate : null,
      regionPageEnabled: false, // TODO - region page settings are not implemented yet
      regionSubPageEnabled: hasRegionSubPage,
      regionSubPageSlug: hasRegionSubPage ? generalTab.regionSubPageSlug : null,
    };
  }

}
