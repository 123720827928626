<div class="switcher-container">
  <div class="image-wrapper">
    <ui-kit-thumbnail [imageId]="images[currentIndex]"></ui-kit-thumbnail>
    <ui-kit-icon
      class="switcher-icon previous-icon"
      [size]="24"
      [icon]="'chevron-left'"
      (click)="previousImage()"
      *ngIf="images.length > 1">
    </ui-kit-icon>
    <ui-kit-icon
      class="switcher-icon next-icon"
      [size]="24"
      [icon]="'chevron-right'"
      (click)="nextImage()"
      *ngIf="images.length > 1">
    </ui-kit-icon>
    <div class="image-indicator" *ngIf="images.length > 1">
      <div
        class="dot-wrapper"
        *ngFor="let image of images; let i = index"
        (click)="goToImage(i)">
        <span [class.active]="currentIndex === i"></span>
      </div>
    </div>
  </div>
</div>
