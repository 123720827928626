export enum PlacePermission {
  CREATE_AND_EDIT_AUDITORIUM_LAYOUT = 'places.create.and.edit.auditorium.layout',
  CREATE_NEW_PLACE = 'places.create.new.place',
  CREATE_NON_SEATED_AUDITORIUM = 'places.create.dynamic.auditorium.-.v2',
  CREATE_PRICING_MAP = 'places.create.pricing.map',
  CREATE_SEATED_AUDITORIUM = 'places.create.seated.auditorium',
  DELETE_AND_RESTORE_AUDITORIUM = 'places.delete.and.restore.auditorium',
  DELETE_AND_RESTORE_AUDITORIUM_LAYOUT = 'places.delete.and.restore.auditorium.layout',
  DELETE_AND_RESTORE_PLACE = 'places.delete.and.restore.place',
  DELETE_AND_RESTORE_PRICING_MAP = 'places.delete.and.restore.pricing.map',
  EDIT_AUDITORIUM = 'places.edit.auditorium.-.v2',
  EDIT_PLACE = 'places.edit.place',
  LIST_AND_SEARCH = 'places.list.and.search.places',
  VIEW_AUDITORIUM = 'places.view.auditorium.-.v2',
  VIEW_PLACE = 'places.view.place',
  SAVE_LAYOUT_DRAFT = 'places.save.auditorium.layout.as.draft',
  SAVE_PRICING_MAP_DRAFT = 'places.save.auditorium.pricing.map.as.draft',
}
