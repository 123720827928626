import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IUiKitTabbedNavbarItem } from '@ui-kit/elements';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'ui-kit-tabbed-navbar',
  templateUrl: './ui-kit-tabbed-navbar.component.html',
  styleUrls: ['./ui-kit-tabbed-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitTabbedNavbarComponent implements OnInit, OnChanges {

  @Input() items: IUiKitTabbedNavbarItem[];

  visibleItems: IUiKitTabbedNavbarItem[] = [];

  readonly buttonSizes = UiKitSize;
  readonly palette = UiKitPalette;

  constructor(
    private readonly permissionService: NgxPermissionsService,
  ) { }

  ngOnInit(): void {
    this.hasMultipleItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.hasMultipleItems();
    }
  }

  private hasMultipleItems(): void {
    this.visibleItems = this.items.filter(item => {
      if (item.permissions === undefined) {
        return true;
      }

      return item.permissions?.every((permission) => this.permissionService.getPermission(permission));
    });
  }

}
