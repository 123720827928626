<label class="autocomplete-label">{{ label | translate }}
  <mat-spinner *ngIf="isLoading$ | async" [diameter]="16"></mat-spinner>
  <mat-form-field class="legacy-form-field" appearance="outline" [floatLabel]="'never'">
    <mat-chip-grid #chipList aria-label="chips">
      <mat-chip-row *ngFor="let item of itemIdsControl.value" (removed)="remove(item)">
        {{ item }}
      </mat-chip-row>
      <input #matChipInput
        autocomplete="off"
        [placeholder]="placeholder"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
  </mat-form-field>
</label>
