<button
  class="chip-btn"
  type="button"
  [class.active-disabled]="isActiveDisabled"
  [class.hover-disabled]="isHoverDisabled">

  <mat-chip
    uiKitChipRippleDisable
    [disableRipple]="isRippleDisabled"
    [disabled]="isDisabled">
    <span class="max-chip-text-length">
      {{ value | translate }}
    </span>

    <div class="remove" *ngIf="isRemovable">
      <ui-kit-button-icon
        matChipRemove
        [rippleDisabled]="true"
        [icon]="removeIcon"
        (onClick)="onRemoved()">
      </ui-kit-button-icon>
    </div>
  </mat-chip>

</button>
