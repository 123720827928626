import { Component, Input } from '@angular/core';
import { scaleOpacityAnimation } from '@shared/animation/scale-opacity-animation';

@Component({
  selector: 'datatable-spinner',
  templateUrl: './datatable-spinner.component.html',
  styleUrls: ['./datatable-spinner.component.scss'],
  animations: [scaleOpacityAnimation],
})
export class DatatableSpinnerComponent {

  @Input() readonly isVisible: boolean;

}
