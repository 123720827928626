import { IPartnerSettingsConfigMailSettings, IPartnerSettingsConfigMailSettingsGroup } from '../interfaces/partner-settings-confirm-mail.interface';

export class PartnerOrganizerConfigMailSettings implements IPartnerSettingsConfigMailSettingsGroup {

  readonly confirmationMail: IPartnerSettingsConfigMailSettings;

  constructor(iConfig: IPartnerSettingsConfigMailSettingsGroup) {
    this.confirmationMail = iConfig?.confirmationMail ? {
      senderMailName: iConfig.confirmationMail?.senderMailName || null,
      senderMailAddress: iConfig.confirmationMail?.senderMailAddress || null,
      forwardToAllEmails: !!iConfig.confirmationMail?.forwardToAllEmails,
      emailsToForward: Array.isArray(iConfig.confirmationMail?.emailsToForward) ? iConfig.confirmationMail.emailsToForward : [],
    } : null;
  }

}
