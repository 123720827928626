import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { accordionAnimation } from '@shared/animation/accordion.animation';

@Component({
  selector: 'ui-kit-expander',
  templateUrl: './ui-kit-expander.component.html',
  styleUrls: ['./ui-kit-expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [accordionAnimation],
})
export class UiKitExpanderComponent implements OnInit, OnChanges {

  title: string;

  @Input() readonly titles: [string, string] = ['show_more_settings', 'hide_more_settings'];
  @Input() borderRadiusPx = 0;
  @Input() isOpened = false;
  @Input() isDisabled = false;
  @Input() isRoundHeader = false;
  @Input() onlyToggleOnIcon = false;

  @Input()
  @HostBinding('class.centerable')
  readonly centered: boolean;

  @Input()
  @HostBinding('class.justify-between')
  readonly justifyBetween: boolean;

  @HostBinding('class.centered') get isCentered(): boolean {
    return this.centered && !this.isOpened;
  }

  @Output() isOpenedChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.title = this.getTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isOpened' in changes && (changes.isOpened.currentValue !== changes.isOpened.previousValue)) {
      this.title = this.getTitle();
    }
  }

  get headerRadius(): string {
    if (this.isRoundHeader) {
      return `${this.borderRadiusPx}px`;
    }

    if (this.centered) {
      return this.isOpened ? '0' : `${this.borderRadiusPx}px`;
    }

    return this.isOpened ? `${this.borderRadiusPx}px ${this.borderRadiusPx}px 0 0` : `${this.borderRadiusPx}px`;
  }

  get contentRadius(): string {
    if (this.centered) {
      return this.isOpened ? '0' : `${this.borderRadiusPx}px`;
    }

    return this.isOpened ? `0 0 ${this.borderRadiusPx}px ${this.borderRadiusPx}px` : '0';
  }

  onHeaderClick(): void {
    !this.onlyToggleOnIcon && this.toggleExpander();
  }

  onIconClick(): void {
    this.onlyToggleOnIcon && this.toggleExpander();
  }

  private toggleExpander(): void {
    if (!this.isDisabled) {
      this.isOpened = !this.isOpened;
      this.title = this.getTitle() || this.titles[0];
      this.isOpenedChange.emit(this.isOpened);
    }
  }

  private getTitle(): string {
    return this.titles[+this.isOpened];
  }

}
