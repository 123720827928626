import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AAGUID, EncodedHttpParams, HttpService, IListResult, IPartnerConfig, IPartnerOrganizerWebSettings, IPartnerWebConfig, IPlaceModuleConfig, IStatus, Mock, PARTNER_CONFIG, PARTNER_WEB_CONFIG, SearchParams } from '@interticket/core';
import { IPartnerHierarchyListItem } from '@interticket/template-layout-editor-angular';
import { INTAKConfig, NTAK_CONFIG } from '@services/iam-management-ntak';
import { IPartnerOrganizerCheckoutSurveySettings } from '@services/iam-management-partner';
import { GuestListExportField } from '@services/report';
import { PARTNER_HIERARCHY_LIST } from '@services/ticket';
import { IResultCount } from '@shared/interfaces/result-count/result-count.interface';
import { IInitSagaFlowResponse } from '@shared/interfaces/saga/init-saga-flow-response.interface';
import { Observable } from 'rxjs';
import { PartnerStatus } from '../enums/partner-status.enum';
import { IPartnerCreatorWebConfig } from '../interfaces/partner-creator-web-config.interface';
import { IPartnerDetailsEditor } from '../interfaces/partner-detail-editor.interface';
import { IPartnerEditDetail } from '../interfaces/partner-edit-detail.interface';
import { IPartnerEditFeatureSettings } from '../interfaces/partner-edit-feature-settings.interface';
import { IContactIds, IPartnerEditGeneralData, IPartnerEditGeneralDataContact } from '../interfaces/partner-edit-general-data.interface';
import { IPartnerEditGeneralSettings } from '../interfaces/partner-edit-general-settings.interface';
import { IPartnerEditInvoiceSettings } from '../interfaces/partner-edit-invoice-setting.interface';
import { IPartnerEditLegalEntity } from '../interfaces/partner-edit-legal-entity.interface';
import { IPartnerEditSalesSettings } from '../interfaces/partner-edit-sales-settings.interface';
import { IPartnerEditWebSettings } from '../interfaces/partner-edit-web-settings.interface';
import { IPartnerListItem } from '../interfaces/partner-list-item.interface';
import { IPartnerListItemBasic } from '../interfaces/partner-list-item-basic.interface';
import { IPartnerOrganizerDefaultSettings } from '../interfaces/partner-organizer-default-settings.interface';
import { IPartnerPollStatusResponse } from '../interfaces/partner-poll-status-response.interface';
import { IPartnerSettingsConfigMailSettings } from '../interfaces/partner-settings-confirm-mail.interface';
import { IPartnerSettingsHolder } from '../interfaces/partner-settings-holder.interface';
import { IPartnerSettingsNTAK } from '../interfaces/partner-settings-ntak.interface';
import { IPartnerView } from '../interfaces/partner-view.interface';
import { ISmartCityPartner } from '../interfaces/smart-city-partner.interface';
import { ALL_PARTNERS_COUNT, CHECKOUT_SURVEY_SETTINGS, EVENT_GUEST_LIST_EXPORT_FIELD, PARTNER_LIST, PARTNER_LIST_BASIC, PARTNER_ORGANIZER_CONFIG, PARTNER_STATUSES, PARTNER_VIEW, REGION_POLL_STATUS } from '../mocks/iam-management-partner.mock';
import { PARTNER_DETAIL_MOCK } from '../mocks/iam-management-partner-detail.mock';
import { PartnerByNameFilter } from '../models/partner-by-name-filter.model';
import { PartnerCreatePayload } from '../models/partner-create-payload.model';
import { PartnerEditor } from '../models/partner-editor.model';
import { PartnerFilter } from '../models/partner-filter.model';
import { PartnerInvite } from '../models/partner-invite.model';
import { PartnerProfileEditor } from '../models/partner-profile-editor.model';
import { PartnerOrganizerDetail } from '../types/partner-organizer-detail.type';

@Injectable({
  providedIn: 'root',
})
export class IamManagementPartnerSourceService {

  private readonly serviceBaseUrlV1: string = '/v1/iam-management/partner';
  private readonly serviceBaseUrlV1WithoutPartner: string = '/v1/iam-management';
  private readonly serviceBaseUrlV2: string = '/v2/iam-management/partner';
  private readonly serviceBaseUrlV3: string = '/v3/iam-management/partner';

  constructor(
    private httpService: HttpService,
  ) { }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  getPartnerOrganizerConfig(partnerId: AAGUID): Observable<PartnerOrganizerDetail> {
    return this.httpService.get<PartnerOrganizerDetail>(`${this.serviceBaseUrlV2}/${partnerId}/config`);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveWebSettings(partnerId: AAGUID, settings: IPartnerOrganizerWebSettings): Observable<PartnerOrganizerDetail> {
    return this.httpService.put((`${this.serviceBaseUrlV2}/${partnerId}/config/web-settings`), settings);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveDefaultSettings(partnerId: AAGUID, settings: IPartnerOrganizerDefaultSettings): Observable<PartnerOrganizerDetail> {
    return this.httpService.put((`${this.serviceBaseUrlV2}/${partnerId}/config/default-settings`), settings);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveDefaultPlaceModules(partnerId: AAGUID, modules: IPlaceModuleConfig[]): Observable<PartnerOrganizerDetail> {
    return this.httpService.put(`${this.serviceBaseUrlV2}/${partnerId}/config/default-settings/place`, modules);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveSalesSettings(partnerId: AAGUID, settings: IPartnerSettingsHolder): Observable<PartnerOrganizerDetail> {
    return this.httpService.put(`${this.serviceBaseUrlV2}/${partnerId}/config/holder-settings`, settings);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveCheckoutSurveySettings(settings: IPartnerOrganizerCheckoutSurveySettings): Observable<PartnerOrganizerDetail> {
    return this.httpService.put(`${this.serviceBaseUrlV1WithoutPartner}/survey/settings`, settings);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  saveConfirmMailSettings(partnerId: AAGUID, settings: IPartnerSettingsConfigMailSettings): Observable<PartnerOrganizerDetail> {
    return this.httpService.put((`${this.serviceBaseUrlV2}/${partnerId}/config/confirmation-mail`), settings);
  }

  @Mock(NTAK_CONFIG)
  getGeneralSettings(): Observable<INTAKConfig> {
    return this.httpService.get<INTAKConfig>(`${this.serviceBaseUrlV1}/ntak/settings`);
  }

  @Mock(CHECKOUT_SURVEY_SETTINGS)
  getCheckoutSurveySettings(): Observable<IPartnerOrganizerCheckoutSurveySettings> {
    return this.httpService.get<IPartnerOrganizerCheckoutSurveySettings>(`${this.serviceBaseUrlV1WithoutPartner}/survey/settings`);
  }

  @Mock()
  deleteGeneralSettings(): Observable<Response> {
    return this.httpService.delete((`${this.serviceBaseUrlV1}/ntak/settings`));
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  createGeneralSettings(settings: IPartnerSettingsNTAK): Observable<PartnerOrganizerDetail> {
    const formData: FormData = new FormData();

    formData.append('regNumber', settings.regNumber);

    if (settings.certFile) {
      formData.append('certFile', settings.certFile);
    }

    if (settings.keyFile) {
      formData.append('keyFile', settings.keyFile);
    }

    return this.httpService.post<PartnerOrganizerDetail>(`${this.serviceBaseUrlV1}/ntak/settings`, formData);
  }

  @Mock(PARTNER_ORGANIZER_CONFIG)
  updateGeneralSettings(settings: IPartnerSettingsNTAK): Observable<PartnerOrganizerDetail> {
    const formData: FormData = new FormData();

    formData.append('regNumber', settings.regNumber);

    if (settings.certFile) {
      formData.append('certFile', settings.certFile);
    }

    if (settings.keyFile) {
      formData.append('keyFile', settings.keyFile);
    }

    return this.httpService.put<PartnerOrganizerDetail>(`${this.serviceBaseUrlV1}/ntak/settings`, formData);
  }

  @Mock(PARTNER_LIST_BASIC)
  getListBasic(): Observable<IPartnerListItemBasic[]> {
    return this.httpService.get<IPartnerListItemBasic[]>(`${this.serviceBaseUrlV1}/login-selector`);
  }

  @Mock(ALL_PARTNERS_COUNT)
  getPartnerCount(): Observable<IResultCount> {
    return this.httpService.get<IResultCount>(`${this.serviceBaseUrlV2}/count`);
  }

  @Mock(PARTNER_LIST)
  getList(searchParams?: SearchParams, filterParams?: PartnerFilter): Observable<IListResult<IPartnerListItem>> {
    let params: HttpParams = searchParams ? searchParams.getHttpParams() : new EncodedHttpParams();
    if (filterParams) {
      params = filterParams.getHttpParams(params);
    }
    return this.httpService.get<IListResult<IPartnerListItem>>(`${this.serviceBaseUrlV2}`, { params });
  }

  @Mock(PARTNER_STATUSES)
  getStatuses(): Observable<IStatus<PartnerStatus>[]> {
    return this.httpService.get<IStatus<PartnerStatus>[]>(`${this.serviceBaseUrlV1}/statuses`);
  }

  @Mock(PARTNER_VIEW)
  getOne(partnerGuid: AAGUID): Observable<IPartnerView> {
    return this.httpService.get<IPartnerView>(`${this.serviceBaseUrlV1}/${partnerGuid}`);
  }

  @Mock(PARTNER_LIST_BASIC?.[0])
  getPartnerBySlug(slug: string): Observable<IPartnerListItemBasic> {
    return this.httpService.get<IPartnerListItemBasic>(`${this.serviceBaseUrlV2}/login-selector/${slug}`);
  }

  @Mock(PARTNER_LIST_BASIC?.[0])
  getPartnerByName(filterParams: PartnerByNameFilter): Observable<IPartnerListItemBasic> {
    const params = filterParams.getHttpParams();
    return this.httpService.get<IPartnerListItemBasic>(`${this.serviceBaseUrlV2}/login-selector`, { params });
  }

  @Mock(REGION_POLL_STATUS)
  getPartnerStatus(sagaId: AAGUID): Observable<IPartnerPollStatusResponse> {
    return this.httpService.get<IPartnerPollStatusResponse>(`${this.serviceBaseUrlV2}/${sagaId}/status`);
  }

  @Mock()
  editPartner(partnerGuid: AAGUID, partnerEditor: PartnerEditor): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/${partnerGuid}/company-details`, partnerEditor);
  }

  @Mock()
  editPartnerDetails(partnerGuid: AAGUID, partnerDetails: IPartnerDetailsEditor): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/${partnerGuid}/details`, partnerDetails);
  }

  @Mock()
  editPartnerPageSettings(partnerGuid: AAGUID, partnerPage: IPartnerCreatorWebConfig): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/${partnerGuid}/web`, partnerPage);
  }

  @Mock(PARTNER_VIEW)
  getProfile(): Observable<IPartnerView> {
    return this.httpService.get<IPartnerView>(`${this.serviceBaseUrlV1}/me`);
  }

  @Mock()
  editProfile(partnerProfile: PartnerProfileEditor): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV1}/me`, partnerProfile);
  }

  @Mock()
  deletePartner(partnerGuid: AAGUID): Observable<Response> {
    return this.httpService.patch<Response>(`${this.serviceBaseUrlV1}/${partnerGuid}`);
  }

  @Mock()
  activate(partnerGuid: AAGUID): Observable<Response> {
    return this.httpService.patch<Response>(`${this.serviceBaseUrlV1}/activate/${partnerGuid}`);
  }

  @Mock()
  inactivate(partnerGuid: AAGUID): Observable<Response> {
    return this.httpService.patch<Response>(`${this.serviceBaseUrlV1}/inactivate/${partnerGuid}`);
  }

  @Mock({ sagaId: 'd3a760df-299b-4e17-8df2-3e149550e2bb' })
  invitePartner(partner: PartnerInvite): Observable<IInitSagaFlowResponse> {
    return this.httpService.post<IInitSagaFlowResponse>(`${this.serviceBaseUrlV2}`, partner);
  }

  @Mock({ sagaId: 'd3a760df-299b-4e17-8df2-3e149550e2bb' })
  createPartner(partner: PartnerCreatePayload): Observable<IInitSagaFlowResponse> {
    return this.httpService.post<IInitSagaFlowResponse>(`${this.serviceBaseUrlV3}`, partner);
  }

  @Mock(PARTNER_CONFIG)
  getConfig(): Observable<IPartnerConfig> {
    return this.httpService.get<IPartnerConfig>(`${this.serviceBaseUrlV3}/me/config`);
  }

  @Mock(PARTNER_WEB_CONFIG)
  getWebConfig(partnerIdToEdit?: AAGUID): Observable<IPartnerWebConfig> {
    let params = new HttpParams;
    if (partnerIdToEdit) {
      params = params.append('partnerIdToEdit', partnerIdToEdit);
    }
    return this.httpService.get<IPartnerWebConfig>(`${this.serviceBaseUrlV2}/me/web-config`, { params });
  }

  @Mock(PARTNER_HIERARCHY_LIST)
  getPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    return this.httpService.get<IPartnerHierarchyListItem[]>(`${this.serviceBaseUrlV2}/partner-hierarchy`);
  }

  @Mock(PARTNER_HIERARCHY_LIST)
  getAllPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    let params = new HttpParams;
    params = params.append('allPartner', true);
    return this.httpService.get<IPartnerHierarchyListItem[]>(`${this.serviceBaseUrlV2}/partner-hierarchy`, { params });
  }

  @Mock(PARTNER_HIERARCHY_LIST)
  getCurrentPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    let params = new HttpParams;
    params = params.append('currentHierarchy', true);
    return this.httpService.get<IPartnerHierarchyListItem[]>(`${this.serviceBaseUrlV2}/partner-hierarchy`, { params });
  }

  @Mock({ sagaId: 'd3a760df-299b-4e17-8df2-3e149550e2bb' })
  createSmartCityPartner(smartCityPartner: ISmartCityPartner): Observable<IInitSagaFlowResponse> {
    return this.httpService.post<IInitSagaFlowResponse>(`${this.serviceBaseUrlV2}/smartcity`, smartCityPartner);
  }

  /**
   * PARTNER EDIT V2
   */

  @Mock(PARTNER_DETAIL_MOCK)
  getPartnerEditDetail(partnerId: AAGUID): Observable<IPartnerEditDetail> {
    return this.httpService.get<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}`);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerEditGeneralData(partnerId: AAGUID, payload: IPartnerEditGeneralData): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/general-data`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  addPartnerEditGeneralDataContact(partnerId: AAGUID, payload: IPartnerEditGeneralDataContact): Observable<IPartnerEditDetail> {
    return this.httpService.post<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/contact`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  updatePartnerEditGeneralDataContact(partnerId: AAGUID, contactId: AAGUID, payload: IPartnerEditGeneralDataContact): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/contact/${contactId}`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  updatePartnerEditGeneralDataContactsOrder(partnerId: AAGUID, payload: IContactIds): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/contacts`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  deletePartnerEditGeneralDataContact(partnerId: AAGUID, contactId: AAGUID): Observable<IPartnerEditDetail> {
    return this.httpService.delete<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/contact/${contactId}`);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerEditGeneralSettings(partnerId: AAGUID, payload: IPartnerEditGeneralSettings): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/general-settings`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerEditWebSettings(partnerId: AAGUID, payload: IPartnerEditWebSettings): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/website-settings`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerSalesSettings(partnerId: AAGUID, payload: IPartnerEditSalesSettings): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/sales-settings`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerLegalEntity(partnerId: AAGUID, payload: IPartnerEditLegalEntity): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/legal-entity`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerFeatureSettings(partnerId: AAGUID, payload: Partial<IPartnerEditFeatureSettings>): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/features`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerInvoicingSettings(partnerId: AAGUID, payload: Partial<IPartnerEditInvoiceSettings>): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/invoice-settings`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  savePartnerDefaultSettings(partnerId: AAGUID, payload: Partial<IPartnerOrganizerDefaultSettings>): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/default-settings`, payload);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  createNTAKSettings(partnerId: AAGUID, settings: FormData): Observable<IPartnerEditDetail> {
    return this.httpService.post<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/ntak-settings`, settings);
  }

  @Mock(PARTNER_DETAIL_MOCK)
  updateNTAKSettings(partnerId: AAGUID, settings: FormData): Observable<IPartnerEditDetail> {
    return this.httpService.put<IPartnerEditDetail>(`${this.serviceBaseUrlV3}/${partnerId}/ntak-settings`, settings);
  }

  @Mock(EVENT_GUEST_LIST_EXPORT_FIELD)
  getGuestListExportFields(eventId: AAGUID): Observable<GuestListExportField[]> {
    const params = new EncodedHttpParams().set('eventId', eventId);
    return this.httpService.get<GuestListExportField[]>(`${this.serviceBaseUrlV1}/field-list/customer`, { params });
  }

  @Mock()
  updateGuestListExportFields(exportFields: GuestListExportField[], eventId: AAGUID): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/field-list/customer`, { customerFields: exportFields, eventId });
  }

}
