import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ImageUploadValidator } from '@interticket/core';
import { IFileUploadConfig } from '@shared/interfaces/image/file-upload-config.interface';

@Component({
  selector: 'upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent {

  @Input() readonly isDisabled = false;
  @Input() readonly id: string = 'file-upload';
  @Input() readonly label: string = 'stemx_file_upload';
  @Input() readonly validatorConfig: IFileUploadConfig;
  @Input() readonly acceptedFileTypes: string[] = [];

  @Output() readonly onClick: EventEmitter<File | string> = new EventEmitter<File | string>();

  @ViewChild('uploadInput', { static: true }) uploadInput: ElementRef;

  readonly type: string = 'file';
  readonly classes: string[] = ['button', 'hollow', 'secondary'];
  readonly icon: string = 'publish';

  onChangeInput(files: FileList): void {
    if (files.length > 0) {
      const file = files.item(0);
      const fileValidator = new ImageUploadValidator(this.validatorConfig);
      const errorLabel = fileValidator.validate(file);

      this.onClick.emit(errorLabel || file);

      this.uploadInput.nativeElement.value = '';
    }
  }

}
