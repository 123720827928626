import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {

  private getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ? error.message : 'no_internet_connection';
  }

  getErrorMessage(error: Error | HttpErrorResponse): string {
    if (error instanceof HttpErrorResponse) {
      return this.getServerErrorMessage(error);
    } else {
      return this.getClientErrorMessage(error);
    }
  }

}
