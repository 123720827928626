import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { accordionAnimation } from '@shared/animation/accordion-extend.animation';
import { UiKitPalette } from '../../../enums/ui-kit-palette.enum';

@Component({
  selector: 'ui-kit-callout',
  templateUrl: './ui-kit-callout.component.html',
  styleUrls: ['./ui-kit-callout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [accordionAnimation],
})
export class UiKitCalloutComponent {

  private state: 'expanded' | 'collapsed' = 'collapsed';

  @Input() readonly title: string;
  @Input({ transform: booleanAttribute }) readonly isCloseable: boolean;
  @Input() readonly description: string | string[];
  @Input({ transform: booleanAttribute }) readonly isCenteredContent = false;

  @Input()
  @HostBinding('class')
  readonly palette: UiKitPalette = UiKitPalette.DEFAULT;

  @HostBinding('@accordionAnimation') get getState(): string {
    return this.state === 'expanded' ? 'collapsed' : 'expanded';
  }

  get icon(): string {
    switch (this.palette) {
      case UiKitPalette.ERROR: return 'alert-circle';
      case UiKitPalette.INFO: return 'info';
      case UiKitPalette.WARNING: return 'alert-triangle';
      case UiKitPalette.SUCCESS: return 'check-circle';
    }
  }

  get descriptions(): string[] {
    if (this.description) {
      return Array.isArray(this.description) ? this.description : [this.description];
    }
  }

  isArray(element: any): boolean {
    return Array.isArray(element);
  }

  onClose(): void {
    this.state = 'expanded';
  }

}
