import { Address } from '@interticket/core';
import { IBillingInformationView } from '../../interfaces/billing/billing-information-view.interface';
import { BankAccount } from '../bank-account/bank-account.model';

export class BillingInformationView implements IBillingInformationView {

  readonly name: string;
  readonly vatNumber: string;
  readonly bankAccount: BankAccount;
  readonly address: Address;

  constructor(iBillingInfoView: IBillingInformationView) {
    this.name = iBillingInfoView.name;
    this.vatNumber = iBillingInfoView.vatNumber;
    this.bankAccount = iBillingInfoView.bankAccount ? new BankAccount(iBillingInfoView.bankAccount) : null;
    this.address = iBillingInfoView.address ? new Address(iBillingInfoView.address) : null;
  }

}
