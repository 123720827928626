import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'reset-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'hollow', 'secondary'];
  @Input() readonly icon: string = 'close';
  @Input() readonly label: string = 'label_form_reset';

}
