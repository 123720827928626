<app-select-group-editor
  [featureList]="featureList"
  [featureIdsControl]="featureIdsControl"
  [disableEdit]="disableEdit"
  [allFeatureShowMode]="showMode"
  [featureColumnLabel]="'label_allowed_countries'"
  [selectedColumnLabel]="'label_prohibited_countries'"
  (isReady)="onFeatureToggled()"
  (featureToggled)="onFeatureToggled()">

  <label><br>
    <app-button
      *ngIf="!disableEdit"
      [label]="isAllSelected ? 'label_allow_all' : 'label_prohibit_all'"
      [classes]="'button primary'"
      [margin]="false"
      (onClick)="onSelectAllToggled()">
    </app-button>
  </label>

</app-select-group-editor>

<div class="nav-bar">
  <cancel-button mat-dialog-close [label]="disableEdit ? 'stemx_general_lang_close' : 'general_lang_cancel'" [icon]="'close'"></cancel-button>
  <save-button *ngIf="!disableEdit" (onClick)="onSave()"></save-button>
</div>
