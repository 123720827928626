import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, environment, IAppConfig, IDynamicAppConfig, StaticAppConfig } from '@env';
import { AppModule } from './app/app.module';

fetch('/assets/app-config/app-config.json')
  .then((response) => response.json())
  .then((dynamicAppConfig: IDynamicAppConfig) => {
    if (environment.enableProdMode) {
      enableProdMode();
    }

    const appConfig: IAppConfig = {
      ...StaticAppConfig,
      ...dynamicAppConfig,
    };

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: appConfig }])
      .bootstrapModule(AppModule)
      .catch(err => console.error('AppInitError', err));
  });
