import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageCode } from '@interticket/core';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';
import { ContentTranslationStoreService } from '../../services/content-translation.service';

@Component({
  selector: 'ui-kit-content-translation-v2',
  templateUrl: './ui-kit-content-translation-v2.component.html',
  styleUrls: ['./ui-kit-content-translation-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitContentTranslationV2Component implements OnChanges {

  private readonly contentTranslationService = inject(ContentTranslationStoreService);

  readonly uiKitPalette = UiKitPalette;
  readonly uiKitSize = UiKitSize;

  readonly languages = this.contentTranslationService.languages;
  readonly $selectedLang = this.contentTranslationService.$selectedLang;
  readonly $indicators = this.contentTranslationService.$indicators;

  @Input() selectedLang: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedLang?.currentValue) {
      this.contentTranslationService.patchState({ selectedLang: changes?.selectedLang?.currentValue });
    }
  }

  changeLanguage(language: LanguageCode): void {
    this.contentTranslationService.patchState({ selectedLang: language });
  }

}
