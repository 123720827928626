import { LocaleFilter } from '@core/models/datetime/locale-filter.model';
import { IExportFieldFilter } from '../interfaces/export-field-filter.interface';

export class ExportFieldFilter<T> extends LocaleFilter implements IExportFieldFilter<T> {

  readonly columns: T[];

  constructor(iFilter: IExportFieldFilter<T>) {
    super(iFilter);
    this.columns = iFilter.columns;
  }

}
