import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-card',
  templateUrl: './ui-kit-card.component.html',
  styleUrls: ['./ui-kit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitCardComponent {

  private readonly sanitizer = inject(DomSanitizer);

  @Input() readonly title: string;
  @Input() readonly subTitle: string;
  @Input() readonly hideHeader: boolean;
  @Input() readonly titleDisc: boolean = true;

  @Input() @HostBinding('class.is-clear') readonly isClear: boolean = true;

  @Input() readonly borderRadius: number = 14;
  @HostBinding('style.--ui-kit-card-border-radius')
  get borderRadiusSize(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`${this.borderRadius}px`);
  }

  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;
  @HostBinding('style')
  get paletteColors(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(this.colors);
  }

  private get colors(): string {
    const palette: UiKitPalette = this.palette || UiKitPalette.PRIMARY;

    switch (palette) {
      case UiKitPalette.PURPLE: return `
        --ui-kit-card-color-disc: var(--purple-color-active);
        --ui-kit-card-color-background: var(--purple-color-hover);
      `;

      case UiKitPalette.ORANGE: return `
        --ui-kit-card-color-disc: var(--orange-color);
        --ui-kit-card-color-background: var(--light-orange);
      `;

      default: return `
        --ui-kit-card-color-disc: var(--${palette}-color);
        --ui-kit-card-color-background: var(--${palette}-color-background);
      `;
    }
  }

}
