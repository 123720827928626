import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';
import { UiKitButtonComponent } from '../ui-kit-button/ui-kit-button.component';

@Component({
  selector: 'ui-kit-button-clear-filters',
  templateUrl: '../ui-kit-button/ui-kit-button.component.html',
  styleUrls: ['../ui-kit-button/ui-kit-button.component.scss', './ui-kit-button-clear-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitButtonClearFiltersComponent extends UiKitButtonComponent {

  @Input() readonly label: string = 'label_clear_all_filters';
  @Input() readonly palette: UiKitPalette = UiKitPalette.SECONDARY;
  @Input() readonly size: UiKitSize = UiKitSize.LARGE;
  @Input() readonly icon = 'x';
  @Input() readonly isFloat = true;

}
