import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { CheckoutStatus } from '@core/enums/checkout/checkout-status.enum';
import { RegionPackageType } from '@services/iam';
import { AdmissionResult } from '@services/report';
import { UiKitPalette } from '@ui-kit/enums';
import { STATUS_ICON_CONFIG_ADMISSION_REPORT, STATUS_ICON_CONFIG_CHECKOUT, STATUS_ICON_CONFIG_FEATURE_PACKAGE } from '../../configs/status-icon.config';
import { StatusIconType } from '../../enums/status-icon-type.enum';
import { IStatusIcon } from '../../interfaces/status-icon-configs.interface';

@Component({
  selector: 'ui-kit-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitStatusIconComponent<T extends string> {

  readonly palette = UiKitPalette;

  statusConfig: IStatusIcon;

  @Input() readonly statusIconType: StatusIconType;
  @Input() readonly size: number = 25;
  @Input() readonly tooltip: string;
  @Input() readonly tooltipPosition: TooltipPosition = 'above';
  @Input() set status(status: T) {
    this.statusConfig = this.getStatusIconConfig(status);
  }

  private getStatusIconConfig(status: T): IStatusIcon {
    switch (this.statusIconType) {
      case StatusIconType.CHECKOUT: return STATUS_ICON_CONFIG_CHECKOUT[status as CheckoutStatus];
      case StatusIconType.FEATURE_PACKAGE: return STATUS_ICON_CONFIG_FEATURE_PACKAGE[status as RegionPackageType];
      case StatusIconType.ADMISSION_REPORT: return STATUS_ICON_CONFIG_ADMISSION_REPORT[status as AdmissionResult];
    }
  }

}
