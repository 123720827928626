import { IPartnerSettingsHolder } from '../interfaces/partner-settings-holder.interface';

export class PartnerSettingsHolder implements IPartnerSettingsHolder {

  readonly assignHolderToTickets: boolean;
  readonly assignHolderToGiftCards: boolean;

  constructor(iConfig: IPartnerSettingsHolder) {
    this.assignHolderToTickets = !!iConfig?.assignHolderToTickets;
    this.assignHolderToGiftCards = !!iConfig?.assignHolderToGiftCards;
  }

}
