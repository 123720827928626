import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from '@core/services/notification/notifier.service';
import { AAGUID, IImageTransform, MediaUrlService, Utils } from '@interticket/core';
import { IFolderedImageList, IFolderedImageUpload } from '@interticket/template-layout-editor-angular';
import { saveAs } from 'file-saver';
import { EMPTY, merge, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MediaImageType } from '../enums/media-image-type.enum';
import { MediaVideoStatus } from '../enums/media-video-status.enum';
import { IFileChunkUploaderRequest } from '../interfaces/file-chunk-uploader-request.interface';
import { IMediaType } from '../interfaces/media-type.interface';
import { IMediaUploadResponse } from '../interfaces/media-upload-response.interface';
import { IMediaVideoStatus } from '../interfaces/media-video-status.interface';
import { MediaServiceVersion } from '../types/media-service-version.type';
import { MediaSourceService } from './media-source.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {

  constructor(
    private mediaSourceService: MediaSourceService,
    private notifier: NotifierService,
    private mediaUrlService: MediaUrlService
  ) { }

  getImageUrl(guid?: AAGUID, transform?: IImageTransform): string {
    return this.mediaSourceService.getImageUrl(guid, transform);
  }

  getVideoUrl(guid?: AAGUID): string {
    return this.mediaUrlService.getVideoUrl(guid);
  }

  getLegacyImageUrl(guid?: AAGUID): string {
    return this.mediaSourceService.getLegacyImageUrl(guid);
  }

  uploadImage(file: File | Blob): Observable<AAGUID> {
    return this.mediaSourceService.uploadImage(file)
      .pipe(
        map(({ imageId }) => imageId)
      );
  }

  uploadMedia(file: File): Observable<IMediaType> {
    return this.mediaSourceService.uploadMedia(file);
  }

  getMedia(fileId: AAGUID): Observable<HttpResponse<Blob>> {
    return this.mediaSourceService.getMedia(fileId);
  }

  uploadImageWithProgress(file: File): Observable<HttpEvent<IMediaUploadResponse>> {
    return this.mediaSourceService.uploadImageWithProgress(file);
  }

  uploadImageWithType(file: File, type: MediaImageType): Observable<AAGUID> {
    return this.mediaSourceService.uploadImageWithType(file, type)
      .pipe(
        map(({ imageDataGuid }) => imageDataGuid),
      );
  }

  uploadFolderedImage(folder: string, file: File): Observable<IFolderedImageUpload> {
    return this.mediaSourceService.uploadFolderedImage(folder, file);
  }

  deleteImage(guid: AAGUID, version: MediaServiceVersion = 'v2'): Observable<Response> {
    return this.mediaSourceService.deleteImage(guid, version);
  }

  deleteImages(ids: AAGUID[], version: MediaServiceVersion = 'v2'): Observable<Response> {
    return merge(...ids.map((guid: AAGUID) => this.deleteImage(guid, version).pipe(
      catchError(() => EMPTY),
    )));
  }

  deleteMedia(guid: AAGUID, version: MediaServiceVersion = 'v3'): Observable<Response> {
    return this.mediaSourceService.deleteMedia(guid, version);
  }

  downloadImage(id: AAGUID, version: MediaServiceVersion = 'v2'): Observable<HttpResponse<Blob>> {
    return this.mediaSourceService.downloadImage(id, version);
  }

  downloadSurveyImage(id: AAGUID, version: MediaServiceVersion = 'v4'): Observable<HttpResponse<Blob>> {
    return this.mediaSourceService.downloadSurveyImage(id, version);
  }

  uploadVideo(chunk: Blob, chunkDatas: IFileChunkUploaderRequest, uploadUrl: string): Observable<HttpEvent<Response>> {
    return this.mediaSourceService.uploadVideo(chunk, chunkDatas, uploadUrl);
  }

  getVideoStatus(programOrEventId: AAGUID, version: MediaServiceVersion = 'v3'): Observable<IMediaVideoStatus> {
    return this.mediaSourceService.getVideoStatus(programOrEventId, version);
  }

  pollVideoStatus(programOrEventId: AAGUID, version: MediaServiceVersion = 'v3'): Observable<IMediaVideoStatus> {
    return Utils.pollTakeWhile(
      () => this.getVideoStatus(programOrEventId, version),
      (videoStatus: IMediaVideoStatus) => videoStatus.status !== MediaVideoStatus.UPLOADED && videoStatus.status !== MediaVideoStatus.NONE
    );
  }

  deleteVideo(eventId: AAGUID, version: MediaServiceVersion = 'v3'): Observable<Response> {
    return this.mediaSourceService.deleteVideo(eventId, version);
  }

  getStreamKey(eventId: AAGUID): Observable<string> {
    return this.mediaSourceService.getStreamKey(eventId)
      .pipe(
        map(({ streamKey }) => streamKey)
      );
  }

  getVideoUploadUrl(programOrEventId: AAGUID, fileName: string, version: MediaServiceVersion = 'v3'): Observable<string> {
    return this.mediaSourceService.getUploadVideoUrl(programOrEventId, fileName, version)
      .pipe(
        map(({ url }) => url)
      );
  }

  getFolderedImageList(folder: string): Observable<IFolderedImageList> {
    return this.mediaSourceService.getFolderedImageList(folder);
  }

  fetchImages(imageIds: AAGUID[]): void {
    imageIds.forEach((imageId: AAGUID) => {
      this.fetchImage(imageId);
    });
  }

  fetchImage(imageId: AAGUID): void {
    this.downloadImage(imageId).subscribe({
      next: (data: HttpResponse<Blob>) => {
        const ext = data.body.type.split(/image\//)[1];
        saveAs(data.body, `${imageId}.${ext}`);
      },
      error: (error: any) => {
        this.notifier.errorFetch(error);
      },
    });
  }

}
