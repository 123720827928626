import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonIconPosition } from '@shared/enums/button/button-icon-position.enum';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'next-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'primary'];
  @Input() readonly icon: string = 'arrow_forward_ios';
  @Input() readonly iconPosition: ButtonIconPosition = ButtonIconPosition.AFTER;
  @Input() readonly label: string = 'general_lang_continue';

}
