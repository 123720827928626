import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { AuthTokenStoreService } from '@core/services/store/auth-token-store.service';

@Injectable()
export class AuthGuard {

  constructor(
    private router: Router,
    private authTokenStore: AuthTokenStoreService,
  ) { }

  private isAuthenticated(returnUrl?: string): boolean {
    if (!this.authTokenStore.isAuthenticated) {
      this.router.navigate(['auth'], { queryParams: { returnUrl } });
    }

    return this.authTokenStore.isAuthenticated;
  }

  canActivate(): boolean {
    return this.isAuthenticated();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const returnUrl: string = '/' + segments.map((segment: UrlSegment) => segment.path).join('/');
    return this.isAuthenticated(returnUrl);
  }

}
