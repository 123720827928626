import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';

@Component({
  selector: 'chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
})
export class ChipInputComponent {

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE, TAB] as const;

  readonly isLoading$ = new Subject<boolean>();
  readonly showError$ = new Subject<boolean>();

  @Input() label: string;
  @Input() placeholder: string;
  @Input() itemIdsControl: FormControl;

  @ViewChild('matChipInput') matChipInput: ElementRef<HTMLInputElement>;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.itemIdsControl.value.push(value);
    }

    this.matChipInput.nativeElement.value = '';
  }

  remove(item: string): void {
    const index = this.itemIdsControl.value.findIndex((arrayItem) => arrayItem === item);

    if (index >= 0) {
      this.itemIdsControl.value.splice(index, 1);
    }
  }

}
