import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IHttpEvent } from '@core/interfaces/error-handler/http-event.interface';
import { ISlackMessage } from '@core/interfaces/error-handler/slack-message.interface';
import { IStackTraceReport } from '@core/interfaces/error-handler/stack-trace-report.interface';
import { APP_CONFIG, environment, IAppConfig } from '@env';
import { HttpService } from '@interticket/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class ErrorReportService {

  private readonly stackTraceReports$: BehaviorSubject<IStackTraceReport[]> = new BehaviorSubject([]);
  private readonly httpEvents$: BehaviorSubject<IHttpEvent[]> = new BehaviorSubject([]);
  private readonly maxStackQueueSize = 3;
  private readonly maxSlackQueueSize = 11;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private httpService: HttpService
  ) { }

  private getReportMessage(message: string, image: string): ISlackMessage[] {
    const slackMessages: ISlackMessage[] = [{
      pretext: `StemX admin - ${this.appConfig.env.toUpperCase()}:v${environment.version}`,
      title: message,
      color: 'danger',
      author_name: window.location.href,
      author_link: window.location.href,
      image_url: image,
    }];

    // HttpEvents
    const httpEvents: IHttpEvent[] = this.httpEvents$.getValue();
    if (httpEvents.length) {
      httpEvents.forEach((event: IHttpEvent, idx: number) => {
        // Skip report image http event from report if success
        if (idx < httpEvents.length - 1 || event.response.status >= 300) {
          slackMessages.push({
            title: `${event.response.status} - ${event.request.method} ${event.request.urlWithParams}`,
            text: `CorrelationId: ${event.response.headers.get('x-correlation-id')}\nDate: ${event.response.headers.get('date')}`,
            color: this.getStatusColor(event.response.status),
          });
        }
      });
    }

    // StackTraces
    if (this.stackTraceReports$.getValue().length) {
      slackMessages.push({
        title: 'Console Errors',
        color: 'danger',
        fields: this.stackTraceReports$.getValue().map((report: IStackTraceReport) => ({
          title: report.message,
          value: report.stackTrace,
        })),
      });
    }

    return slackMessages;
  }

  private getStatusColor(status: number): 'danger' | 'warning' | 'good' {
    if (status < 300) {
      return 'good';
    } else if (status < 400) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  private pushToQueue(queue: any[], element: any, queueSize: number): any[] {
    if (queue.length >= queueSize) {
      queue.shift();
    }
    queue.push(element);

    return queue;
  }

  pushStackTrace(stackTraceReport: IStackTraceReport): void {
    this.stackTraceReports$.next(this.pushToQueue(this.stackTraceReports$.getValue(), stackTraceReport, this.maxStackQueueSize));
  }

  pushHttpEvent(httpEvent: IHttpEvent): void {
    this.httpEvents$.next(this.pushToQueue(this.httpEvents$.getValue(), httpEvent, this.maxSlackQueueSize));
  }

  reportError(message: string, image: string): Observable<Response> {
    const slackMessage: ISlackMessage[] = this.getReportMessage(message, image);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      responseType: 'text',
    };

    return this.httpService.post<Response>(this.appConfig.reportWebhookUrl, { attachments: slackMessage }, options, { static: true })
      .pipe(take(1));
  }

}
