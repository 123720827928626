import { AAGUID, FilterParams } from '@interticket/core';
import { IUserPartnerRoleFilter } from '../interfaces/user-partner-role-filter.interface';

export class UserPartnerRoleFilter extends FilterParams implements IUserPartnerRoleFilter {

  readonly customRoleId: AAGUID;
  readonly systemRoleId: AAGUID;

  constructor(iFilter: IUserPartnerRoleFilter) {
    super();
    this.customRoleId = iFilter.customRoleId;
    this.systemRoleId = iFilter.systemRoleId ?? null;
  }

}
