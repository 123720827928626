import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'card-reason-modal',
  templateUrl: './card-reason-modal.component.html',
  styleUrls: ['./card-reason-modal.component.scss'],
})
export class CardReasonModalComponent implements OnInit {

  modalForm: UntypedFormGroup;
  reason: AbstractControl;
  status: AbstractControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.modalForm = this.createModalForm();
    this.reason = this.modalForm.get('reason');
    if (this.data.revokeStatuses) {
      this.status = this.modalForm.get('status');
    }
  }

  private createModalForm(): UntypedFormGroup {
    const form = this.formBuilder.group({
      reason: [null, Validators.required],
    });
    if (this.data.revokeStatuses) {
      form.addControl('status', new UntypedFormControl('', Validators.required));
    }
    return form;
  }

}
