import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'booking-chooser-dialog',
  templateUrl: './booking-chooser-dialog.component.html',
  styleUrls: ['./booking-chooser-dialog.component.scss'],
})
export class BookingChooserDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({ createType: ['booking', Validators.required] });
  }

}
