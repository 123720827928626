export const Chair = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="chair">
    <path id="Vector 1 (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
      d="M3.57039 2.9627C3.38901 1.75349 4.32553 0.666016 5.54826 0.666016H10.4518C11.6745 0.666016 12.611 1.75349 12.4296 2.9627L11.6593 8.09824L10.3407 7.90046L11.1111 2.76491C11.1715 2.36184 10.8593 1.99935 10.4518 1.99935H5.54826C5.14068 1.99935 4.82851 2.36184 4.88897 2.76491L5.6593 7.90046L4.34072 8.09824L3.57039 2.9627Z"
      fill="currentColor" />
    <path id="Vector 2 (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
      d="M2.6665 10.0007V9.33398C2.6665 8.22942 3.56193 7.33398 4.6665 7.33398H11.3332C12.4377 7.33398 13.3332 8.22941 13.3332 9.33398V10.0007C13.3332 11.1052 12.4377 12.0007 11.3332 12.0007H4.6665C3.56193 12.0007 2.6665 11.1052 2.6665 10.0007ZM3.99984 9.33398V10.0007C3.99984 10.3688 4.29831 10.6673 4.6665 10.6673H11.3332C11.7014 10.6673 11.9998 10.3688 11.9998 10.0007V9.33398C11.9998 8.96579 11.7014 8.66732 11.3332 8.66732H4.6665C4.29831 8.66732 3.99984 8.96579 3.99984 9.33398Z"
      fill="currentColor" />
    <path id="Vector 9 (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
      d="M3.34388 14.8815L4.01054 11.2148L5.32237 11.4534L4.6557 15.12C4.58984 15.4823 4.24278 15.7225 3.88053 15.6567C3.51828 15.5908 3.27801 15.2438 3.34388 14.8815Z"
      fill="currentColor" />
    <path id="Vector 10 (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
      d="M12.6561 14.8815L11.9895 11.2148L10.6776 11.4534L11.3443 15.12C11.4102 15.4823 11.7572 15.7225 12.1195 15.6567C12.4817 15.5908 12.722 15.2438 12.6561 14.8815Z"
      fill="currentColor" />
  </g>
</svg>`;
