import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'checkbox-search-bar',
  templateUrl: './checkbox-search-bar.component.html',
  styleUrls: ['./checkbox-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxSearchBarComponent {

  @Input() searchFormControl: UntypedFormControl;
  @Input() checkBoxFormControl: UntypedFormControl;
  @Input() searchBarPlaceholder: string;
  @Input() checkboxLabel: string;

  toggleCheckBox(checked: boolean): void {
    this.checkBoxFormControl.patchValue(checked);
  }

}
