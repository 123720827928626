export enum WalletPermission {
  LIST = 'smartcity.coupon.module.list.coupons',
  CREATE = 'smartcity.coupon.module.request.new.coupon',
  DELETE = 'smartcity.coupon.module.delete.coupon',
  DETAILS = 'smartcity.coupon.module.edit.coupon',
  STATISTICS = 'smartcity.coupon.module.coupon.excel.statistics.download',
  TYPES = 'smartcity.coupon.module.coupon.types.access',
  STATUSES = 'smartcity.coupon.module.view.statuses.of.requestable.coupons',
  CARD_SEARCH = 'smartcity.coupon.module.check.partner.card.in.case.of.coupon.request',
  PDF = 'smartcity.coupon.module.pdf.download',
  UPDATE_STATUS = 'smartcity.coupon.module.deny.or.accept.of.a.requested.coupon'
}
