export const Auditorium = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1413_342)">
  <path d="M11.25 16.25H1.875V1.875H28.125V16.25H18.75" stroke="currentColor" stroke-width="2"
    stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M10.5 28.5H1.5V25.5C1.5 23.0147 3.51472 21 6 21C8.48528 21 10.5 23.0147 10.5 25.5V28.5Z"
    stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  <path
    d="M19.5 28.5H10.5V25.5C10.5 23.0147 12.5147 21 15 21C17.4853 21 19.5 23.0147 19.5 25.5V28.5Z"
    stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  <path
    d="M28.5 28.5H19.5V25.5C19.5 23.0147 21.5147 21 24 21C26.4853 21 28.5 23.0147 28.5 25.5V28.5Z"
    stroke="currentColor" stroke-width="2" stroke-linecap="round" />
</g>
<defs>
  <clipPath id="clip0_1413_342">
    <rect width="30" height="30" fill="white" />
  </clipPath>
</defs>
</svg>`;
