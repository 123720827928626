import { UiKitNotificationComponent } from '@ui-kit/elements';
import { UiKitPalette } from '@ui-kit/enums';
import { GlobalConfig } from 'ngx-toastr';

/**
 * {@link} https://www.npmjs.com/package/ngx-toastr#global-options
 */
export const toastrGlobalConfig: Partial<GlobalConfig> = {
  maxOpened: 4,
  autoDismiss: true,
  easeTime: 180,
  onActivateTick: true,
  preventDuplicates: true,
  includeTitleDuplicates: true,
  countDuplicates: true,
  resetTimeoutOnDuplicate: true,
  toastComponent: UiKitNotificationComponent,
  iconClasses: {
    error: UiKitPalette.ERROR,
    info: UiKitPalette.INFO,
    success: UiKitPalette.SUCCESS,
    warning: UiKitPalette.WARNING,
  },
};
