import { AAGUID, IPartnerWebConfigTheme, ISODateTime, PartnerWebConfigTheme, PublicPageTemplate } from '@interticket/core';
import { IPartnerViewWebConfig } from '../interfaces/partner-view-web-config.interface';

export class PartnerViewWebConfig implements IPartnerViewWebConfig {

  readonly externalSystemId: number;
  readonly partnerPageActivationAt: ISODateTime;
  readonly partnerPageDomain: string;
  readonly partnerPageEnabled: boolean;
  readonly partnerPageThemeId: AAGUID;
  readonly regionPageActivationAt: ISODateTime;
  readonly regionPageEnabled: boolean;
  readonly regionPageImageId: AAGUID;
  readonly regionSubPageEnabled?: boolean;
  readonly regionSubPageSlug?: string;
  readonly theme: IPartnerWebConfigTheme;
  readonly partnerPageTemplate: PublicPageTemplate;

  constructor(iPartnerViewWebConfig: IPartnerViewWebConfig) {
    this.externalSystemId = iPartnerViewWebConfig.externalSystemId;
    this.partnerPageActivationAt = iPartnerViewWebConfig.partnerPageActivationAt;
    this.partnerPageDomain = iPartnerViewWebConfig.partnerPageDomain;
    this.partnerPageEnabled = iPartnerViewWebConfig.partnerPageEnabled;
    this.partnerPageThemeId = iPartnerViewWebConfig.partnerPageThemeId;
    this.regionPageActivationAt = iPartnerViewWebConfig.regionPageActivationAt;
    this.regionPageEnabled = iPartnerViewWebConfig.regionPageEnabled;
    this.regionPageImageId = iPartnerViewWebConfig.regionPageImageId;
    this.regionSubPageEnabled = !!iPartnerViewWebConfig.regionSubPageEnabled;
    this.regionSubPageSlug = iPartnerViewWebConfig.regionSubPageSlug || null;
    this.theme = iPartnerViewWebConfig.theme ? new PartnerWebConfigTheme(iPartnerViewWebConfig.theme) : null;
    this.partnerPageTemplate = iPartnerViewWebConfig.partnerPageTemplate;
  }

}
