<div class="main">

  <!-- TITLE -->
  <div class="row align-spaced">
    <div class="columns text-center">
      <h2 mat-dialog-title *ngIf="!data.event">{{ 'booking_calendar_new_work_break_title' | translate }}</h2>
      <h2 mat-dialog-title *ngIf="data.event">{{ 'booking_calendar_edit_work_break_title' | translate }}</h2>
    </div>
  </div>

  <form [formGroup]="form">

    <!-- NAME -->
    <div class="row">
      <div class="columns box-white small-12">
        <label>{{ 'booking_calendar_work_brake_name_label' | translate }}
          <input type="text" appFormControl formControlName="name" placeholder="{{ 'booking_calendar_work_brake_name_label' | translate }}" autocomplete="off">
        </label>
      </div>
    </div>

    <!-- RESOURCES -->
    <div class="columns small-12">
      <label>{{ 'booking_calendar_work_brake_resources_label' | translate }}
        <mat-select
          [formControl]="resourcesFormControl"
          class="custom-select"
          [placeholder]="'booking_calendar_work_brake_resources_label' | translate"
          multiple>
          <mat-option
            #matOption
            [value]="resource.id"
            *ngFor="let resource of data.resourceList"
            (click)="onChangeResourceCheckbox(matOption.value, matOption.selected)">
            {{ resource.name }}
          </mat-option>
        </mat-select>
      </label>
    </div>

    <!-- START -->
    <div class="row">
      <div class="columns small-12">
        <label>{{ 'booking_calendar_work_brake_start_label' | translate }}
          <div class="input-group">
            <input type="text" class="input-group-field" appFormControl formControlName="start" placeholder="{{ 'booking_calendar_work_brake_start_label' | translate }}" autocomplete="off"
              [owlDateTimeTrigger]="dateTimePickerStart" [owlDateTime]="dateTimePickerStart" [selectMode]="'single'">
            <owl-date-time #dateTimePickerStart [startView]="'month'" [firstDayOfWeek]="1"></owl-date-time>
            <div class="input-group-button">
              <button type="button" class="button" [owlDateTimeTrigger]="dateTimePickerStart">
                <i class="material-icons">calendar_today</i>
              </button>
            </div>
          </div>
        </label>
      </div>
    </div>

    <!-- END -->
    <div class="row">
      <div class="columns small-12">
        <label>{{ 'booking_calendar_work_brake_end_label' | translate }}
          <div class="input-group">
            <input type="text" class="input-group-field" appFormControl formControlName="end" placeholder="{{ 'booking_calendar_work_brake_end_label' | translate }}" autocomplete="off"
              [owlDateTimeTrigger]="dateTimePickerEnd" [owlDateTime]="dateTimePickerEnd" [selectMode]="'single'">
            <owl-date-time #dateTimePickerEnd [startView]="'month'" [firstDayOfWeek]="1"></owl-date-time>
            <div class="input-group-button">
              <button type="button" class="button" [owlDateTimeTrigger]="dateTimePickerEnd">
                <i class="material-icons">calendar_today</i>
              </button>
            </div>
          </div>
        </label>
      </div>
    </div>

    <!-- ISGENERAL -->
    <div class="row">
      <div class="columns small-12">
        <mat-checkbox appFormControl formControlName="isGeneral" (change)="onChangeIsGeneral($event)" [checked]="form.value.isGeneral">{{ 'booking_calendar_work_brake_general_label' | translate }}</mat-checkbox>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="row align-center margin-top-2">

      <!-- DELETE -->
      <span *ngxPermissionsOnly="[calendarPermission.CALENDAR_DELETE_WORKBREAK]">
        <delete-button
          *ngIf="data.event"
          [label]="'delete_work_break_button'"
          (onClick)="onClickDelete()"
          [classes]="['button', 'alert']">
        </delete-button>
      </span>

      <!-- CANCEL -->
      <button
        class="button secondary margin-right-1 margin-left-1"
        [mat-dialog-close]="{ confirmed: false, formData: null }">
        {{ 'label_cancel' | translate }}
      </button>

      <!-- SUBMIT FOR CREATE -->
      <button
        class="button"
        [mat-dialog-close]="{ confirmed: true, formData: form.value }"
        [disabled]="form.status === 'INVALID' || form.value.start >= form.value.end"
        *ngIf="!data.event">
        {{ 'label_create' | translate }}
      </button>

      <!-- SUBMIT FOR EDIT -->
      <button
        class="button"
        [mat-dialog-close]="{ confirmed: true, formData: form.value }"
        [disabled]="form.status === 'INVALID' || form.value.start >= form.value.end"
        *ngIf="data.event">
        {{ 'label_edit' | translate }}
      </button>

    </div>
  </form>
</div>
