import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';
import { UiKitChipActiveDirective } from '../../directives/ui-kit-chip-active.directive';

export type UiKitChipOptionVariant = 'filled' | 'outline';

@Component({
  selector: 'ui-kit-chip-option',
  templateUrl: './ui-kit-chip-option.component.html',
  styleUrls: [
    './ui-kit-chip-option.component.scss',
    './variants/ui-kit-chip-option-outline.variant.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ui-kit-chip-option' },
  hostDirectives: [UiKitChipActiveDirective],
})
export class UiKitChipOptionComponent {

  @Input() readonly isRemovable: boolean = false;
  @Input() readonly isDisabled: boolean = false;
  @Input() readonly isRippleDisabled: boolean = false;
  @Input() readonly isActiveDisabled: boolean = false;
  @Input() readonly isHoverDisabled: boolean = false;
  @Input() readonly value: string | number;
  @Input() readonly id: string | number;
  @Input() readonly removeIcon: string = 'x';
  @Input() readonly activePalette: UiKitPalette = UiKitPalette.PRIMARY;
  @Input() readonly palette: UiKitPalette = UiKitPalette.SECONDARY;
  @Input() readonly variant: UiKitChipOptionVariant = 'filled';

  @Output() readonly remove = new EventEmitter<string | number>();

  @HostBinding('style')
  get paletteColors(): SafeStyle {
    const activePalette: UiKitPalette = this.activePalette || UiKitPalette.PRIMARY;
    const palette: UiKitPalette = this.palette || UiKitPalette.SECONDARY;

    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-chip-active-bg: var(--${activePalette}-color);
      --ui-kit-chip-bg: var(--${palette}-color);
      --ui-kit-chip-text: var(--${palette}-color-contrast);
      --ui-kit-chip-outlined-text: var(--${palette}-text-color);
      --ui-kit-chip-overlay-color: var(--${palette}-color-contrast, black);
    `);
  }

  @HostBinding('class')
  get variantType(): string {
    const variant = this.variant || 'filled';
    return `ui-kit-chip-option-${variant}`;
  }

  constructor(
    private readonly sanitizer: DomSanitizer,
  ) { }

  onRemoved(): void {
    this.remove.emit(this.id || this.value);
  }

}
