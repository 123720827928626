<div class="tabbed-navbar-container" *ngIf="visibleItems.length > 1">
  <ng-container *ngFor="let item of visibleItems">
    <ui-kit-button
      [label]="item.label"
      [icon]="item.icon"
      [palette]="item.palette || palette.PRIMARY"
      [size]="item.size || buttonSizes.LARGE"
      [routerLinkActive]="'active'"
      [class.not-primary]="item.palette && item.palette !== palette.PRIMARY"
      [routerLink]="[item.routerLink]">
    </ui-kit-button>
  </ng-container>
</div>
