<mat-select [value]="selectedIcon?.name || icons[0].name" (valueChange)="changeIcon($event)" class="icon-picker-select custom-select">
  <mat-select-trigger>
    <img src="assets/svgs/{{ selectedIcon?.name }}.svg" class="it-icon-img size-20" alt="{{ selectedIcon.label | translate }}">
    <span *ngIf="false">
      {{ selectedIcon.label | translate }}
    </span>
  </mat-select-trigger>

  <mat-option *ngFor="let icon of icons" [value]="icon.name">
    <img src="assets/svgs/{{ icon.name }}.svg" class="it-icon-img size-20" alt="{{ icon.label | translate }}">
    <span *ngIf="false">
      {{ icon.label | translate }}
    </span>
  </mat-option>
</mat-select>
