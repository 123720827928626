import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Callout } from '@shared/models/callout/callout.model';

@Component({
  selector: 'app-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent {

  @Input() callout: Callout;

  /** Fired when callout closed */
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  closeCallout(): void {
    // Waiting for animated callout close is end
    setTimeout(() => {
      this.callout = null;
      this.onClose.emit(true);
    }, 500);
  }

  isArray(element: any): boolean {
    return Array.isArray(element);
  }

}
