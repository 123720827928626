<ul class="breadcrumb" *ngIf="(breadcrumbs$ | async)?.length > 1">
  <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs$ | async; index as idx">

    <!-- ROOT -->
    <ng-container *ngIf="idx === 0 else breadcrumbItem">
      <a [routerLink]="'/'">
        <ui-kit-icon [icon]="'home'" [size]="20"></ui-kit-icon>
      </a>
    </ng-container>

    <!-- BREADCRUMB ITEM -->
    <ng-template #breadcrumbItem>
      <ui-kit-icon [icon]="'chevron-right'" [size]="20"></ui-kit-icon>
      <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.label | translate }}</a>
    </ng-template>

  </li>
</ul>
