import { NotificationType } from '@core/enums/notifier/notification-type.enum';
import { IAuthLogoutRedirectParams } from '@core/interfaces/auth/auth-logout-redirect-params.interface';

export class AuthLogoutRedirectParams implements IAuthLogoutRedirectParams {

  readonly messageType: NotificationType;
  readonly message: string;

  constructor(params: IAuthLogoutRedirectParams) {
    this.messageType = params.messageType;
    this.message = params.message;
  }

}
