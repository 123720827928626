import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthUserStoreService } from '@core/services/store/auth-user-store.service';
import { LanguageCode, TranslateService } from '@interticket/core';
import { ContentTranslationPermission } from '@services/permissions';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-content-translation',
  templateUrl: './ui-kit-content-translation.component.html',
  styleUrls: ['./ui-kit-content-translation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitContentTranslationComponent implements OnInit {

  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;
  @Input() readonly label: string = 'label_content_translation';
  @Input() set selected(language: LanguageCode) {
    this.selectedLanguage = language;
  }

  @Output() selectedItemChange = new EventEmitter<LanguageCode>();

  readonly contentTranslationPermission = ContentTranslationPermission;

  selectedLanguage: LanguageCode;
  badgeClasses: string[] = [];
  languages: string[];

  constructor(
    private translate: TranslateService,
    private authUserStore: AuthUserStoreService,
  ) {
    this.selectedLanguage = this.authUserStore.defaultLanguageCode;
  }

  ngOnInit(): void {
    this.languages = this.translate.getLanguages();
    this.setPanelClasses();
  }

  isSelected(language: LanguageCode): boolean {
    return language === this.selectedLanguage;
  }

  onClick(language: LanguageCode): void {
    this.selectedLanguage = language;
    this.selectedItemChange.emit(language);
  }

  private setPanelClasses(): void {
    if (this.palette) {
      this.badgeClasses = [...this.badgeClasses, `ui-kit-palette-${this.palette}`];
    }
  }

}
