import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export interface IAccordionAnimationParams {
  enterDelay: number;
  leaveDelay: number;
}

export const accordionAnimation: AnimationTriggerMetadata =
  trigger('accordionAnimation', [
    transition(':enter', [
      style({ height: '0px', minHeight: '0' }),
      animate('225ms {{enterDelay}}ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*' })),
    ], { params: { enterDelay: 0 } }),
    transition(':leave', [
      animate('225ms {{leaveDelay}}ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '0px', minHeight: '0' })),
    ], { params: { leaveDelay: 0 } }),
  ]);

export const accordionExtendAnimation: AnimationTriggerMetadata =
  trigger('accordionExtendAnimation', [
    transition(':enter', [
      style({ height: '0px', minHeight: '0px' }),
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*' })),
    ]),
    transition(':leave', [
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '0px', minHeight: '0px', display: 'none' })),
    ]),
  ]);
