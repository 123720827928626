<ng-container *ngIf="callout">
  <div class="{{ callout.type }} callout" data-closable>
    <h2 *ngIf="callout.title">{{ callout.title | translate }}</h2>

    <ng-container *ngxPermissionsOnly="callout?.permissions?.description">
      <ng-container *ngIf="callout.description">
        <ng-container *ngIf="isArray(callout.description) else description">
          <p *ngFor="let description of callout.description">{{ description | translate }}</p>
        </ng-container>
        <ng-template #description>
          <p>{{ callout.description | translate }}</p>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngxPermissionsOnly="callout?.permissions?.button">
      <div class="row" *ngIf="callout.button">
        <div class="column small-12 flex-end">
          <ng-content></ng-content>
          <app-button 
            [type]="callout.button.type" 
            [classes]="callout.button.class" 
            [label]="callout.button.label" 
            [icon]="callout.button.icon" 
            [isLoading]="callout.button.isLoading" 
            [isDisabled]="callout.button.isLoading" 
            (onClick)="callout.button.onClick()">
          </app-button>
        </div>
      </div>
    </ng-container>
    <button type="button" *ngIf="callout.isCloseable" class="close-button" aria-label="Dismiss alert" (click)="closeCallout()">
      <i class="material-icons">close</i>
    </button>
  </div>
</ng-container>
