import { animate, AnimationTriggerMetadata, keyframes, state, style, transition, trigger } from '@angular/animations';

export const flyInOutAnimation: AnimationTriggerMetadata =
  trigger('flyInOut', [
    state('inactive', style({ opacity: 0 })),
    transition('inactive => active',
      animate('300ms ease-out',
        keyframes([
          style({ opacity: 0, transform: 'translateY(-25%)' }),
          style({ opacity: 1, transform: 'translateY(0)' }),
        ]),
      ),
    ),
    transition('active => removed',
      animate('200ms ease-out',
        keyframes([
          style({ opacity: 1, transform: 'translateX(0)' }),
          style({ opacity: 0, transform: 'translateX(25%)' }),
        ]),
      ),
    ),
  ]);
