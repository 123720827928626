import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'draft-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftButtonComponent extends ButtonComponent {

  @Input() readonly isDisabled = false;
  @Input() readonly isLoading = false;
  @Input() readonly classes: string | string[] = ['button', 'ok'];
  @Input() readonly icon: string = 'drafts';
  @Input() readonly label: string = 'save_as_draft';
  @Input() readonly type: 'button';

}
