import { Injectable } from '@angular/core';
import { AAGUID, HttpService, IListResult, ListResult, Mock } from '@interticket/core';
import { IType } from '@shared/interfaces/type/type.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS_MOCK } from 'test/mock/permissions-mock';
import { RegionPackageType } from '../enums/region-package-type.enum';
import { IAdminPartner } from '../interfaces/admin-partner.interface';
import { IRegionPackage } from '../interfaces/region-package.interface';
import { IUserIdList } from '../interfaces/user-id-list.interface';
import { IUserPartnerAssociation } from '../interfaces/user-partner-association.interface';
import { IUserPartnerEditor } from '../interfaces/user-partner-editor.interface';
import { ADMIN_PARTNERS, PARTNER_ROLES_FOR_USER, REGION_PACKAGE, REGION_PACKAGE_LIST, REGION_PACKAGE_TYPES, USER_ID_LIST } from '../mocks/iam.mock';
import { RegionPackage } from '../models/region-package.model';
import { RegionPackageCreator } from '../models/region-package-creator.model';
import { RegionPackageEditor } from '../models/region-package-editor.model';
import { UserPartnerRoleFilter } from '../models/user-partner-role-filter.model';

@Injectable({
  providedIn: 'root',
})
export class IamSourceService {

  private readonly serviceBaseUrlV1: string = `/v1/iam`;
  private readonly serviceBaseUrlV2: string = `/v2/iam`;

  constructor(
    private httpService: HttpService
  ) { }

  @Mock(REGION_PACKAGE_TYPES)
  getPackageTypes(): Observable<IType<RegionPackageType>[]> {
    return this.httpService.get<IType<RegionPackageType>[]>(`${this.serviceBaseUrlV2}/package/types`);
  }

  @Mock(REGION_PACKAGE_TYPES)
  getRegionPackageTypes(): Observable<IType<RegionPackageType>[]> {
    return this.httpService.get<IType<RegionPackageType>[]>(`${this.serviceBaseUrlV2}/region-package/types`);
  }

  @Mock(REGION_PACKAGE_LIST?.results)
  getRegionPackages(): Observable<RegionPackage[]> {
    return this.httpService.get<RegionPackage[]>(`${this.serviceBaseUrlV2}/region-partner-package`);
  }

  @Mock(REGION_PACKAGE_LIST)
  getRegionPackageList(regionId: AAGUID): Observable<ListResult<IRegionPackage, RegionPackage>> {
    return this.httpService.get<IListResult<IRegionPackage>>(`${this.serviceBaseUrlV2}/region-package/${regionId}`);
  }

  @Mock(REGION_PACKAGE)
  getRegionPackage(packageId: AAGUID): Observable<IRegionPackage> {
    return this.httpService.get<IRegionPackage>(`${this.serviceBaseUrlV2}/region-package/${packageId}/detail`);
  }

  @Mock()
  createRegionPackage(regionPackageCreator: RegionPackageCreator, regionId: AAGUID): Observable<Response> {
    return this.httpService.post<Response>(`${this.serviceBaseUrlV2}/region-package/${regionId}`, regionPackageCreator);
  }

  @Mock()
  updateRegionPackage(regionPackageEditor: RegionPackageEditor, packageId: AAGUID): Observable<Response> {
    return this.httpService.put<Response>(`${this.serviceBaseUrlV2}/region-package/${packageId}`, regionPackageEditor);
  }

  @Mock(PERMISSIONS_MOCK)
  getPermissions(): Observable<string[]> {
    return this.httpService.get<{ features: string[] }>(`${this.serviceBaseUrlV2}/user-partner/feature`)
      .pipe(map(({ features }) => features));
  }

  @Mock(PARTNER_ROLES_FOR_USER)
  getPartnerRolesForUser(userId: AAGUID): Observable<IListResult<IUserPartnerAssociation>> {
    return this.httpService.get<IListResult<IUserPartnerAssociation>>(`${this.serviceBaseUrlV1}/user-partner/${userId}/role`);
  }

  @Mock()
  saveUserRoles(userPartnerEditor: IUserPartnerEditor): Observable<Response> {
    return this.httpService.put(`${this.serviceBaseUrlV1}/user-partner`, userPartnerEditor);
  }

  @Mock(ADMIN_PARTNERS)
  getAdminPartners(): Observable<IAdminPartner[]> {
    return this.httpService.get<IAdminPartner[]>(`${this.serviceBaseUrlV2}/user-partner/accessible-admin-partners`);
  }

  @Mock(USER_ID_LIST)
  getUserPartnerRole(filterParams: UserPartnerRoleFilter): Observable<IUserIdList> {
    const params = filterParams.getHttpParams();
    return this.httpService.get<IUserIdList>(`${this.serviceBaseUrlV2}/user-partner/role`, { params });
  }

}
