import { IIcon } from '@shared/interfaces/icon/icon.interface';

export const ICONS_CONFIG: IIcon[] = [
  {
    name: 'asterisk',
    label: 'asterisk',
  },
  {
    name: 'biking',
    label: 'biking',
  },
  {
    name: 'building',
    label: 'building',
  },
  {
    name: 'bus',
    label: 'bus',
  },
  {
    name: 'car-crash',
    label: 'car-crash',
  },
  {
    name: 'car-side',
    label: 'car-side',
  },
  {
    name: 'dumpster',
    label: 'dumpster',
  },
  {
    name: 'exclamation-triangle',
    label: 'exclamation-triangle',
  },
  {
    name: 'exclamation',
    label: 'exclamation',
  },
  {
    name: 'fill-drip',
    label: 'fill-drip',
  },
  {
    name: 'grip-vertical',
    label: 'grip-vertical',
  },
  {
    name: 'home',
    label: 'home',
  },
  {
    name: 'infinity-icon',
    label: 'infinity',
  },
  {
    name: 'info-circle',
    label: 'info-circle',
  },
  {
    name: 'lightbulb',
    label: 'lightbulb',
  },
  {
    name: 'manual-icon',
    label: 'manual-icon',
  },
  {
    name: 'map-signs',
    label: 'map-signs',
  },
  {
    name: 'monument',
    label: 'monument',
  },
  {
    name: 'parking',
    label: 'parking',
  },
  {
    name: 'quidditch',
    label: 'quidditch',
  },
  {
    name: 'road',
    label: 'road',
  },
  {
    name: 'running',
    label: 'running',
  },
  {
    name: 'seedling',
    label: 'seedling',
  },
  {
    name: 'shoe-prints',
    label: 'shoe-prints',
  },
  {
    name: 'shower',
    label: 'shower',
  },
  {
    name: 'spray-can',
    label: 'spray-can',
  },
  {
    name: 'star',
    label: 'star',
  },
  {
    name: 'tenge',
    label: 'tenge',
  },
  {
    name: 'thumbtack',
    label: 'thumbtack',
  },
  {
    name: 'tint',
    label: 'tint',
  },
  {
    name: 'toolbox',
    label: 'toolbox',
  },
  {
    name: 'tools',
    label: 'tools',
  },
  {
    name: 'traffic-light',
    label: 'traffic-light',
  },
  {
    name: 'trash-alt',
    label: 'trash-alt',
  },
  {
    name: 'trash',
    label: 'trash',
  },
  {
    name: 'tree',
    label: 'tree',
  },
  {
    name: 'universal-access',
    label: 'universal-access',
  },
  {
    name: 'users',
    label: 'users',
  },
  {
    name: 'vial',
    label: 'vial',
  },
  {
    name: 'walking',
    label: 'walking',
  },
  {
    name: 'wheelchair',
    label: 'wheelchair',
  },
  {
    name: 'wine-bottle',
    label: 'wine-bottle',
  },
  {
    name: 'wrench',
    label: 'wrench',
  },
];
