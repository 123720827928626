<!-- ICON -->
<ui-kit-icon *ngIf="icon && !isLoading" [icon]="icon" [size]="iconSize"></ui-kit-icon>

<!-- LOADER -->
<ui-kit-spinner *ngIf="isLoading" [palette]="spinnerPalette" [size]="iconSize"></ui-kit-spinner>

<!-- TITLE -->
<h2 *ngIf="title">{{ title | translate }}</h2>

<!-- DESCRIPTION -->
<p *ngIf="description">{{ description | translate }}</p>

<!-- CONTENT -->
<div class="es-content">
  <ng-content></ng-content>
</div>
