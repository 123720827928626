import { StatusIconConfigs } from '@shared/models/status-icon/status-icon-configs.model';

export const STATUS_ICON_CONFIGS: StatusIconConfigs = {
  Approved: {
    icon: 'playlist_add_check',
    classes: ['status-icon', 'active'],
  },
  Deprecated: {
    icon: 'error_outline',
    classes: ['status-icon', 'closed'],
  },
  Pending: {
    icon: 'help_outline',
    classes: ['status-icon', 'waiting-for-confirmation'],
  },
  Rejected: {
    icon: 'highlight_off',
    classes: ['status-icon', 'closed'],
  },
  active: {
    icon: 'done',
    classes: ['status-icon', 'active'],
  },
  inactive: {
    icon: 'close',
    classes: ['status-icon', 'inactive'],
  },
  waiting_for_confirmation: {
    icon: 'help_outline',
    classes: ['status-icon', 'waiting-for-confirmation'],
  },
  deleted: {
    icon: 'close',
    classes: ['status-icon', 'deleted'],
  },
  published: {
    icon: 'done',
    classes: ['status-icon', 'published'],
  },
  opened: {
    icon: 'error_outline',
    classes: ['status-icon', 'opened'],
  },
  closed: {
    icon: 'done',
    classes: ['status-icon', 'closed'],
  },
  inProgress: {
    icon: 'access_time',
    classes: ['status-icon', 'in-progress'],
  },
  draft: {
    icon: 'edit',
    classes: ['status-icon', 'draft'],
  },
  private: {
    icon: 'people_outline',
    classes: ['status-icon', 'private'],
  },
  public: {
    icon: 'people',
    classes: ['status-icon', 'public'],
  },
  Invited: {
    icon: 'people',
    classes: ['status-icon', 'public'],
  },
  New: {
    icon: 'fiber_new',
    classes: ['status-icon', 'draft'],
  },
  WaitingForApproval: {
    icon: 'help_outline',
    classes: ['status-icon', 'waiting-for-confirmation'],
  },
  Draft: {
    icon: 'border_color',
    classes: ['status-icon', 'draft2'],
  },
  Active: {
    icon: 'done',
    classes: ['status-icon', 'public'],
  },
  Inactive: {
    icon: 'close',
    classes: ['status-icon', 'inactive'],
  },
  Suspended: {
    icon: 'not_interested',
    classes: ['status-icon', 'closed'],
  },
  Closed: {
    icon: 'close',
    classes: ['status-icon', 'closed'],
  },
  ROLE_ENABLED: {
    icon: 'done',
    classes: ['status-icon', 'active'],
  },
  ROLE_DISABLED: {
    icon: 'close',
    classes: ['status-icon', 'closed'],
  },
  deactive: {
    icon: 'block',
    classes: ['status-icon', 'deleted'],
  },
  Succeeded: {
    icon: 'check_circle_outline',
    classes: ['status-icon', 'public'],
  },
  InProgress: {
    icon: 'update',
    classes: ['status-icon', 'in-progress'],
  },
  Failed: {
    icon: 'highlight_off',
    classes: ['status-icon', 'deleted'],
  },
  Stucked: {
    icon: 'error_outline',
    classes: ['status-icon', 'closed'],
  },
};

export const REPORT_STATUS_ICON_CONFIGS: StatusIconConfigs = {
  opened: {
    icon: 'whatshot',
    classes: ['status-icon', 'closed'],
  },
  in_progress: {
    icon: 'build',
    classes: ['status-icon', 'report-in-progress'],
  },
  closed: {
    icon: 'done',
    classes: ['status-icon', 'active'],
  },
  forwarded: {
    icon: 'forward_to_inbox',
    classes: ['status-icon', 'active'],
  },
  handle_later: {
    icon: 'schedule_send',
    classes: ['status-icon', 'active'],
  },
};

export const PARKING_STATUS_ICON_CONFIGS: StatusIconConfigs = {
  NEW: {
    icon: 'fiber_new',
    classes: ['status-icon', 'active'],
  },
  PREPARED: {
    icon: 'alarm_on',
    classes: ['status-icon', 'active'],
  },
  RUNNING: {
    icon: 'timer',
    classes: ['status-icon', 'active'],
  },
  FAILED: {
    icon: 'highlight_off',
    classes: ['status-icon', 'closed'],
  },
  STUCKED: {
    icon: 'error_outline',
    classes: ['status-icon', 'closed'],
  },
  STOPPED: {
    icon: 'timer_off',
    classes: ['status-icon', 'active'],
  },
};

export const VIGNETTE_STATUS_ICON_CONFIGS: StatusIconConfigs = {
  NEW: {
    icon: 'fiber_new',
    classes: ['status-icon', 'active'],
  },
  PURCHASED: {
    icon: 'alarm_on',
    classes: ['status-icon', 'active'],
  },
  FAILED: {
    icon: 'highlight_off',
    classes: ['status-icon', 'closed'],
  },
  STUCKED: {
    icon: 'error_outline',
    classes: ['status-icon', 'closed'],
  },
  VALID: {
    icon: 'timer_on',
    classes: ['status-icon', 'active'],
  },
  INVALID: {
    icon: 'timer_off',
    classes: ['status-icon', 'active'],
  },
};

export const PRODUCT_STATUS_ICON_CONFIGS: StatusIconConfigs = {
  NEW: {
    icon: 'fiber_new',
    classes: ['status-icon', 'active'],
  },
  PURCHASED: {
    icon: 'alarm_on',
    classes: ['status-icon', 'active'],
  },
  STORNO_IN_PROGRESS: {
    icon: 'timer',
    classes: ['status-icon', 'active'],
  },
  STORNO_FINISHED: {
    icon: 'timer_off',
    classes: ['status-icon', 'active'],
  },
  FAILED: {
    icon: 'highlight_off',
    classes: ['status-icon', 'closed'],
  },
  STUCKED: {
    icon: 'error_outline',
    classes: ['status-icon', 'closed'],
  },
};
