import { LanguageCode, TranslationMap } from '@interticket/core';
import { IContentTranslationIndicatorListStrategy } from '../interfaces/content-translation-indicator-list-strategy.interface';

export class ContentTranslationDefaultIndicatorList implements IContentTranslationIndicatorListStrategy {

  createIndicatorList(translations: Record<string, TranslationMap>, contentTranslationId: string): LanguageCode[] {
    const translationMap = translations[contentTranslationId];
    return Object.entries(translationMap)
      .reduce((languages: LanguageCode[], [lang, hasTranslation]) => {
        if (hasTranslation) {
          languages.push(lang as LanguageCode);
        }
        return languages;
      }, []);
  }

}
