import { AAGUID, LanguageCode, Status } from '@interticket/core';
import { UserStatus } from '../enums/user-status.enum';
import { IUserListItem } from '../interfaces/user-list-item.interface';
import { IUserSettings } from '../interfaces/user-settings.interface';

export class UserListItem implements IUserListItem {

  readonly id: AAGUID;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly status: Status<UserStatus>;
  readonly picture: AAGUID;
  readonly createdAt: string;
  readonly emailConfirmed: boolean;
  readonly userSettings: IUserSettings;

  constructor(iUserListItem: IUserListItem) {
    this.id = iUserListItem.id;
    this.firstName = iUserListItem.firstName;
    this.lastName = iUserListItem.lastName;
    this.email = iUserListItem.email;
    this.status = iUserListItem.status ? new Status<UserStatus>(iUserListItem.status) : null;
    this.picture = iUserListItem.picture;
    this.createdAt = iUserListItem.createdAt;
    this.emailConfirmed = iUserListItem.emailConfirmed;
    this.userSettings = iUserListItem.userSettings;
  }

  getFullName(lang?: LanguageCode): string {
    if (lang === LanguageCode.HU) {
      return `${this.lastName} ${this.firstName}`;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  getFullNameWithEmail(lang?: LanguageCode): string {
    return `${this.getFullName(lang)} (${this.email})`;
  }

}
