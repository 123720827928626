import { Status } from '@interticket/core';
import { Type } from '@shared/models/type/type.model';
import { IUserPartnerAssociation } from '../interfaces/user-partner-association.interface';

export class UserPartnerAssociation implements IUserPartnerAssociation {

  readonly associationId: string;
  readonly associationStatus: Status<string>;
  readonly roleId: string;
  readonly roleName: string;
  readonly roleType: Type<string>;

  constructor(iUserPartnerAssociation: IUserPartnerAssociation) {
    this.associationId = iUserPartnerAssociation.associationId;
    this.associationStatus = new Status<string>(iUserPartnerAssociation.associationStatus);
    this.roleId = iUserPartnerAssociation.roleId;
    this.roleName = iUserPartnerAssociation.roleName;
    this.roleType = new Type<string>(iUserPartnerAssociation.roleType);
  }

}
