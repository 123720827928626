import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-icon-base-card',
  templateUrl: './ui-kit-icon-base-card.component.html',
  styleUrls: ['./ui-kit-icon-base-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[style]': 'paletteColors' },
})
export class UiKitIconBaseCardComponent {

  protected readonly sanitizer = inject(DomSanitizer);

  readonly icon = input.required<string>();
  readonly iconSize = input<number>();
  readonly title = input.required<string>();
  readonly palette = input<UiKitPalette>();
  readonly size = input<UiKitSize>(UiKitSize.MEDIUM);
  readonly description = input<string>();

  readonly effectiveIconSize = computed(() =>
    this.iconSize() ?? (this.size() === UiKitSize.MEDIUM ? 18 : 24)
  );

  get paletteColors(): SafeStyle {
    const styles: string[] = [];

    if (this.palette()) {
      styles.push(`--ui-kit-card-base-color: var(--${this.palette()}-color);`);
    }

    return this.sanitizer.bypassSecurityTrustStyle(styles.join('\n'));
  }

}
