import { AAGUID } from '@interticket/core';
import { RegionsFeatureSelectGroup } from '@shared/modules/select-group/models/regions-feature-select-group.model';
import { IRegionPackage } from '../interfaces/region-package.interface';
import { RegionPackageCreator } from './region-package-creator.model';

export class RegionPackage extends RegionPackageCreator implements IRegionPackage {

  readonly id: AAGUID;

  featureList?: RegionsFeatureSelectGroup[];

  constructor(iRegionPackage: IRegionPackage) {
    super(iRegionPackage);
    this.id = iRegionPackage.id;
  }

}
