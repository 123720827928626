export enum UiKitPalette {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  ACTION = 'action',
  INFO = 'info',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  BLUE = 'blue',
  GREEN = 'green',
  PURPLE_BLUE = 'purple-blue',
  DARK_GREY = 'dark-grey',
}
