import { IPartnerSettingsNTAK } from '../interfaces/partner-settings-ntak.interface';

export class PartnerSettingsNTAK implements IPartnerSettingsNTAK {

  regNumber: string;
  certFile?: File;
  keyFile?: File;
  certFileName: string;
  keyFileName: string;
  vatNumber?: string;

  constructor(iConfig: IPartnerSettingsNTAK) {
    this.regNumber = iConfig?.regNumber || null;
    this.certFile = iConfig?.certFile || null;
    this.keyFile = iConfig?.keyFile || null;
    this.certFileName = iConfig?.certFileName || null;
    this.keyFileName = iConfig?.keyFileName || null;
    this.vatNumber = iConfig?.vatNumber || null;
  }

}
