export enum SmartCityCardPermission {
  LIST = 'smartcity.citycard.module.list.own',
  CREATE = 'smartcity.citycard.module.create.by.admin',
  DETAILS = 'smartcity.citycard.module.details',
  APPROVE = 'smartcity.citycard.module.approve.or.reject.card.requests',
  NUMBER = 'smartcity.citycard.module.get.own.card.number',
  EXPORT = 'smartcity.citycard.module.export.card.details',
  WIFI = 'smartcity.citycard.module.add.wifi.password',
  IMPORT = 'smartcity.citycard.module.import',
  ADMIN_EDIT = 'smartcity.citycard.module.update.by.admin',
  CITIZEN_REGISTRY_IMPORT = 'smartcity.citycard.module.update.citizen.registry.import',
  APPLY_CITIZEN_CORRECTION = 'smartcity.citycard.module.update.citizen.registry.appply.correction',
  APPLY_CITIZEN_CORRECTIONS = 'smartcity.citycard.module.update.citizen.registry.appply.corrections',
  REVOKE_STATUSES = 'smartcity.citycard.module.update.revoke.statuses'
}
