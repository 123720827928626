import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { activeTabStorageKey } from '@shared/configs/navigate-buttons.config';
import { Button } from '@shared/models/button/button.model';

@Component({
  selector: 'navigate-buttons',
  templateUrl: './navigate-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigateButtonsComponent {

  @Input() readonly buttons: Button[] = [];

  constructor(
    protected router: Router,
  ) { }

  onClick(button: Button) {
    localStorage.setItem(activeTabStorageKey, button.routerLink);
    this.router.navigate([button.routerLink]);
  }

}
