<label
  [class.list-param]="listParam">{{ label | translate }}
  <input
    type="text"
    #autocompleteInput
    appFormControl
    [matAutocomplete]="auto"
    [formControl]="dropdownControl"
    [placeholder]="(dropdownControl.enabled ? placeholder : 'list_empty') | translate"
    (keydown.enter)="$event.preventDefault()">
  <app-show-errors [control]="dropdownControl"></app-show-errors>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [class]="panelClass" (optionSelected)="onOptionSelected($event.option.id)" (closed)="onCloseDropdown()">
    <!-- EMTY LIST MESSAGE -->
    <mat-option disabled *ngIf="isEmptyList">{{ 'label_no_data_to_display' | translate }}</mat-option>
    <!-- LOADER -->
    <mat-option disabled *ngIf="isLoading$ | async">
      <mat-spinner [diameter]="20"></mat-spinner>
    </mat-option>
    <!-- LIST -->
    <mat-option [class.hide]="isLoading$ | async" *ngFor="let item of filteredList$ | async" [value]="item.name" [id]="item.id">
      <ng-template *ngIf="listItemRef else itemTemplate" [ngTemplateOutlet]="listItemRef" [ngTemplateOutletContext]="{ item: item, asyncItem: getAsyncListItem(item.id) }"></ng-template>
      <ng-template #itemTemplate>{{ item.name }}</ng-template>
    </mat-option>
  </mat-autocomplete>
</label>
