export const colorMap = {
  '#3d3e5a': { class: 'primary', translationKey: 'admin_crm_color_black' },
  '#12c782': { class: 'success', translationKey: 'admin_crm_color_green' },
  '#5db3de': { class: 'blue', translationKey: 'admin_crm_color_cyan' },
  '#2680ff': { class: 'info', translationKey: 'admin_crm_color_blue' },
  '#9747ff': { class: 'purple-3', translationKey: 'admin_crm_color_purple' },
  '#ce5749': { class: 'error', translationKey: 'admin_crm_color_red' },
  '#ff922e': { class: 'orange', translationKey: 'admin_crm_color_orange' },
};

export const DEFAULT_COLOR = '#3d3e5a';

export function getColors(): string[] {
  return Object.keys(colorMap);
}
