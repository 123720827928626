<span
  [class.width-100]="fillWidth"
  [ngClass]="{ 'report-mode': displayMode === 'report', 'filter-mode': displayMode === 'filter' }"
  [matTooltip]="tooltip | translate"
  [matTooltipPosition]="tooltipPosition"
  [matTooltipDisabled]="isDisabled && hideTooltipWhenDisabled">

  <button
    matRipple
    [matRippleDisabled]="rippleDisabled"
    [type]="type"
    [disabled]="isDisabled"
    [class.loader]="isLoading"
    [class.inline]="isInline"
    [class.link]="isLink"
    [class.float]="isFloat"
    [class.hollow]="isHollow"
    [class.light]="isLight"
    [class.icon-only]="isIconOnly"
    [class.is-rounded]="isRounded"
    [class.is-invalid-input]="isInvalid"
    [class.has-background]="hasBackground"
    [class.disable-padding]="isDisablePadding"
    [class.no-border]="noBorder"
    [class.is-align-to-input]="isAlignToInput"
    [ngClass]="classes"
    (click)="onClick.emit($event)">

    <!-- LOADER -->
    <ui-kit-spinner *ngIf="isLoading" [size]="iconSize" class="loader-icon"></ui-kit-spinner>

    <!-- ICON -->
    <ui-kit-icon *ngIf="icon && !isIconRight" [icon]="icon" [size]="iconSize"></ui-kit-icon>

    <!-- LABEL -->
    <ng-container *ngIf="label">{{ label | translate }}</ng-container>

    <!-- CONTENT -->
    <ng-content></ng-content>

    <!-- ICON RIGHT -->
    <ui-kit-icon *ngIf="icon && isIconRight" [icon]="icon" [size]="iconSize" class="icon-right"></ui-kit-icon>

    <!-- PREDEFINED CONTENT -->
    <ng-template
      *ngIf="buttonType"
      [ngTemplateOutlet]="{
        'back': back,
        'close': close
      }[buttonType]">
    </ng-template>

  </button>

  <!-- BACK CONTENT -->
  <ng-template #back>
    <ui-kit-icon [icon]="'chevron-left'" [size]="iconSize"></ui-kit-icon>
    {{ 'general_lang_back' | translate }}
  </ng-template>

  <!-- CLOSE CONTENT -->
  <ng-template #close>
    <ui-kit-icon [icon]="'x'" [size]="iconSize"></ui-kit-icon>
    {{ 'stemx_general_lang_close' | translate }}
  </ng-template>

</span>
