import { IAuthUser } from '@core/interfaces/auth/auth-user.interface';
import { PartnerConfig } from '@interticket/core';
import { IUserProfile } from '@services/iam-management-user';

export class AuthUser implements IAuthUser {

  readonly userProfile: IUserProfile;
  readonly permissionList: string[];
  readonly partnerConfig: PartnerConfig;

  constructor(iUser: IAuthUser) {
    this.userProfile = iUser.userProfile || null;
    this.permissionList = iUser.permissionList || [];
    this.partnerConfig = iUser.partnerConfig ? new PartnerConfig(iUser.partnerConfig) : null;
  }

}
