import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AAGUID } from '@interticket/core';
import { IAutocompleteDropdownItem } from '@shared/interfaces/autocomplete-dropdown/autocomplete-dropdown-item.interface';
import { BookingServiceHeader } from 'app/services/bookings/models/booking-service.model';
import { DateTime } from 'luxon';
import { IBookingDialogConfig } from '../../interfaces/booking-dialog-config.interface';

@Component({
  selector: 'booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrls: ['./booking-dialog.component.scss'],
})
export class BookingDialogComponent implements OnInit {

  bookingForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBookingDialogConfig,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.bookingForm = this.formBuilder.group({
      userId: [null, Validators.required],
      serviceId: [null, Validators.required],
      resourceId: [this.data.resourceId, Validators.required],
      start: [DateTime.fromJSDate(this.data.eventDate), Validators.required],
    });
  }

  onOptionSelected(user: IAutocompleteDropdownItem): void {
    this.bookingForm.get('userId').patchValue(user);
  }

  trackById(_index: number, service: BookingServiceHeader): AAGUID {
    return service.id;
  }

}
