export enum RatePermission {
  FEE_CREATE = 'fee.fee.create.fee',
  FEE_DELETE = 'fee.fee.delete.fee',
  FEE_EDIT = 'fee.fee.edit.fee',
  FEE_LIST = 'fee.fee.list.and.search.fees',
  FEE_VIEW = 'fee.fee.details.fee',
  FEE_RESTORE = 'fee.fee.restore.fee',
  DISCOUNT_CREATE = 'discountv2.create.discount',
  DISCOUNT_DELETE = 'discountv2.delete.discount',
  DISCOUNT_RESTORE = 'discountv2.restore.discount',
  DISCOUNT_EDIT = 'discountv2.edit.discount',
  DISCOUNT_LIST = 'discountv2.list.and.search.discounts',
  DISCOUNT_VIEW = 'discountv2.details.discount',
}

export const DiscountPermissions = [
  RatePermission.DISCOUNT_CREATE,
  RatePermission.DISCOUNT_DELETE,
  RatePermission.DISCOUNT_RESTORE,
  RatePermission.DISCOUNT_EDIT,
  RatePermission.DISCOUNT_LIST,
  RatePermission.DISCOUNT_VIEW,
];

export const FeePermissions = [
  RatePermission.FEE_CREATE,
  RatePermission.FEE_DELETE,
  RatePermission.FEE_EDIT,
  RatePermission.FEE_LIST,
  RatePermission.FEE_VIEW,
  RatePermission.FEE_RESTORE,
];
