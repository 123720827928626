import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiKitCoreModule } from '@interticket/core';
import { SharedModule } from '@shared/shared.module';
import { FeatherModule } from 'angular-feather';
import { UiKitIconComponent } from './components/ui-kit-icon/ui-kit-icon.component';
import { UiKitSpinnerComponent } from './components/ui-kit-spinner/ui-kit-spinner.component';
import { UiKitStatusIconComponent } from './components/ui-kit-status-icon/status-icon.component';
import { UiKitIconPack } from './configs/icon-pack';

const components = [
  UiKitIconComponent,
  UiKitSpinnerComponent,
  UiKitStatusIconComponent,
];

@NgModule({
  imports: [
    SharedModule,
    FeatherModule.pick(UiKitIconPack),
    UiKitCoreModule,

    // Material imports
    MatTooltipModule,
  ],
  declarations: [
    ...components,
  ],
  exports: [
    FeatherModule,
    ...components,
  ],
})
export class UiKitIconsModule { }
