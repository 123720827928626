import { Company } from '@core/models/company/company.model';
import { AAGUID, Status } from '@interticket/core';
import { PartnerStatus } from '../enums/partner-status.enum';
import { IPartnerListItem } from '../interfaces/partner-list-item.interface';

export class PartnerListItem implements IPartnerListItem {

  readonly partnerId: AAGUID;
  readonly name: string;
  readonly status: Status<PartnerStatus>;
  readonly company: Company;
  readonly invitedUserEmail: string;

  constructor(iPartner: IPartnerListItem) {
    this.partnerId = iPartner.partnerId;
    this.name = iPartner.name;
    this.status = new Status<PartnerStatus>(iPartner.status);
    this.company = new Company(iPartner.company);
    this.invitedUserEmail = iPartner.invitedUserEmail;
  }

}
