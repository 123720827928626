export enum Environment {
  DEVELOPMENT = 'dev',
  QA = 'qa',
  LOCAL = 'local',
  MOCK = 'mock',
  PRODUCTION = 'prod',
  USA_PRODUCTION = 'us-prod',
  PRODLIKE = 'prodlike',
  EXT = 'ext',
  EXT2 = 'ext2',
}
