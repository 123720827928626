import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'search-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'primary'];
  @Input() readonly icon: string = 'search';
  @Input() readonly label: string = 'general_lang_search';
  @Input() readonly type: 'button' | 'submit' = 'submit';

}
