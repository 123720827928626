<div class="title-container" *ngIf="title$ | async as title" [@slideInRight]="{ value: titleChange$ | async, params: { x: '-25px' } }">

  <!-- ICON & TITLE -->
  <h1>
    <ui-kit-icon *ngIf="title.icon" [icon]="title.icon" [size]="30"></ui-kit-icon>
    {{ title.label | translate }}
  </h1>

  <!-- SUBTITLE -->
  <span class="subtitle">
    <ng-container *ngIf="subtitle else subtitleContent">{{ subtitle | translate }}</ng-container>
    <ng-template #subtitleContent>
      <ng-content select="[ui-kit-subtitle]"></ng-content>
    </ng-template>
  </span>

</div>
