import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ui-kit-empty-state-row',
  templateUrl: './ui-kit-empty-state-row.component.html',
  styleUrls: ['./ui-kit-empty-state-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitEmptyStateRowComponent {

  @Input() readonly title: string;
  @Input() readonly description: string;
  @Input() @HostBinding('class.has-error') hasError = false;

  /** {@link} https://feathericons.com/ */
  @Input() readonly icon: string;
  @Input() readonly titleTemplate: TemplateRef<unknown>;

}
