export const BlockchainService = `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_309_33)">
<path d="M8.25 15.792L5.25 14.142V10.767L8.25 9.11699L11.25 10.767V14.142L8.25 15.792ZM6.75 13.242L8.25 14.067L9.75 13.242V11.592L8.25 10.767L6.75 11.592V13.242Z" fill="currentColor"/>
<path d="M4.5 22.542L1.5 20.892V17.517L4.5 15.792L7.5 17.442V20.817L4.5 22.542ZM3 19.992L4.5 20.817L6 19.992V18.342L4.5 17.517L3 18.342V19.992Z" fill="currentColor"/>
<path d="M12 22.542L9 20.892V17.517L12 15.867L15 17.517V20.892L12 22.542ZM10.5 19.992L12 20.817L13.5 19.992V18.342L12 17.517L10.5 18.342V19.992Z" fill="currentColor"/>
<path d="M19.5 22.542L16.5 20.892V17.517L19.5 15.867L22.5 17.517V20.892L19.5 22.542ZM18 19.992L19.5 20.817L21 19.992V18.342L19.5 17.517L18 18.342V19.992Z" fill="currentColor"/>
<path d="M18.45 8.74199L16.5 10.692V7.09199L18.75 5.81699V2.44199L15.75 0.791992L12.75 2.44199V5.81699L15 7.09199V10.617L13.05 8.66699L12 9.79199L15.75 13.542L19.5 9.79199L18.45 8.74199ZM14.25 3.34199L15.75 2.51699L17.25 3.34199V4.99199L15.75 5.81699L14.25 4.99199V3.34199Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_309_33">
<rect width="24" height="24" fill="transparent" transform="translate(0 0.0419922)"/>
</clipPath>
</defs>
</svg>`;
