import { AAGUID, FilterParams } from '@interticket/core';
import { PartnerStatus } from '../enums/partner-status.enum';
import { IPartnerFilter } from '../interfaces/partner-filter.interface';

export class PartnerFilter extends FilterParams implements IPartnerFilter {

  readonly ids?: AAGUID[];
  readonly invitedUserEmail?: string;
  readonly partnerName?: string;
  readonly partnerStatus?: PartnerStatus;
  readonly searchText?: string;

  constructor(iPartnerFilter: IPartnerFilter) {
    super();
    this.ids = Array.isArray(iPartnerFilter.ids) && iPartnerFilter.ids.length ? iPartnerFilter.ids : null;
    this.invitedUserEmail = iPartnerFilter.invitedUserEmail || null;
    this.partnerName = iPartnerFilter.partnerName || null;
    this.partnerStatus = iPartnerFilter.partnerStatus || null;
    this.searchText = iPartnerFilter.searchText || null;
  }

}
