import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { AppError } from '@interticket/core';
import { DialogService } from '@shared/modules/dialog/services/dialog.service';
import { NotifierService } from '../notification/notifier.service';
import { ErrorLoggerService } from './error-logger.service';

/**
 * {@link} https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private isConfirmingReload = false;

  constructor(
    private injector: Injector,
    private zone: NgZone
  ) { }

  handleError(error: AppError): void {
    // Angular Lazy Routes & loading chunk failed
    // https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
    if (/Loading chunk [\d]+ failed/.test(error?.message)) {
      if (!this.isConfirmingReload) {
        this.isConfirmingReload = true;

        this.zone.run(() => {
          const dialogService = this.injector.get(DialogService);
          dialogService.openConfirmDialog(
            (confirm) => confirm ? window.location.reload() : this.isConfirmingReload = false,
            { data: { title: 'new_version_available', description: 'app_updated_page_will_reload' } }
          );
        });
      }

      return;
    }

    const logger = this.injector.get(ErrorLoggerService);
    const notifier = this.injector.get(NotifierService);

    // Show notification
    notifier.showError(error);

    // Logging Error
    logger.logError(error);
  }

}
