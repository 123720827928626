import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'download-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'hollow'];
  @Input() readonly icon: string = 'get_app';
  @Input() readonly label: string = 'label_excel_download';
  @Input() readonly type: 'button';

}
