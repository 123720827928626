import { Directive, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@interticket/core';
import { SelectGroupShowModes } from '../../../select-group/enums/select-group-show-modes.enum';
import { RegionsFeatureSelectGroup } from '../../../select-group/models/regions-feature-select-group.model';
import { ISelectGroupDialogConfig } from '../../interfaces/select-group-dialog-config.interface';

@Directive()
export abstract class SelectGroupDialogBase<GroupType, FeatureType> implements OnInit {

  protected abstract dialogData: ISelectGroupDialogConfig;
  protected abstract dialogRef: MatDialogRef<SelectGroupDialogBase<GroupType, FeatureType>>;
  protected abstract translate: TranslateService;

  protected abstract allFeatureIds: FeatureType[];

  featureList: RegionsFeatureSelectGroup<GroupType>[];
  featureIdsControl: UntypedFormControl = new UntypedFormControl([]);
  isAllSelected: boolean;
  disableEdit: boolean;
  showMode: SelectGroupShowModes;

  ngOnInit(): void {
    this.featureIdsControl.patchValue(this.dialogData.featuresControl.value);
    this.featureList = this.dialogData.list;
    this.disableEdit = this.dialogData.disableEdit;
    this.showMode = this.dialogData.showMode || SelectGroupShowModes.ALL;
  }

  onSave(): void {
    if (!this.disableEdit) {
      this.dialogData.featuresControl.patchValue(this.featureIdsControl.value);
      this.dialogRef.close();
    }
  }

  onFeatureToggled(): void {
    if (!this.disableEdit) {
      this.setAllSelected();
    }
  }

  onSelectAllToggled(): void {
    this.featureIdsControl.patchValue(this.isAllSelected ? [] : this.allFeatureIds);
    this.isAllSelected = !this.isAllSelected;
  }

  protected setAllSelected(): void {
    this.isAllSelected = this.featureList.every(item => item.selected);
  }

}
