<div class="custom-row">
  <mat-form-field class="legacy-form-field" appearance="outline">
    <div class="mat-infix">
      <input matInput class="search-input" [formControl]="searchFormControl" placeholder="{{ 'stemx_custom_search_bar' | translate }}" autocomplete="off">
    </div>
    <div matSuffix>
      <mat-checkbox
        class="checkbox-container"
        [formControl]="labelFormControl"
        (change)="onToggleLabelsFilterLabel($event.checked)">
        {{ 'stemx_labels_filter_label' | translate }}
      </mat-checkbox>
      <mat-checkbox
        class="checkbox-container"
        [formControl]="translationFormControl"
        (change)="onToggleTranslationsFilterLabel($event.checked)">
        {{ 'stemx_translations_filter_label' | translate }}
      </mat-checkbox>
    </div>
  </mat-form-field>
</div>
