import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

/**
 * How to Detect When a Sticky Element Gets Pinned
 * {@link} https://davidwalsh.name/detect-sticky
 */
@Directive({
  selector: '[uiKitStickyPinned]',
})
export class UiKitStickyPinnedDirective implements AfterViewInit, OnDestroy {

  @Input() readonly pinnedClass = 'is-pinned';
  @Input() readonly rootMargin: string = '0px';
  @Input() readonly threshold: number | number[] = 1;
  @Input() readonly rootId: string;

  private observer: IntersectionObserver;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    if (this.rootId !== undefined) {
      this.setupObserver();
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private setupObserver(): void {
    const options = {
      root: this.rootId ? document.getElementById(this.rootId) : null,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    this.observer = new IntersectionObserver(
      entries => entries.forEach(entry => this.renderer[entry.isIntersecting ? 'removeClass' : 'addClass'](this.element.nativeElement, this.pinnedClass)),
      options
    );

    this.observer.observe(this.element.nativeElement);
  }

}
