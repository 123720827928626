import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-length-error-field',
  templateUrl: './length-error-field.component.html',
  styleUrls: ['./length-error-field.component.scss'],
})
export class LengthErrorComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();

  @Input() textFormControl: UntypedFormControl;
  @Input() isFieldInput: boolean;
  @Input() maxLength: number;
  @Input() label: string;
  @Input() tooltip: string;

  textLength: number;

  ngOnInit(): void {
    this.textLength = this.textFormControl.value?.length || 0;
    this.subscribeToMessageInput();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToMessageInput(): void {
    this.textFormControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (value: string) => this.textLength = value ? value.length : 0,
      });
  }

}
