import { Injectable } from '@angular/core';
import { AAGUID, ApiError, IListResult, IPartnerConfig, IPartnerOrganizerWebSettings, IPartnerWebConfig, IPlaceModuleConfig, IStatus, ListResult, PartnerConfig, PartnerWebConfig, SearchParams, Status, Utils } from '@interticket/core';
import { IPartnerHierarchyListItem } from '@interticket/template-layout-editor-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartnerPollStatus } from '../enums/partner-poll-status.enum';
import { PartnerStatus } from '../enums/partner-status.enum';
import { IPartnerCreatorWebConfig } from '../interfaces/partner-creator-web-config.interface';
import { IPartnerDetailsEditor } from '../interfaces/partner-detail-editor.interface';
import { IPartnerEditDetail } from '../interfaces/partner-edit-detail.interface';
import { IPartnerEditFeatureSettings } from '../interfaces/partner-edit-feature-settings.interface';
import { IContactIds, IPartnerEditGeneralData, IPartnerEditGeneralDataContact } from '../interfaces/partner-edit-general-data.interface';
import { IPartnerEditGeneralSettings } from '../interfaces/partner-edit-general-settings.interface';
import { IPartnerEditInvoiceSettings } from '../interfaces/partner-edit-invoice-setting.interface';
import { IPartnerEditLegalEntity } from '../interfaces/partner-edit-legal-entity.interface';
import { IPartnerEditSalesSettings } from '../interfaces/partner-edit-sales-settings.interface';
import { IPartnerEditWebSettings } from '../interfaces/partner-edit-web-settings.interface';
import { IPartnerListItem } from '../interfaces/partner-list-item.interface';
import { IPartnerListItemBasic } from '../interfaces/partner-list-item-basic.interface';
import { IPartnerOrganizerCheckoutSurveySettings } from '../interfaces/partner-organizer-checkout-survey-settings.interface';
import { IPartnerOrganizerDefaultSettings } from '../interfaces/partner-organizer-default-settings.interface';
import { IPartnerPollStatusResponse } from '../interfaces/partner-poll-status-response.interface';
import { IPartnerSettingsConfigMailSettings } from '../interfaces/partner-settings-confirm-mail.interface';
import { IPartnerSettingsHolder } from '../interfaces/partner-settings-holder.interface';
import { IPartnerSettingsNTAK } from '../interfaces/partner-settings-ntak.interface';
import { IPartnerView } from '../interfaces/partner-view.interface';
import { ISmartCityPartner } from '../interfaces/smart-city-partner.interface';
import { PartnerByNameFilter } from '../models/partner-by-name-filter.model';
import { PartnerCreatePayload } from '../models/partner-create-payload.model';
import { PartnerEditor } from '../models/partner-editor.model';
import { PartnerFilter } from '../models/partner-filter.model';
import { PartnerInvite } from '../models/partner-invite.model';
import { PartnerListItem } from '../models/partner-list-item.model';
import { PartnerOrganizerConfig } from '../models/partner-organizer-config.model';
import { PartnerOrganizerDefaultSettings } from '../models/partner-organizer-default-settings.model';
import { PartnerOrganizerWebSettingsWithDefaults } from '../models/partner-organizer-web-settings-with-defaults.model';
import { PartnerProfileEditor } from '../models/partner-profile-editor.model';
import { PartnerSettingsHolder } from '../models/partner-settings-holder.model';
import { PartnerView } from '../models/partner-view.model';
import { PartnerOrganizerDetail } from '../types/partner-organizer-detail.type';
import { IamManagementPartnerSourceService } from './iam-management-partner-source.service';

@Injectable({
  providedIn: 'root',
})
export class IamManagementPartnerService {

  constructor(
    private iamManagementPartnerSourceService: IamManagementPartnerSourceService
  ) { }

  getPartnerOrganizerConfig(partnerId: AAGUID): Observable<PartnerOrganizerDetail> {
    return this.iamManagementPartnerSourceService.getPartnerOrganizerConfig(partnerId);
  }

  saveWebSettings(partnerId: AAGUID, settings: IPartnerOrganizerWebSettings): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveWebSettings(partnerId, new PartnerOrganizerWebSettingsWithDefaults(settings))
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  saveDefaultSettings(partnerId: AAGUID, settings: IPartnerOrganizerDefaultSettings): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveDefaultSettings(partnerId, new PartnerOrganizerDefaultSettings(settings))
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  saveSalesSettings(partnerId: AAGUID, settings: IPartnerSettingsHolder): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveSalesSettings(partnerId, new PartnerSettingsHolder(settings))
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  saveDefaultPlaceModules(partnerId: AAGUID, modules: IPlaceModuleConfig[]): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveDefaultPlaceModules(partnerId, modules)
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  saveConfirmMailSettings(partnerId: AAGUID, settings: IPartnerSettingsConfigMailSettings): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveConfirmMailSettings(partnerId, settings)
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  getListBasic(): Observable<IPartnerListItemBasic[]> {
    return this.iamManagementPartnerSourceService.getListBasic();
  }

  getPartnerCount(): Observable<number> {
    return this.iamManagementPartnerSourceService.getPartnerCount()
      .pipe(
        map(partnerCountResponse => partnerCountResponse.count)
      );
  }

  getList(searchParams?: SearchParams, filterParams?: PartnerFilter): Observable<ListResult<IPartnerListItem, PartnerListItem>> {
    return this.iamManagementPartnerSourceService.getList(searchParams, filterParams)
      .pipe(
        map((iListResult: IListResult<IPartnerListItem>) => new ListResult<IPartnerListItem, PartnerListItem>(iListResult, PartnerListItem))
      );
  }

  getStatuses(): Observable<Status<PartnerStatus>[]> {
    return this.iamManagementPartnerSourceService.getStatuses()
      .pipe(
        map((iStatuses: IStatus<PartnerStatus>[]) => iStatuses.map((iStatus: IStatus<PartnerStatus>) => new Status<PartnerStatus>(iStatus)))
      );
  }

  getOne(partnerGuid: AAGUID): Observable<PartnerView> {
    return this.iamManagementPartnerSourceService.getOne(partnerGuid)
      .pipe(
        map((iPartner: IPartnerView) => new PartnerView(iPartner))
      );
  }

  getPartnerBySlug(slug: string): Observable<IPartnerListItemBasic> {
    return this.iamManagementPartnerSourceService.getPartnerBySlug(slug);
  }

  getPartnerByName(filterParams: PartnerByNameFilter): Observable<IPartnerListItemBasic> {
    return this.iamManagementPartnerSourceService.getPartnerByName(filterParams);
  }

  editPartner(partnerGuid: AAGUID, partnerEditor: PartnerEditor): Observable<Response> {
    return this.iamManagementPartnerSourceService.editPartner(partnerGuid, partnerEditor);
  }

  editPartnerDetails(partnerGuid: AAGUID, partnerDetails: IPartnerDetailsEditor): Observable<Response> {
    return this.iamManagementPartnerSourceService.editPartnerDetails(partnerGuid, partnerDetails);
  }

  editPartnerPageSettings(partnerGuid: AAGUID, partnerPage: IPartnerCreatorWebConfig): Observable<Response> {
    return this.iamManagementPartnerSourceService.editPartnerPageSettings(partnerGuid, partnerPage);
  }

  getProfile(): Observable<PartnerView> {
    return this.iamManagementPartnerSourceService.getProfile()
      .pipe(
        map((iPartnerProfile: IPartnerView) => new PartnerView(iPartnerProfile))
      );
  }

  editProfile(partnerProfile: PartnerProfileEditor): Observable<Response> {
    return this.iamManagementPartnerSourceService.editProfile(partnerProfile);
  }

  changeStatus(partnerGuid: AAGUID, method: 'activate' | 'inactivate') {
    return this.iamManagementPartnerSourceService[method](partnerGuid);
  }

  invitePartner(partner: PartnerInvite): Observable<AAGUID> {
    return this.iamManagementPartnerSourceService.invitePartner(partner)
      .pipe(
        map(({ sagaId }) => sagaId),
      );
  }

  createPartner(partner: PartnerCreatePayload): Observable<AAGUID> {
    return this.iamManagementPartnerSourceService.createPartner(partner)
      .pipe(
        map(({ sagaId }) => sagaId),
      );
  }

  getPartnerStatus(sagaId: AAGUID): Observable<IPartnerPollStatusResponse> {
    return this.iamManagementPartnerSourceService.getPartnerStatus(sagaId);
  }

  getConfig(): Observable<PartnerConfig> {
    return this.iamManagementPartnerSourceService.getConfig()
      .pipe(
        map((iPartnerConfig: IPartnerConfig) => new PartnerConfig(iPartnerConfig)),
      );
  }

  getWebConfig(partnerIdToEdit?: AAGUID): Observable<PartnerWebConfig> {
    return this.iamManagementPartnerSourceService.getWebConfig(partnerIdToEdit)
      .pipe(
        map((iPartnerWebConfig: IPartnerWebConfig) => new PartnerWebConfig(iPartnerWebConfig))
      );
  }

  pollCreatePartnerStatus(sagaId: AAGUID, rethrowError = true): Observable<IPartnerPollStatusResponse> {
    return Utils.pollSkipWhile(
      () => this.getPartnerStatus(sagaId),
      partnerPollStatusResponse => partnerPollStatusResponse.status === PartnerPollStatus.IN_PROGRESS
    )
      .pipe(
        map(partnerPollStatusResponse => {
          if (rethrowError && partnerPollStatusResponse?.error) {
            throw new ApiError(partnerPollStatusResponse.error);
          }
          return partnerPollStatusResponse;
        }),
      );
  }

  getPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    return this.iamManagementPartnerSourceService.getPartnerHierarchyList();
  }

  getAllPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    return this.iamManagementPartnerSourceService.getAllPartnerHierarchyList();
  }

  getCurrentPartnerHierarchyList(): Observable<IPartnerHierarchyListItem[]> {
    return this.iamManagementPartnerSourceService.getCurrentPartnerHierarchyList();
  }

  createSmartCityPartner(smartCityPartner: ISmartCityPartner): Observable<AAGUID> {
    return this.iamManagementPartnerSourceService.createSmartCityPartner(smartCityPartner)
      .pipe(
        map(({ sagaId }) => sagaId),
      );
  }

  getCheckoutSurveySettings(): Observable<IPartnerOrganizerCheckoutSurveySettings> {
    return this.iamManagementPartnerSourceService.getCheckoutSurveySettings();
  }

  saveCheckoutSurveySettings(settings: IPartnerOrganizerCheckoutSurveySettings): Observable<PartnerOrganizerConfig> {
    return this.iamManagementPartnerSourceService.saveCheckoutSurveySettings(settings)
      .pipe(
        map(iSettings => new PartnerOrganizerConfig(iSettings))
      );
  }

  /**
   * PARTNER EDIT V2
   */

  getPartnerEditDetail(partnerId: AAGUID): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.getPartnerEditDetail(partnerId);
  }

  savePartnerEditGeneralData(partnerId: AAGUID, generalData: IPartnerEditGeneralData): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerEditGeneralData(partnerId, generalData);
  }

  addPartnerEditGeneralDataContact(partnerId: AAGUID, generalDataContact: IPartnerEditGeneralDataContact): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.addPartnerEditGeneralDataContact(partnerId, generalDataContact);
  }

  updatePartnerEditGeneralDataContact(partnerId: AAGUID, contactId: AAGUID, generalDataContact: IPartnerEditGeneralDataContact): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.updatePartnerEditGeneralDataContact(partnerId, contactId, generalDataContact);
  }

  updatePartnerEditGeneralDataContactsOrder(partnerId: AAGUID, contactIds: IContactIds): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.updatePartnerEditGeneralDataContactsOrder(partnerId, contactIds);
  }

  deletePartnerEditGeneralDataContact(partnerId: AAGUID, contactId: AAGUID): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.deletePartnerEditGeneralDataContact(partnerId, contactId);
  }

  savePartnerEditGeneralSettings(partnerId: AAGUID, generalSettings: IPartnerEditGeneralSettings): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerEditGeneralSettings(partnerId, generalSettings);
  }

  savePartnerEditWebSettings(partnerId: AAGUID, websiteSettings: IPartnerEditWebSettings): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerEditWebSettings(partnerId, new PartnerOrganizerWebSettingsWithDefaults(websiteSettings));
  }

  savePartnerSalesSettings(partnerId: AAGUID, salesSettings: IPartnerEditSalesSettings): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerSalesSettings(partnerId, salesSettings);
  }

  savePartnerLegalEntity(partnerId: AAGUID, legalEntity: IPartnerEditLegalEntity): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerLegalEntity(partnerId, legalEntity);
  }

  savePartnerFeatureSettings(partnerId: AAGUID, featureSettings: Partial<IPartnerEditFeatureSettings>): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerFeatureSettings(partnerId, featureSettings);
  }

  savePartnerInvoicingSettings(partnerId: AAGUID, invoicingSettings: Partial<IPartnerEditInvoiceSettings>): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerInvoicingSettings(partnerId, invoicingSettings);
  }

  savePartnerDefaultSettings(partnerId: AAGUID, payload: Partial<IPartnerOrganizerDefaultSettings>): Observable<IPartnerEditDetail> {
    return this.iamManagementPartnerSourceService.savePartnerDefaultSettings(partnerId, payload);
  }

  saveNTAKSettings(partnerId: AAGUID, settings: IPartnerSettingsNTAK): Observable<IPartnerEditDetail> {
    const { certFile, keyFile, regNumber, isEditMode } = settings;
    const formData = new FormData();

    if (!regNumber) {
      // empty form data to remove NTAK settings
      return this.iamManagementPartnerSourceService.updateNTAKSettings(partnerId, formData);
    }

    formData.append('regNumber', regNumber);
    certFile && formData.append('certFile', certFile);
    keyFile && formData.append('keyFile', keyFile);

    const method: keyof IamManagementPartnerSourceService = isEditMode ? 'updateNTAKSettings' : 'createNTAKSettings';
    return this.iamManagementPartnerSourceService[method](partnerId, formData);
  }

}
