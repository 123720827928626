import { Address } from '@interticket/core';
import { ICompany } from '../../interfaces/company/company.interface';

export class Company implements ICompany {

  readonly name: string;
  readonly longName: string;
  readonly registrationNumber: string;
  readonly seat: Address;

  constructor(iCompany: ICompany) {
    this.name = iCompany?.name || '';
    this.longName = iCompany?.longName || '';
    this.registrationNumber = iCompany?.registrationNumber || '';
    this.seat = iCompany?.seat ? new Address(iCompany.seat) : null;
  }

}
