import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'input-group-button',
  templateUrl: './input-group-button.component.html',
  styleUrls: ['./input-group-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputGroupButtonComponent {

  @Input() readonly placeholder: string = '';
  @Input() readonly tooltipText: string;
  @Input() readonly buttonText: string;
  @Input() readonly buttonLabel: string;
  @Input() readonly isButtonDisabled: boolean | false;
  @Input() readonly apiKeyControl: FormControl;
  @Input() readonly buttonClicked: boolean;
  @Input() readonly isInputReadonly: boolean;
  @Input() readonly noGenerateButton: boolean;
  @Input() readonly type: string;
  @Input() readonly matTooltipPosition = 'right';
  @Output() readonly onClick: EventEmitter<void> = new EventEmitter();
  @Output() readonly onSecondaryButtonClick: EventEmitter<void> = new EventEmitter();

  readonly matTooltipHideDelay = 1500;

  @ViewChild('tooltip') tooltip: MatTooltip;

  onClickGenerateButton(): void {
    this.onClick.emit();
  }

  onClickButton(event: Event): void {
    event.stopPropagation();
    this.onSecondaryButtonClick.emit();

    if (this.tooltip._isTooltipVisible()) {
      this.tooltip.hide();
    }
    this.tooltip.show();
  }

}
