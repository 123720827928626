import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { TranslateService } from '@interticket/core';
import { slideInRight } from '@ui-kit/animations';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, startWith, tap } from 'rxjs/operators';
import { IPageTitle } from '../../interfaces/page-title.interface';

@Component({
  selector: 'ui-kit-page-title',
  templateUrl: './ui-kit-page-title.component.html',
  styleUrls: ['./ui-kit-page-title.component.scss'],
  animations: [slideInRight],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitPageTitleComponent implements OnInit {

  private readonly ROUTE_DATA_PAGE_TITLE = 'pageTitle';
  private readonly inputTitle$: BehaviorSubject<IPageTitle> = new BehaviorSubject(null);

  title$: Observable<IPageTitle>;
  titleChange$: Subject<'hide' | 'show'> = new BehaviorSubject('hide');

  @Input() set title(pageTitle: IPageTitle) {
    this.inputTitle$.next(pageTitle);
  }

  get title() {
    return this.inputTitle$.getValue();
  }

  @Input() subtitle: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.title) {
      this.title$ = this.getTitleStream();
    } else {
      this.title$ = this.getTitleStreamByRoute();
    }
  }

  private getTitleStream(): Observable<IPageTitle> {
    return merge(
      this.translate.onLangChanged,
      this.inputTitle$,
    )
      .pipe(
        mapTo(this.title),
        tap(title => this.titleService.setTitle(this.translate.get(title.label))),
        tap(() => this.triggerTitleChange()),
      );
  }

  private getTitleStreamByRoute(): Observable<IPageTitle> {
    return merge(
      this.translate.onLangChanged,
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
    )
      .pipe(
        startWith(true),
        map(() => this.getPageTitle()),
        distinctUntilChanged(),
        tap(title => this.titleService.setTitle(this.translate.get(title?.label))),
        tap(() => this.triggerTitleChange()),
      );
  }

  /**
   * Returns page title
   * @link https://toddmotto.com/dynamic-page-titles-angular-2-router-events
   */
  private getPageTitle(): IPageTitle {
    let newRoute = this.activatedRoute.root;

    while (newRoute.firstChild) {
      const child = newRoute.firstChild;
      if (child.outlet === PRIMARY_OUTLET) {
        newRoute = child;
      }
    }

    return newRoute.snapshot.data[this.ROUTE_DATA_PAGE_TITLE] || null;
  }

  private triggerTitleChange(): void {
    this.titleChange$.next('hide');
    setTimeout(() => this.titleChange$.next('show'));
  }

}
