import { IType } from '@shared/interfaces/type/type.interface';

export class Type<T> implements IType<T> {

  readonly name: T;
  readonly label: string;

  constructor(iType: IType<T>) {
    this.name = iType.name;
    this.label = iType.label;
  }

}

export class Item<T> {

  readonly value: T;
  readonly label: string;

  constructor(iType: IType<T>) {
    this.value = iType.name;
    this.label = iType.label;
  }

}
