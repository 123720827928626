import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { NotifierService } from '@core/services/notification/notifier.service';
import { AAGUID, AppError, showErrorAnimation } from '@interticket/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, take, takeUntil } from 'rxjs/operators';
import { IChipDropdownConfig } from '../../interfaces/chip-dropdown/chip-dropdown-config.interface';
import { IChipDropdownOption } from '../../interfaces/chip-dropdown/chip-dropdown-item.interface';

@Component({
  selector: 'checkbox-chip-dropdown',
  templateUrl: './checkbox-chip-dropdown.component.html',
  styleUrls: ['./checkbox-chip-dropdown.component.scss'],
  animations: [showErrorAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxChipDropdownComponent<ListItem> implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();
  private readonly charLimit = 3;
  private readonly searchDelay = 400;

  readonly chipDropdownOptions$ = new BehaviorSubject<IChipDropdownOption[]>([]);
  readonly isLoadingOptionsList$ = new Subject<boolean>();
  readonly isLoading$ = new Subject<boolean>();
  readonly showError$ = new Subject<boolean>();

  searchTextControl: FormControl<string | IChipDropdownOption>;
  hasPermissionToEdit: boolean;
  placeholder: string;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipGrid') chipGrid: MatChipGrid;

  @Input() filterKey: string;
  @Input() inputPattern: string;
  @Input() isChipsInside: boolean;
  @Input() isChipClosable: boolean;
  @Input() isChipCreatable: boolean;
  @Input() chipCreatablePermissions: string[];
  @Input() chipEditablePermissions: string[];
  @Input() chipDropdownConfig: IChipDropdownConfig<ListItem>;
  @Input() label: string;
  @Input() itemIdsControl: FormControl<AAGUID[]>;
  @Input() alreadySelectedChips: string[];
  @Input()
  set listItems(listItems: ListItem[]) {
    if (listItems) {
      this.updateDropdownOptions(listItems);
      this.hideAllDropdownItems();
    }
  }

  @Input()
  set isLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);

    if (this.searchTextControl) {
      this.searchTextControl[isLoading ? 'disable' : 'enable']({ emitEvent: false });
    }
  }

  @Output()
  readonly emitListItems: EventEmitter<string[]> = new EventEmitter();

  @Output()
  readonly emitNewChip: EventEmitter<string> = new EventEmitter();

  constructor(
    private notifier: NotifierService,
    private ngxPermissionsService: NgxPermissionsService
  ) { }

  ngOnInit(): void {
    this.searchTextControl = new FormControl<string | IChipDropdownOption>('', [
      Validators.minLength(this.charLimit), Validators.pattern(this.inputPattern),
    ]);

    this.setPlaceholder(this.itemIdsControl.value);
    this.subscribeToSearchChanges();
    this.subscribeToIdsControlValueChanges();

    if (this.alreadySelectedChips) {
      this.alreadySelectedChips.forEach((item) => this.itemIdsControl.value.push(item));
    }

    this.ngxPermissionsService.hasPermission(this.chipEditablePermissions).then((hasPermission: boolean) => {
      if (hasPermission) {
        this.hasPermissionToEdit = hasPermission;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToSearchChanges(): void {
    this.searchTextControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.searchDelay),
        takeUntil(this.destroy$)
      ).subscribe({
        next: (searchText) => {
          this.showError$.next(false);
          if (typeof searchText === 'string') {
            this.fetchOptions(searchText);
          }
        },
      });
  }

  private subscribeToIdsControlValueChanges(): void {
    this.itemIdsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (itemIds) => {
          this.onIdsControlChange();
          this.setPlaceholder(itemIds);
        },
      });
  }

  private fetchOptions(searchText: string): void {
    this.isLoadingOptionsList$.next(true);
    this.searchTextControl.disable({ emitEvent: false });
    this.chipDropdownConfig.getList(searchText)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingOptionsList$.next(false);
          this.searchTextControl.enable({ emitEvent: false });
          this.searchInput.nativeElement.focus();
        })
      )
      .subscribe({
        next: (items: ListItem[]) => {
          this.hideAllDropdownItems();
          this.updateDropdownOptions(items);

          if (!items.length) {
            this.showError$.next(true);
          }
        },
        error: (error: AppError) => this.notifier.errorFetch(error),
      });
  }

  private hideAllDropdownItems(): void {
    this.chipDropdownOptions$.getValue().forEach((option: IChipDropdownOption) => option.isVisible = true);
  }

  private updateDropdownOptions(items: ListItem[]): void {
    const itemIds: string[] = this.itemIdsControl.value;

    this.chipDropdownOptions$.next(this.getDropdownOptions(items, itemIds));
  }

  private getDropdownOptions(items: ListItem[], itemIds: string[]): IChipDropdownOption[] {
    const newOptions: IChipDropdownOption[] = items.map((item: ListItem) => {
      const props = this.chipDropdownConfig.getProps(item);

      return {
        ...props,
        isSelected: itemIds?.includes(props[this.filterKey]) || this.alreadySelectedChips?.includes(props[this.filterKey]),
        isVisible: true,
      };
    });

    const currentOptions: IChipDropdownOption[] = this.chipDropdownOptions$.getValue().filter((currentOption: IChipDropdownOption) =>
      currentOption.isSelected && newOptions.findIndex((newOption: IChipDropdownOption) => currentOption.id === newOption.id) === -1
    );

    return [...newOptions, ...currentOptions].sort(
      (optionA: IChipDropdownOption, optionB: IChipDropdownOption) => optionA.name < optionB.name ? -1 : 1
    );
  }

  private onIdsControlChange(): void {
    this.showError$.next(false);
    this.hideAllDropdownItems();
    this.chipGrid.errorState = !!this.itemIdsControl.errors && !!this.itemIdsControl.touched;

    if (this.itemIdsControl.value && this.itemIdsControl.value.length) {
      this.chipDropdownOptions$.getValue().forEach(option => option.isSelected = this.itemIdsControl.value.includes(option[this.filterKey]));
    } else {
      this.chipDropdownOptions$.getValue().forEach(option => option.isSelected = false);
    }
  }

  private setPlaceholder(itemIds: AAGUID[]): void {
    this.placeholder = !itemIds?.length || !this.isChipsInside ? this.label : '';
  }

  private patchItemIds(): void {
    const selectedIds: string[] = this.chipDropdownOptions$.getValue().reduce((ids: string[], option: IChipDropdownOption) =>
      option.isSelected ? [...ids, option[this.filterKey]] : ids, []);
    this.itemIdsControl.patchValue(selectedIds, { emitEvent: true });

    if (selectedIds && !!selectedIds.length) {
      this.chipGrid.errorState = false;
    }

    this.emitListItems.emit(selectedIds);
  }

  toggleChipOption(option: IChipDropdownOption): void {
    this.searchInput.nativeElement.blur();
    option.isSelected = !option.isSelected;
    this.patchItemIds();
    this.searchInput.nativeElement.focus();
  }

  displayFn(option: IChipDropdownOption): string {
    return option ? option.name : undefined;
  }

  trackByFn(index: number): number {
    return index;
  }

  optionClicked(event, option: IChipDropdownOption): void {
    event.stopPropagation();
    this.toggleChipOption(option);
  }

  addTag(event: string): void {
    const value = event;
    if ((value || '').trim()) {
      if (!this.chipDropdownOptions$.getValue().find(t => t.name.toLowerCase() === value.toLowerCase())) {
        this.emitNewChip.emit(value);
      }
    }
  }

}
