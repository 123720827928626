import { IContact } from '../../interfaces/contact/contact.interface';

export class Contact implements IContact {

  readonly firstName: string;
  readonly lastName: string;
  readonly phone: string;
  readonly email: string;
  readonly jobDescription: string;
  readonly address: string;
  readonly isPublic: boolean;

  constructor(iContact: IContact) {
    this.firstName = iContact.firstName;
    this.lastName = iContact.lastName;
    this.phone = iContact.phone;
    this.email = iContact.email;
    this.jobDescription = iContact.jobDescription;
    this.address = iContact.address;
    this.isPublic = iContact.isPublic;
  }

}
