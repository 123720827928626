export const CrmIcon = `<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.0625 22.3125H25.7812L28.0078 17.0977C28.1836 16.6875 28.0664 16.2188 27.7148 15.9844C27.3633 15.6914 26.8945
15.75 26.543 15.9844L19.043 22.5469C18.75 22.8398 18.6328 23.25 18.8086 23.6016C18.9258 23.9531 19.2773 24.1875 19.6875
24.1875H22.9102L20.6836 29.4609C20.5078 29.8711 20.625 30.3398 20.9766 30.5742C21.1523 30.6914 21.3281 30.75 21.5625
30.75C21.7383 30.75 21.9727 30.6914 22.1484 30.5742L29.6484 24.0117C29.9414 23.7188 30.0586 23.3086 29.8828 22.957C29.7656
22.6055 29.4141 22.3125 29.0625 22.3125ZM8.4375 20.9062C6.85547 20.9062 5.625 22.1953 5.625 23.7188C5.625 25.3008 6.85547
26.5312 8.4375 26.5312C9.96094 26.5312 11.25 25.2422 11.25 23.7188C11.25 22.1953 9.96094 20.9062 8.4375 20.9062ZM5.91797
13.2305C6.32812 13.6406 6.91406 13.9336 7.5 13.9336C8.02734 13.9336 8.61328 13.6406 9.02344 13.2305L13.5938 8.66016C14.5312
7.72266 15 6.49219 14.9414 5.14453C14.8828 3.85547 14.2969 2.625 13.3008 1.80469C11.6016 0.457031 9.19922 0.457031 7.5
1.74609C5.74219 0.457031 3.33984 0.457031 1.69922 1.80469C0.644531 2.625 0.0585938 3.85547 0 5.14453C-0.0585938 6.49219
0.410156 7.72266 1.34766 8.66016L5.91797 13.2305ZM3.45703 3.97266C4.33594 3.26953 5.44922 3.62109 6.03516 4.20703L7.5
5.67188L8.90625 4.20703C9.66797 3.50391 10.7812 3.38672 11.4844 3.97266C12.0703 4.44141 12.5977 5.67188 11.6016 6.66797L7.5
10.8281L3.33984 6.66797C2.8125 6.14062 2.40234 4.85156 3.45703 3.97266ZM28.2422 0.75C28.125 0.75 28.0664 0.808594 28.0078
0.808594L19.2773 2.15625C18.3984 2.27344 17.7539 3.03516 17.7539 3.97266V10.2422C17.4609 10.1836 17.168 10.125 16.875
10.125C14.7656 10.125 13.125 11.4141 13.125 12.9375C13.125 14.5195 14.8242 15.75 16.875 15.75C18.8672 15.75 20.625 14.5195
20.625 12.9961L20.5664 4.79297L27.1289 3.73828V8.36719C26.8359 8.30859 26.543 8.25 26.25 8.25C24.1406 8.25 22.5 9.53906
22.5 11.0625C22.5 12.6445 24.1992 13.8164 26.25 13.8164C28.2422 13.8164 29.9414 12.5859 29.9414 11.0625V2.625C29.9414
1.62891 29.1797 0.75 28.2422 0.75ZM14.9414 17.625H13.3594L12.9492 16.8047C12.7148 16.1602 12.1289 15.75 11.4258
15.75H5.39062C4.6875 15.75 4.10156 16.1602 3.86719 16.8047L3.45703 17.625H1.875C0.820312 17.625 0 18.4453 0 19.5V28.875C0
29.9297 0.820312 30.75 1.875 30.75H14.9414C15.9961 30.75 16.8164 29.9297 16.8164 28.875V19.5C16.8164 18.4453 15.9961
17.625 14.9414 17.625ZM14.0039 27.9375H2.8125V20.4375H5.21484L6.15234 18.5625H10.6641L11.6016 20.4375H14.0039V27.9375Z" fill="currentColor"/>
</svg>`;
