import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AAGUID } from '@interticket/core';
import { SelectGroupShowModes } from '../../enums/select-group-show-modes.enum';
import { RegionsFeatureSelectGroup } from '../../models/regions-feature-select-group.model';
import { RegionsFeatureSelectItem } from '../../models/regions-feature-select-item.model';
import { SelectGroupService } from '../../services/select-group.service';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
})
export class SelectGroupComponent {

  readonly showModes = SelectGroupShowModes;

  hasNoFilter = true;

  @Input() list: RegionsFeatureSelectGroup[] = [];
  @Input() disableEdit: boolean;
  @Input() showMode: SelectGroupShowModes = SelectGroupShowModes.ALL;
  @Input() featuresControl: UntypedFormControl;
  @Output() featureToggled: EventEmitter<AAGUID[]> = new EventEmitter();

  @Input() set searchText(text: string) {
    if (this.list) {
      const searchText: string = (text || '').toLowerCase().trim();
      this.list.forEach((group: RegionsFeatureSelectGroup) => group.filter(searchText));
      this.hasNoFilter = !searchText;
    }
  }

  constructor(
    private selectGroupService: SelectGroupService
  ) { }

  toggleGroup(group: RegionsFeatureSelectGroup): void {
    this.showMode === SelectGroupShowModes.SELECTED || group.selected ? group.deselect(this.hasNoFilter) : group.select(this.hasNoFilter);
    this.emitSelectedFeatures();
  }

  toggleItem(item: RegionsFeatureSelectItem, group: RegionsFeatureSelectGroup): void {
    item.selected ? item.deselect() : item.select();
    group.updateState();
    this.emitSelectedFeatures();
  }

  emitSelectedFeatures(): void {
    this.featureToggled.emit(this.selectGroupService.getSelectedFeatures(this.list));
  }

}
