import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventType } from '@services/product-catalog';
import { UiKitPalette } from '@ui-kit/enums';

type UiKitEventTypeDisplayModes = 'normal' | 'sidebar' | 'event-list-item';

@Component({
  selector: 'ui-kit-event-type',
  templateUrl: './ui-kit-event-type.component.html',
  styleUrls: ['./ui-kit-event-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitEventTypeComponent {

  @Input() set eventType(type: EventType) {
    this._eventType = type?.toLowerCase() as EventType;
    this.icon = this.getIcon(this._eventType);
  }

  @Input() readonly displayMode: UiKitEventTypeDisplayModes = 'normal';
  @Input() readonly infoMessageLabel: string;
  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;

  readonly eventTypes = EventType;

  _eventType: EventType = EventType.NORMAL;
  icon: string;

  private readonly defaultIcon = 'circle';
  private readonly iconMap = {
    [EventType.NORMAL]: this.defaultIcon,
    [EventType.VIDEO]: 'video-circle',
    [EventType.LIVESTREAM]: 'livestream',
    [EventType.LIVESTREAM_VIDEO]: 'livestream',
  };

  getIcon(type: EventType): string {
    return this.iconMap[type] || this.defaultIcon;
  }

}
