import { AAGUID } from '@interticket/core';
import { IUiKitSelectOption } from '@ui-kit/form/select';
import { IOTRSTaxClassView } from '../interfaces/otrs-tax-class-view.interface';

export class OTRSUtil {

  static createTaxClassLabel(taxClass: IOTRSTaxClassView): string {
    if (!taxClass) {
      return '';
    }

    return `${taxClass.name}: ${taxClass.value}%`;
  }

  static createTaxDropdownOptions(taxClasses: IOTRSTaxClassView[]): IUiKitSelectOption<AAGUID>[] {
    return (taxClasses || [])
      .map(taxClass => ({
        value: taxClass.id,
        label: OTRSUtil.createTaxClassLabel(taxClass),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  static getDefaultTaxIdForDropdown(taxDropdownOptions: IUiKitSelectOption<AAGUID>[], defaultTaxId?: AAGUID): AAGUID | null {
    return (taxDropdownOptions.find(item => item.value === defaultTaxId) || taxDropdownOptions[0])?.value?.toString() || null;
  }

}
