<!-- HEADER -->
<header *ngIf="!hideHeader">
  <!-- HEADER CUSTOM CONTENT -->
  <ng-content select="[card-header]"></ng-content>

  <div class="titles">
    <!-- TITLE -->
    <h1 *ngIf="title else titleContent">
      <i class="title-disc" *ngIf="titleDisc"></i>
      <span>{{ title | translate }}</span>
    </h1>
    <ng-template #titleContent>
      <ng-content select="[card-title]"></ng-content>
    </ng-template>

    <!-- SUBTITLE -->
    <h2 *ngIf="subTitle else subTitleContent">{{ subTitle | translate }}</h2>
    <ng-template #subTitleContent>
      <ng-content select="[card-subtitle]"></ng-content>
    </ng-template>
  </div>

  <!-- ASIDE -->
  <ng-content select="[card-aside]"></ng-content>
</header>

<!-- CONTENT -->
<section>
  <ng-content select="[card-content]"></ng-content>
</section>

<!-- FOOTER -->
<footer>
  <ng-content select="[card-footer]"></ng-content>
</footer>
