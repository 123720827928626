import { AAGUID, IPlaceModuleConfig } from '@interticket/core';
import { IPartnerOrganizerDefaultSettings } from '../interfaces/partner-organizer-default-settings.interface';

export class PartnerOrganizerDefaultSettings implements IPartnerOrganizerDefaultSettings {

  readonly defaultPlaceSettings?: IPlaceModuleConfig[];
  readonly defaultSubCategories: AAGUID[];
  readonly defaultTaxId: AAGUID;

  constructor(iConfig: IPartnerOrganizerDefaultSettings) {
    this.defaultPlaceSettings = Array.isArray(iConfig?.defaultPlaceSettings) ? iConfig.defaultPlaceSettings : [];
    this.defaultSubCategories = Array.isArray(iConfig?.defaultSubCategories) ? iConfig.defaultSubCategories : [];
    this.defaultTaxId = iConfig?.defaultTaxId || null;
  }

}
