import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-copy-content',
  templateUrl: './ui-kit-copy-content.component.html',
  styleUrls: ['./ui-kit-copy-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitCopyContentComponent {

  readonly palette = UiKitPalette;
  readonly size = UiKitSize;

  @ViewChild('copiedHint') copiedHint: MatTooltip;

  @Input() label = '';
  @Input() value: string;
  @Input() valueToDisplay: string;
  @Input() suffix: string;
  @Input() iconSize = 14;
  @Input() button = true;
  @Input() isEllipsis = false;
  @Input() showValue = true;
  @Input() @HostBinding('class.disable-margin') disableMargin = false;
  @Input() valueTooltip = '';

  showTooltip(): void {
    this.copiedHint.disabled = false;
    this.copiedHint.show();
    setTimeout(() => this.copiedHint.disabled = true, 1000);
  }

}
