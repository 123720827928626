import { AAGUID, FilterParams } from '@interticket/core';
import { UserStatus } from '../enums/user-status.enum';
import { IUserFilter } from '../interfaces/user-filter.interface';

export class UserFilter extends FilterParams implements IUserFilter {

  readonly searchText: string;
  readonly createdDateTimeFrom: string;
  readonly createdDateTimeTo: string;
  readonly userStatus: UserStatus;
  readonly partnerId: string;
  readonly partnerIds: string[];
  readonly onlyAdmins: boolean;
  readonly ids: AAGUID[];

  constructor(iFilter: IUserFilter) {
    super();

    this.searchText = iFilter.searchText || null;
    this.createdDateTimeFrom = iFilter.createdDateTimeFrom || null;
    this.createdDateTimeTo = iFilter.createdDateTimeTo || null;
    this.userStatus = iFilter.userStatus || null;
    this.partnerId = iFilter.partnerId || null;
    this.partnerIds = Array.isArray(iFilter.partnerIds) && iFilter.partnerIds.length ? iFilter.partnerIds : null;
    this.onlyAdmins = iFilter.onlyAdmins || null;
    this.ids = Array.isArray(iFilter.ids) && iFilter.ids.length ? iFilter.ids : null;
  }

}
