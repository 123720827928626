import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AAGUID } from '@interticket/core';

@Component({
  selector: 'ui-kit-image-preview',
  templateUrl: './ui-kit-image-preview.component.html',
  styleUrls: ['./ui-kit-image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitImagePreviewComponent {

  @Input() readonly imageId: AAGUID;
  @Input() readonly fallbackImageId: AAGUID;
  @Input() readonly fill: boolean = false;
  @Input() readonly imageBorder: boolean = true;
  @Input() @HostBinding('style.--preview-img-size') readonly imgSize: string = '100%';

  get imageIdToShow(): AAGUID {
    return this.imageId || this.fallbackImageId;
  }

}
