<div class="row align-spaced text-center">
  <div class="columns small-12">
    <h2 mat-dialog-title>{{ data.title | translate }}</h2>
    <p *ngIf="data?.description" [innerHTML]="data?.description | translate"></p>
  </div>

  <div class="button-container">
    <button class="button alert" [mat-dialog-close]="false">{{ 'label_no' | translate }}</button>
    <button class="button success" [mat-dialog-close]="true">{{ 'label_yes' | translate }}</button>
  </div>
</div>
