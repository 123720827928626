<!-- LABEL -->
<div>{{ label | translate }}</div>

<!-- CONTENT -->
<div class="value">

  <!-- VALUE -->
  <span
    *ngIf="showValue"
    [class.text-ellipsis]="isEllipsis"
    [matTooltip]="valueTooltip"
    [matTooltipPosition]="'above'">
    <ng-container *ngIf="!suffix; else hasSuffix">{{ valueToDisplay || value }}</ng-container>
    <ng-template #hasSuffix>{{ valueToDisplay || value }} {{ suffix | translate }}</ng-template>
  </span>

  <!-- BUTTON -->
  <ui-kit-button-icon
    *ngIf="button; else icon"
    #copiedHint="matTooltip"
    [matTooltip]="'stemx_label_copied_to_clipboard_long' | translate"
    [matTooltipDisabled]="true"
    [matTooltipPosition]="'above'"
    [palette]="palette.PRIMARY"
    [size]="size.SMALL"
    [icon]="'copy'"
    [cdkCopyToClipboard]="value"
    (click)="showTooltip()">
  </ui-kit-button-icon>

  <!-- ICON -->
  <ng-template #icon>
    <ui-kit-icon
      #copiedHint="matTooltip"
      [matTooltip]="'stemx_label_copied_to_clipboard_long' | translate"
      [matTooltipDisabled]="true"
      [matTooltipPosition]="'above'"
      [size]="iconSize"
      [icon]="'copy'"
      [cdkCopyToClipboard]="value"
      (click)="showTooltip()">
    </ui-kit-icon>
  </ng-template>

  <!-- HIDDEN INPUT -->
  <input type="hidden" [(ngModel)]="value">

</div>
