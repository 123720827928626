export const Calculate = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_12263_45757)">
    <g clip-path="url(#clip1_12263_45757)">
      <rect x="5.12805" y="1.10815" width="2.41875" height="9.675" rx="1.20937" fill="currentColor"/>
      <rect x="1.5" y="7.15503" width="2.41875" height="9.675" rx="1.20937" transform="rotate(-90 1.5 7.15503)" fill="currentColor"/>
    </g>
    <g clip-path="url(#clip2_12263_45757)">
      <rect x="2.04974" y="14.9187" width="2.41876" height="9.67503" rx="1.20938" transform="rotate(-45 2.04974 14.9187)" fill="currentColor"/>
      <rect x="3.76025" y="21.7603" width="2.41876" height="9.67503" rx="1.20938" transform="rotate(-135 3.76025 21.7603)" fill="currentColor"/>
    </g>
    <rect x="13.5" y="4.5" width="9" height="2.505" rx="1.2525" fill="currentColor"/>
    <g clip-path="url(#clip3_12263_45757)">
      <rect x="13.5" y="13.5" width="9" height="2.5" rx="1.25" fill="currentColor"/>
      <rect x="13.4998" y="18.4998" width="9" height="2.5" rx="1.25" fill="currentColor"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_12263_45757">
      <rect width="24" height="24" fill="transparent"/>
    </clipPath>
    <clipPath id="clip1_12263_45757">
      <rect width="9.675" height="9.675" fill="transparent" transform="translate(1.5 1.10815)"/>
    </clipPath>
    <clipPath id="clip2_12263_45757">
      <rect width="9.67503" height="9.67503" fill="transparent" transform="translate(-0.515686 17.4844) rotate(-45)"/>
    </clipPath>
    <clipPath id="clip3_12263_45757">
      <rect width="9" height="7.5" fill="transparent" transform="translate(13.5 13.5)"/>
    </clipPath>
  </defs>
</svg>`;
