import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule } from '@shared/shared.module';
import { UiKitControlsModule } from '../controls/ui-kit-controls.module';
import { UiKitIconsModule } from '../icons/ui-kit-icons.module';
import { UiKitChipListComponent } from './components/ui-kit-chip-list/ui-kit-chip-list.component';
import { UiKitChipOptionComponent } from './components/ui-kit-chip-option/ui-kit-chip-option.component';
import { UiKitChipRippleDisableDirective } from './directives/ui-kit-chip-ripple-disable.directive';

const components = [
  UiKitChipOptionComponent,
  UiKitChipListComponent,
];

const directives = [
  UiKitChipRippleDisableDirective,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UiKitIconsModule,
    UiKitControlsModule,

    // Material imports
    MatChipsModule,
    MatRippleModule,
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [
    ...components,
    ...directives,
  ],
})
export class UiKitChipsModule { }
