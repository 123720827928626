import { FilterParams } from '@interticket/core';
import { IPartnerByNameFilter } from '../interfaces/partner-by-name-filter.interface';

export class PartnerByNameFilter extends FilterParams implements IPartnerByNameFilter {

  readonly name: string;

  constructor(iFilter: IPartnerByNameFilter) {
    super();

    this.name = iFilter.name || null;
  }

}
