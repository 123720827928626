export const Gear = `<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1193_5664)">
  <path d="M10.9067 10.7358L9.98076 11.2141C9.99856 11.3115 10.0075 11.4001 10.0075 11.5064C10.0075 12.8882 8.92125 13.9512 7.54121 13.9512C6.16117 13.9512 5.06604 12.8882 5.06604 11.5064C5.06604 10.1246 6.15227 9.0528 7.54121 9.0528C7.61244 9.0528 7.68366 9.0528 7.74599 9.06165L8.11993 8.11387C7.93296 8.07844 7.73708 8.06958 7.54121 8.06958C5.60916 8.06958 4.07776 9.58427 4.07776 11.5064C4.07776 13.4286 5.60916 14.9432 7.54121 14.9432C9.47326 14.9432 10.9958 13.4286 10.9958 11.5064C10.9958 11.2407 10.969 10.9927 10.9067 10.7358Z" fill="currentColor"/>
  <path d="M14.1921 13.1185V12.1973L12.4381 12.6756L12.3936 12.9059C12.2512 13.2337 12.1087 13.5614 11.9663 13.898L11.8238 14.1283L12.6696 15.6696L11.7348 16.6085L10.1767 15.7759L9.93627 15.9087C9.60684 16.1036 9.27741 16.2365 8.94798 16.3339L8.71649 16.3871L8.20009 18.0612H6.87348L6.35708 16.3871L6.12559 16.3339C5.79616 16.1833 5.46673 16.0505 5.1373 15.9087L4.89691 15.7759L3.3477 16.6085L2.40394 15.6696L3.24977 14.1283L3.10731 13.898C2.92034 13.5614 2.77788 13.2337 2.67994 12.9059L2.63543 12.6756L0.943768 12.153V10.8509L2.54639 10.3726L2.63543 10.1423C2.73336 9.76142 2.87582 9.43368 3.06279 9.10594L3.20525 8.87564L2.40394 7.32552L3.3477 6.38659L4.85239 7.2458L5.08388 7.09522C5.41331 6.91806 5.74274 6.76748 6.12559 6.67004L6.35708 6.59032L6.82896 4.98706H5.93861L5.6537 5.87284C5.36879 5.92599 5.1373 6.02342 4.89691 6.15629L3.67713 5.50081C3.39222 5.36795 3.01828 5.41224 2.77788 5.64254L1.64714 6.76748C1.41565 6.99778 1.36223 7.37867 1.50469 7.65326L2.11903 8.84021C2.02109 9.07051 1.93205 9.35396 1.83411 9.57541L0.516401 10.0094C0.23149 10.098 0 10.3726 0 10.7003V12.2948C0 12.6225 0.23149 12.9059 0.560918 13.0122L1.88754 13.4197L2.16354 14.1283L1.50469 15.3418C1.36223 15.6253 1.41565 15.9973 1.64714 16.2365L2.77788 17.3703C3.01828 17.6006 3.39222 17.6537 3.67713 17.5031L4.89691 16.8477L5.60918 17.1223L6.02765 18.4864C6.12559 18.7698 6.4105 19.0001 6.73993 19.0001H8.34255C8.67198 19.0001 8.94798 18.7698 9.04592 18.4864L9.47329 17.1223L10.1767 16.8477L11.4053 17.5031C11.6813 17.6537 12.0642 17.6006 12.2957 17.3703L13.4264 16.2365C13.6668 15.9973 13.7113 15.6253 13.5689 15.3418L12.91 14.1283L13.1949 13.4197L14.1921 13.1185Z" fill="currentColor"/>
  <path d="M18.5549 4.7035L17.5131 4.37576L17.2817 3.81772L17.807 2.85221C17.9227 2.62191 17.8871 2.3296 17.7001 2.15245L16.8009 1.25781C16.6139 1.06294 16.3112 1.03636 16.0886 1.15152L15.1181 1.65641L14.5572 1.43496L14.2189 0.398601C14.1476 0.177156 13.9251 0 13.6669 0H12.3937C12.1355 0 11.904 0.177156 11.8684 0.44289L11.53 1.47925C11.3074 1.51469 11.1205 1.59441 10.9335 1.70956L9.96301 1.17809C9.74042 1.06294 9.44661 1.10723 9.25963 1.28438L8.36038 2.17902C8.17341 2.37389 8.1378 2.6662 8.24464 2.88765L8.73433 3.81772C8.70762 3.89744 8.67201 3.98601 8.6453 4.08345C8.59187 4.1986 8.55626 4.31375 8.51174 4.42005L7.46113 4.75664C7.3632 4.78322 7.26526 4.85408 7.19403 4.9338C7.105 5.04009 7.05157 5.15524 7.05157 5.30583V6.57249C7.05157 6.82051 7.23855 7.05082 7.49675 7.13054L8.54736 7.46713L8.76994 8.02517L8.6453 8.24662L8.24464 8.98182C8.24464 8.98182 8.20903 9.07925 8.20012 9.1324C8.1556 9.31841 8.20903 9.531 8.36038 9.69044L9.25963 10.5762C9.4288 10.7445 9.6781 10.7977 9.88288 10.7268C9.90959 10.7091 9.9363 10.7002 9.96301 10.6914L10.7643 10.2573L10.9335 10.1776L11.4944 10.3991L11.8327 11.4709C11.904 11.6923 12.1355 11.8872 12.3937 11.8872H13.6669C13.8538 11.8872 14.023 11.7897 14.1298 11.6392C14.1654 11.586 14.2011 11.5329 14.2189 11.4709L14.5572 10.3991L14.9222 10.2485L15.1181 10.1776L16.0886 10.6914C16.3112 10.7977 16.6139 10.7622 16.8009 10.5762L17.7001 9.69044C17.8871 9.49557 17.9227 9.20326 17.807 8.98182L17.2817 8.02517L17.5131 7.46713L18.5905 7.13054C18.8131 7.05082 19 6.82051 19 6.57249V5.30583C19 5.04009 18.8131 4.78322 18.5549 4.7035ZM13.4443 7.8303C13.3107 7.85688 13.1683 7.87459 13.0258 7.87459C12.8121 7.87459 12.6073 7.84802 12.4204 7.77716C11.8684 7.60886 11.4321 7.2014 11.2184 6.66993C11.1294 6.43963 11.0759 6.20047 11.0759 5.93473C11.0759 5.82844 11.0848 5.72214 11.1027 5.61585C11.2451 4.69464 12.0375 3.98601 13.0258 3.98601C14.1209 3.98601 14.9846 4.85408 14.9846 5.93473C14.9846 6.88252 14.3346 7.64429 13.4443 7.8303Z" fill="currentColor"/>
</g>
<defs>
  <clipPath id="clip0_1193_5664">
    <rect width="19" height="19" fill="currentColor"/>
  </clipPath>
</defs>
</svg>`;
