import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TimeZone, Utils } from '@interticket/core';
import { DateObjectUnits, DateTime, DateTimeJSOptions, Info } from 'luxon';
import { IUiKitDateTimePickerModuleConfig } from '../interfaces/ui-kit-date-time-picker-module-config.interface';

const ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;

const range = (length: number, valueFunction: (i: number) => any): any[] => {
  const valuesArray = [];
  for (let index = 0; index < length; index++) {
    valuesArray[index] = valueFunction(index);
  }
  return valuesArray;
};

interface IOwlLuxonDateTimeAdapterOptions {
  useUtc?: boolean;
  zone?: TimeZone;
}

/**
 * Based on NativeDateTimeAdapter and MomentDateTimeAdapter
 */
export class LuxonDateTimeAdapter extends DateTimeAdapter<DateTime> {

  private localeData: {
    longMonths: string[];
    shortMonths: string[];
    narrowMonths: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
    dates: string[];
  };

  private options: IOwlLuxonDateTimeAdapterOptions = { useUtc: false };
  private dateTimeOptions: DateTimeJSOptions = {};

  constructor(
    config: IUiKitDateTimePickerModuleConfig,
    options?: IOwlLuxonDateTimeAdapterOptions
  ) {
    super();

    this.options.zone = config.timeZone;

    this.setLocale(config.locale);
    this.setOptions(options);
  }

  private setOptions(options?: IOwlLuxonDateTimeAdapterOptions): void {
    if (options) {
      this.options = {
        ...this.options,
        ...options,
      };
    }

    if (this.options.zone) {
      this.dateTimeOptions = {
        ...this.dateTimeOptions,
        zone: this.options.zone,
      };
    }

    if (this.options.useUtc) {
      this.dateTimeOptions = {
        ...this.dateTimeOptions,
        zone: 'utc',
      };
    }
  }

  setLocale(locale: string): void {
    super.setLocale(locale);
    const localeOpts = { locale: this.locale };
    this.localeData = {
      longMonths: Info.months('long', localeOpts),
      shortMonths: Info.months('short', localeOpts),
      narrowMonths: Info.months('narrow', localeOpts),
      longDaysOfWeek: Utils.shiftArray(Info.weekdays('long', localeOpts)),
      shortDaysOfWeek: Utils.shiftArray(Info.weekdays('short', localeOpts)),
      narrowDaysOfWeek: Utils.shiftArray(Info.weekdays('narrow', localeOpts)),
      dates: range(31, (i: number) => this.createDate(2018, 0, i + 1).toFormat('d')),
    };
  }

  getYear(date: DateTime): number {
    return date.year;
  }

  getMonth(date: DateTime): number {
    // Months in Luxon are 1-indexed instead of 0-indexed like in Moment and the native Date type.
    return date.month - 1;
  }

  getDay(date: DateTime): number {
    return date.weekday;
  }

  getDate(date: DateTime): number {
    return date.day;
  }

  getHours(date: DateTime): number {
    return date.hour;
  }

  getMinutes(date: DateTime): number {
    return date.minute;
  }

  getSeconds(date: DateTime): number {
    return date.second;
  }

  getTime(date: DateTime): number {
    return date.valueOf();
  }

  getNumDaysInMonth(date: DateTime): number {
    return date.daysInMonth;
  }

  differenceInCalendarDays(dateLeft: DateTime, dateRight: DateTime): number {
    const start = dateLeft.startOf('day');
    const end = dateRight.endOf('day');

    return Math.ceil(start.diff(end, 'days').days);
  }

  getYearName(date: DateTime): string {
    return date.toFormat('yyyy');
  }

  getMonthNames(style: 'long' | 'short' | 'narrow' = 'short'): string[] {
    switch (style) {
      case 'long': return this.localeData.shortMonths;
      case 'narrow': return this.localeData.narrowMonths;
      default: return this.localeData.shortMonths;
    }
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow' = 'narrow'): string[] {
    switch (style) {
      case 'short': return this.localeData.shortDaysOfWeek;
      case 'long': return this.localeData.longDaysOfWeek;
      default: return this.localeData.narrowDaysOfWeek;
    }
  }

  getDateNames(): string[] {
    return this.localeData.dates;
  }

  toIso8601(date: DateTime): string {
    return date.toISO();
  }

  isEqual(dateLeft: DateTime, dateRight: DateTime): boolean {
    return +dateLeft === +dateRight;
  }

  isSameDay(dateLeft: DateTime, dateRight: DateTime): boolean {
    if (dateLeft && dateRight) {
      return dateLeft.hasSame(dateRight, 'day');
    }
    return dateLeft === dateRight;
  }

  isValid(date: DateTime): boolean {
    return this.isDateInstance(date) && date.isValid;
  }

  invalid(): DateTime {
    return DateTime.invalid('invalid');
  }

  isDateInstance(obj: any): boolean {
    return obj && obj instanceof DateTime;
  }

  addCalendarYears(date: DateTime, amount: number): DateTime {
    return date.plus({ years: amount });
  }

  addCalendarMonths(date: DateTime, amount: number): DateTime {
    return date.plus({ months: amount });
  }

  addCalendarDays(date: DateTime, amount: number): DateTime {
    return date.plus({ days: amount });
  }

  setHours(date: DateTime, amount: number): DateTime {
    return date.set({ hour: amount });
  }

  setMinutes(date: DateTime, amount: number): DateTime {
    return date.set({ minute: amount });
  }

  setSeconds(date: DateTime, amount: number): DateTime {
    return date.set({ second: amount });
  }

  createDate(year: number, month: number, date: number, hours = 0, minutes = 0, seconds = 0): DateTime {
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    if (hours < 0 || hours > 23) {
      throw Error(`Invalid hours "${hours}". Hours has to be between 0 and 23.`);
    }
    if (minutes < 0 || minutes > 59) {
      throw Error(`Invalid minutes "${minutes}". Minutes has to between 0 and 59.`);
    }
    if (seconds < 0 || seconds > 59) {
      throw Error(`Invalid seconds "${seconds}". Seconds has to be between 0 and 59.`);
    }

    const iDateObject: DateObjectUnits = {
      year: year,
      month: month + 1, // Months in Luxon are 1-indexed instead of 0-indexed like in Moment and the native Date type.
      day: date,
      hour: hours,
      minute: minutes,
      second: seconds,
    };

    const iDateOptions: DateTimeJSOptions = {
      ...this.dateTimeOptions,
      locale: this.locale,
    };

    const dateTime: DateTime = DateTime.fromObject(iDateObject, iDateOptions);

    if (!dateTime.isValid) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return dateTime;
  }

  clone(date: DateTime): DateTime {
    return date;
  }

  now(): DateTime {
    return this.options.useUtc ? DateTime.utc().setLocale(this.locale) : DateTime.local().setLocale(this.locale).setZone(this.options.zone);
  }

  format(date: DateTime, displayFormat: any): string {
    if (!this.isValid(date)) {
      throw Error('LuxonDateTimeAdapter: Cannot format invalid date.');
    }
    return date.toFormat(displayFormat);
  }

  parse(value: any, parseFormat: any): DateTime | null {
    if (value) {
      const date = typeof value === 'number' ? new Date(value) : new Date(Date.parse(value));
      return DateTime.fromJSDate(date, this.dateTimeOptions);
    }
    return null;
  }

  deserialize(value: any): DateTime | null {
    if (value instanceof DateTime) {
      return super.deserialize(value.setLocale(this.locale).setZone(this.options.zone));
    }

    if (typeof value === 'string') {
      if (!value) {
        return null;
      }

      let dateTime: DateTime = null;
      if (ISO_8601_REGEX.test(value)) {
        dateTime = DateTime.fromISO(value, this.dateTimeOptions);
      } else if (!Number.isNaN(Number(value))) {
        dateTime = DateTime.fromMillis(+value, this.dateTimeOptions);
      }

      if (dateTime && this.isValid(dateTime)) {
        return super.deserialize(dateTime.setLocale(this.locale).setZone(this.options.zone));
      }
    }

    return super.deserialize(value);
  }

}
