export const ImageLandscape = `<svg width="43" height="34" viewBox="0 0 43 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M30.0069 8.82105C28.9282 8.82105 28.0537 9.69552 28.0537 10.7742C28.0537 11.8529 28.9282 12.7274 30.0069 12.7274C31.0856 12.7274 31.9601 11.8529 31.9601 10.7742C31.9601 9.69552 31.0856 8.82105 30.0069 8.82105ZM25.5537 10.7742C25.5537 8.31481 27.5475 6.32104 30.0069 6.32104C32.4663 6.32104 34.4601 8.31481 34.4601 10.7742C34.4601 13.2337 32.4663 15.2274 30.0069 15.2274C27.5475 15.2274 25.5537 13.2337 25.5537 10.7742Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M14.5275 12.2008C14.2202 11.8847 13.718 11.8682 13.3907 12.1634L2.6777 21.8246L1.00342 19.968L11.7164 10.3068C13.0421 9.11132 15.0758 9.17819 16.3201 10.4582L30.2764 24.8154C30.7576 25.3104 30.7464 26.1018 30.2513 26.583C29.7563 27.0642 28.965 27.053 28.4838 26.558L14.5275 12.2008Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M0.611816 4.82779C0.611816 2.42279 2.56146 0.473145 4.96647 0.473145H37.9301C40.3351 0.473145 42.2848 2.42279 42.2848 4.8278V29.1718C42.2848 31.5768 40.3351 33.5265 37.9301 33.5265H4.96647C2.56146 33.5265 0.611816 31.5768 0.611816 29.1718V4.82779ZM4.96647 2.97314C3.94217 2.97314 3.11182 3.8035 3.11182 4.82779V29.1718C3.11182 30.1961 3.94217 31.0265 4.96647 31.0265H37.9301C38.9544 31.0265 39.7848 30.1961 39.7848 29.1718V4.8278C39.7848 3.8035 38.9544 2.97314 37.9301 2.97314H4.96647Z"
    fill="currentColor" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M34.3756 19.1876C34.0836 18.9682 33.6817 18.9684 33.3899 19.1882L27.4729 23.6451L25.9688 21.6482L31.8858 17.1913C33.0673 16.3014 34.695 16.3004 35.8774 17.189L41.8106 21.6473C42.3625 22.062 42.4737 22.8456 42.059 23.3975C41.6443 23.9494 40.8607 24.0606 40.3088 23.6459L34.3756 19.1876Z"
    fill="currentColor" />
</svg>`;
