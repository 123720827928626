import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AAGUID } from '@interticket/core';
import { MediaService } from '@services/media';

@Component({
  selector: 'datatable-image',
  templateUrl: './datatable-image.component.html',
  styleUrls: ['./datatable-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatatableImageComponent implements OnInit {

  imageUrl: string;

  @Input() readonly imageId: AAGUID;
  @Input() readonly alt: string;

  constructor(
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.imageUrl = this.mediaService.getImageUrl(this.imageId);
  }

}
