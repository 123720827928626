import { parseArray, UtilString } from '@interticket/core';
import { IRegionsFeatureSelectGroup } from '../interfaces/regions-feature-select-group.interface';
import { RegionsFeatureSelectItem } from './regions-feature-select-item.model';

export class RegionsFeatureSelectGroup<T = any> implements IRegionsFeatureSelectGroup {

  readonly label: string;
  readonly features: RegionsFeatureSelectItem[];
  readonly translation: string;

  id?: T;
  hasSelected?: boolean;
  selected?: boolean;
  match?: boolean;
  hidden?: boolean;

  constructor(iRegionsFeatureEditorItem: IRegionsFeatureSelectGroup) {
    this.id = iRegionsFeatureEditorItem.id;
    this.label = iRegionsFeatureEditorItem.label;
    this.features = parseArray(iRegionsFeatureEditorItem.features, RegionsFeatureSelectItem);
    this.translation = iRegionsFeatureEditorItem.translation;
    this.updateState();
  }

  private isVisible(item: RegionsFeatureSelectItem): boolean {
    return !item.hidden || this.match;
  }

  private toggle(select: boolean, selectHidden: boolean): void {
    this.features.forEach((item: RegionsFeatureSelectItem) => {
      if (this.isVisible(item) || selectHidden) {
        item[select ? 'select' : 'deselect']();
      }
    });
    this.updateState();
  }

  select(selectHidden?: boolean): void {
    this.toggle(true, selectHidden);
  }

  deselect(deselectHidden?: boolean): void {
    this.toggle(false, deselectHidden);
  }

  updateState(): void {
    this.hasSelected = this.features.some((item: RegionsFeatureSelectItem) => item.selected);
    this.selected = this.features.every((item: RegionsFeatureSelectItem) => {
      const visible = this.isVisible(item);
      const visibleAndSelected = visible && item.selected;
      return visibleAndSelected || !visible;
    });
  }

  filter(text: string): void {
    this.features.forEach((item: RegionsFeatureSelectItem) => item.filter(text));
    this.match = UtilString.include(this.translation, text);
    this.hidden = !this.match && this.features.every((item: RegionsFeatureSelectItem) => item.hidden);
    this.updateState();
  }

}
