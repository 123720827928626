<div class="content-languages">
  @for (lang of languages; track lang) {
    <ui-kit-button
      class="content-language-btn"
      [palette]="uiKitPalette.PRIMARY"
      [size]="uiKitSize.TINY"
      [isHollow]="lang !== $selectedLang()"
      (onClick)="changeLanguage(lang)">

      <div class="content-language-projection">
        <span data-e2e="indicator" [ngClass]="['indicator', $indicators().includes(lang) ? 'green' : 'yellow']"></span>
        <span data-e2e="language-code">{{ lang }}</span>
      </div>

    </ui-kit-button>
  }
</div>
