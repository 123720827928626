import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UiKitPalette } from '@ui-kit/enums';
import { fromEvent, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UiKitConfirmModalComponent } from '../components/ui-kit-confirm-modal/ui-kit-confirm-modal.component';
import { IUiKitConfirmModalData } from '../interfaces/ui-kit-confirm-modal-data.interface';

@Directive({
  selector: '[uiKitConfirmModalTrigger]',
})
export class UiKitConfirmModalDirective implements OnInit {

  private readonly destroy$ = new Subject<void>();

  @Input() readonly confirmTitle: string;
  @Input() readonly confirmMessage: string;
  @Input() readonly dismissButtonLabel: string;
  @Input() readonly confirmButtonLabel: string;
  @Input() readonly modalPalette: UiKitPalette;
  @Input() readonly isDisabled: boolean;

  @Output() confirmed = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(
        filter(() => !this.hasDisabledElement()),
        switchMap(() => this.dialog.open<UiKitConfirmModalComponent, IUiKitConfirmModalData>(UiKitConfirmModalComponent, {
          data: {
            confirmTitle: this.confirmTitle,
            message: this.confirmMessage,
            dismissButtonLabel: this.dismissButtonLabel,
            confirmButtonLabel: this.confirmButtonLabel,
            modalPalette: this.modalPalette,
          },
        })
          .afterClosed()
          .pipe(
            tap(() => this.closed.emit()),
            filter(Boolean),
            tap(() => this.confirmed.emit())
          )
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private hasDisabledElement(): boolean {
    if (this.isDisabled || this.elementRef.nativeElement.hasAttribute('disabled')) {
      return true;
    }

    const childElements = this.elementRef.nativeElement.children;

    return Array.from(childElements).some((childElement) => childElement.hasAttribute('disabled'));
  }

}
