export const Accessibility = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path
    d="M8.00016 2.66732C8.36835 2.66732 8.66683 2.36884 8.66683 2.00065C8.66683 1.63246 8.36835 1.33398 8.00016 1.33398C7.63197 1.33398 7.3335 1.63246 7.3335 2.00065C7.3335 2.36884 7.63197 2.66732 8.00016 2.66732Z"
    fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
    stroke-linejoin="round" />
  <path d="M4.6665 14.6667L7.99984 10L11.3332 14.6667" stroke="currentColor" stroke-width="1.5"
    stroke-linecap="round" stroke-linejoin="round" />
  <path d="M2.6665 3.33398L7.99984 5.33398L13.3332 3.33398" stroke="currentColor" stroke-width="1.5"
    stroke-linecap="round" stroke-linejoin="round" />
  <path d="M8 5.33398V10.0007" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
    stroke-linejoin="round" />
</svg>`;
