import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AAGUID } from '@interticket/core';

@Component({
  selector: 'ui-kit-image-carousel',
  templateUrl: './ui-kit-image-carousel.component.html',
  styleUrls: ['./ui-kit-image-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitImageCarouselComponent {

  @Input() images: AAGUID[];

  currentIndex = 0;

  nextImage() {
    this.currentIndex++;
    if (this.currentIndex > this.images.length - 1) {
      this.currentIndex = 0;
    }
  }

  previousImage() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.images.length - 1;
    }
  }

  goToImage(index: number) {
    this.currentIndex = index;
  }

}
