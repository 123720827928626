import { booleanAttribute, DestroyRef, Directive, inject, Input, numberAttribute, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ContentTranslationStoreService } from '../services/content-translation.service';

@Directive({
  selector: '[uiContentTranslation]',
})
export class UiKitContentTranslationDirective implements OnInit, OnDestroy {

  private readonly contentTranslationService = inject(ContentTranslationStoreService);
  private readonly destroyRef = inject(DestroyRef);

  @Input({ required: true }) control: FormControl;
  @Input({ transform: numberAttribute }) maxLength = 127;
  @Input({ transform: booleanAttribute }) cleanupOnDestroy = true;

  /**
   * Unique identifier for the content translation stored in the service.
   */
  @Input({ required: true }) contentTranslationId: string;

  /**
   * Function that sets the validation for the control.
   */
  @Input() validationChecker: () => void = this.defaultValidatorChecker;

  ngOnInit(): void {
    if (this.control && this.contentTranslationId) {
      this.subscribeToControlChanges();
      this.subscribeToSelectedLangChanges();
    }
  }

  ngOnDestroy(): void {
    this.cleanupOnDestroy && this.contentTranslationService.removeTranslations(this.contentTranslationId);
  }

  private subscribeToControlChanges(): void {
    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.contentTranslationService.addTranslationToSelectedLang(this.contentTranslationId, value || '');
      });
  }

  private subscribeToSelectedLangChanges(): void {
    this.contentTranslationService.selectedLang$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedLang) => {
        const storedTranslations = this.contentTranslationService.getTranslations(this.contentTranslationId);
        const translationForSelectedLang = storedTranslations[selectedLang] || '';
        const wasTouched = this.control.touched;

        this.validationChecker();
        this.control.patchValue(this.contentTranslationService.beforePatchValue(translationForSelectedLang, this.contentTranslationId));

        if (wasTouched) {
          this.control.markAsTouched();
        }

        this.control.updateValueAndValidity();
      });
  }

  private defaultValidatorChecker(): void {
    const baseValidators: ValidatorFn[] = [Validators.maxLength(this.maxLength)];
    const validators = this.contentTranslationService.isDefaultLanguage
      ? [Validators.required, ...baseValidators]
      : baseValidators;
    this.control.clearValidators();
    this.control.setValidators(validators);
  }

}
