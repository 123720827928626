export const Rhombus = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_2746_1387)">
  <path d="M2.13739 8.15416C1.96001 8.33136 1.8193 8.54177 1.7233 8.77338C1.6273 9.00498 1.57788 9.25324 1.57788 9.50396C1.57788 9.75467 1.6273 10.0029 1.7233 10.2345C1.8193 10.4661 1.96001 10.6766 2.13739 10.8537L8.14614 16.8625C8.32333 17.0399 8.53374 17.1806 8.76535 17.2766C8.99696 17.3726 9.24521 17.422 9.49593 17.422C9.74664 17.422 9.9949 17.3726 10.2265 17.2766C10.4581 17.1806 10.6685 17.0399 10.8457 16.8625L16.8545 10.8537C17.0318 10.6766 17.1726 10.4661 17.2686 10.2345C17.3646 10.0029 17.414 9.75467 17.414 9.50396C17.414 9.25324 17.3646 9.00498 17.2686 8.77338C17.1726 8.54177 17.0318 8.33136 16.8545 8.15416L10.8457 2.14541C10.6685 1.96804 10.4581 1.82733 10.2265 1.73133C9.9949 1.63532 9.74664 1.58591 9.49593 1.58591C9.24521 1.58591 8.99696 1.63532 8.76535 1.73133C8.53374 1.82733 8.32333 1.96804 8.14614 2.14541L2.13739 8.15416Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
  <clipPath id="clip0_2746_1387">
    <rect width="19" height="19" fill="white"/>
  </clipPath>
</defs>
</svg>`;
