export const Company = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_364_9287)">
    <path d="M14 12.6667H15.3333V14H0.666626V12.6667H1.99996V2.66667C1.99996 2.48986 2.0702 2.32029 2.19522 2.19526C2.32025 2.07024 2.48981 2 2.66663 2H9.33329C9.5101 2 9.67967 2.07024 9.8047 2.19526C9.92972 2.32029 9.99996 2.48986 9.99996 2.66667V12.6667H12.6666V7.33333H11.3333V6H13.3333C13.5101 6 13.6797 6.07024 13.8047 6.19526C13.9297 6.32029 14 6.48986 14 6.66667V12.6667ZM3.33329 3.33333V12.6667H8.66663V3.33333H3.33329ZM4.66663 7.33333H7.33329V8.66667H4.66663V7.33333ZM4.66663 4.66667H7.33329V6H4.66663V4.66667Z" fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_364_9287">
      <rect width="16" height="16" fill="none"/>
    </clipPath>
  </defs>
</svg>`;
