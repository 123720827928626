import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiKitChipSetComponent } from '../ui-kit-chip-set/ui-kit-chip-set.component';

@Component({
  selector: 'ui-kit-chip-list',
  templateUrl: './ui-kit-chip-list.component.html',
  styleUrls: ['./ui-kit-chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitChipListComponent<ValueType = string> extends UiKitChipSetComponent<ValueType> {

  @Input() isHoverDisabled = false;
  @Input() @HostBinding('class') readonly direction: 'row' | 'column' = 'row';

}
