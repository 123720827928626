export const Gallery = `<svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="stroke">
    <g id="Group 168">
      <rect id="Rectangle 138" x="1.85352" y="8.9375" width="16.6547" height="12.7874" rx="1.3244"
        stroke="currentColor" stroke-width="2" />
      <path id="Vector 130"
        d="M13.6787 19.2L7.8634 13.2979C7.28705 12.713 6.35443 12.6792 5.73729 13.2209L1.93945 16.5548"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path id="Vector 131"
        d="M18.481 17.4039L16.6533 16.1405C16.1307 15.7792 15.4405 15.7736 14.912 16.1262L12.7656 17.5585"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <circle id="Ellipse 22" cx="13.9327" cy="12.5655" r="1.1319" stroke="currentColor" stroke-width="2" />
    </g>
    <path id="Rectangle 139"
      d="M21.1819 19.2013V19.2013C21.9069 19.2013 22.4946 18.6136 22.4946 17.8886V6.69315C22.4946 5.83733 21.8008 5.14355 20.945 5.14355L5.53457 5.14355C4.83807 5.14355 4.27344 5.70818 4.27344 6.40469V6.40469"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" />
    <path id="Rectangle 140"
      d="M24.969 15.3311V15.3311C25.694 15.3311 26.2817 14.7434 26.2817 14.0185V2.82304C26.2817 1.96722 25.5879 1.27344 24.7321 1.27344L9.32168 1.27344C8.62518 1.27344 8.06055 1.83807 8.06055 2.53457V2.53457"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" />
  </g>
</svg>`;
