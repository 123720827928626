import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { DateView, OwlCalendarComponent, OwlDateTimeInlineComponent } from '@danielmoncada/angular-datetime-picker';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[uiKitDateTimePickerInline]',
})
export class UiKitDateTimePickerInlineDirective implements OnDestroy, AfterViewInit {

  private readonly destroy$ = new Subject<void>();
  private markedDaysOfMonth: number[] = [];

  @Input()
  set markedDates(markedDates: DateTime[]) {
    this.markedDaysOfMonth = markedDates?.map(date => date.day) || [];
    this.markDays();
  }

  @Output() pickerMomentChange = new EventEmitter<DateTime>();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private calendarComponent: OwlDateTimeInlineComponent<DateTime>,
  ) { }

  ngAfterViewInit(): void {
    this.subscribeToPickerMomentChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToDateInView(pickerMoment: DateTime): void {
    this.calendar.goToDateInView(pickerMoment, DateView.MONTH);
  }

  private get nativeElement(): HTMLElement {
    return this.element.nativeElement;
  }

  private get calendar(): OwlCalendarComponent<DateTime> {
    return this.calendarComponent.container.calendar;
  }

  private get dayHTMLElements(): HTMLSpanElement[] {
    return Array.from(this.nativeElement.querySelectorAll('.owl-dt-calendar-cell:not([class*="owl-dt-calendar-cell-disabled"]'))
      .filter(day => day.querySelector('span:not([class*="owl-dt-calendar-cell-out"]'))
      .map(day => day.querySelector('span'))
      .filter(day => this.markedDaysOfMonth.includes(parseInt(day.textContent.trim())));
  }

  private markDays(): void {
    this.dayHTMLElements.forEach(day => this.renderer.addClass(day, 'day-with-timeslots'));
  }

  private subscribeToPickerMomentChange(): void {
    this.calendar.pickerMomentChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(pickerMoment => this.pickerMomentChange.emit(pickerMoment));
  }

}
