<div class="custom-row">
  <mat-form-field class="legacy-form-field" appearance="outline">
    <div class="mat-infix">
      <input matInput class="search-input" [formControl]="searchFormControl" placeholder="{{ searchBarPlaceholder | translate }}" autocomplete="off">
    </div>
    <div matSuffix>
      <mat-checkbox
        class="checkbox-container"
        [formControl]="checkBoxFormControl"
        (change)="toggleCheckBox($event.checked)">
        {{ checkboxLabel | translate }}
      </mat-checkbox>
    </div>
  </mat-form-field>
</div>
