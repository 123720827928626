import { MatDialogConfig } from '@angular/material/dialog';
import { RegionCode } from '@interticket/core';

export const FULL_SCREEN_DIALOG_CONFIG: MatDialogConfig = {
  height: '99%',
  width: '99%',
  maxWidth: '99%',
  maxHeight: '99%',
  panelClass: 'full-screen-dialog',
  disableClose: true,
};

export const REGION_SORT_PATTERN: RegionCode[] = [
  RegionCode.EUROPEAN_UNION,
  RegionCode.REST_OF_EUROPEAN_UNION,
  RegionCode.NORTH_AMERICA,
  RegionCode.SOUTH_AMERICA,
  RegionCode.OCEANIA,
  RegionCode.ASIA,
  RegionCode.AFRICA,
  RegionCode.ANTARCTICA,
];
