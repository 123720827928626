import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[elementHeightListener]',
})
export class UiKitElementHeightListenerDirective implements AfterViewInit, OnDestroy {

  private height: number;
  private observer: ResizeObserver;

  @Output() readonly changedHeight = new EventEmitter<number>();

  constructor(
    private element: ElementRef<HTMLElement>,
  ) { }

  ngAfterViewInit(): void {
    this.subscribeToElementSizeChanges();
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  private subscribeToElementSizeChanges(): void {
    this.observer = new ResizeObserver(_ => {
      const currentHeight: number = this.getElementHeight();

      if (this.height !== currentHeight) {
        this.height = currentHeight;
        this.changedHeight.emit(this.height);
      }
    });

    this.observer.observe(this.element.nativeElement);
  }

  private getElementHeight(): number {
    return this.element.nativeElement.getBoundingClientRect().height;
  }

}
