import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { DATE_TIME_FORMATS, LanguageCode, TimeZone, TranslateModule, TranslateService } from '@interticket/core';
import { OwlDateTimeToLabelSelectOverrideDirective } from '../form/modules/date-time-picker/components/ui-kit-date-time-menu-picker/owl-date-time-to-label-select-override.directive';
import { DateTimeIntl, owlDateTimeFormatFactory } from './configs/date-time-picker-localization';
import { LuxonDateTimeAdapter } from './configs/date-time-picker-luxon-adapter';
import { UiKitDateTimePickerInlineDirective } from './directives/ui-kit-date-time-picker-inline.directive';
import { IUiKitDateTimePickerModuleConfig, UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG } from './interfaces/ui-kit-date-time-picker-module-config.interface';

@NgModule({
  imports: [
    CommonModule,
    OwlDateTimeModule,
    TranslateModule,
  ],
  declarations: [
    UiKitDateTimePickerInlineDirective,
    OwlDateTimeToLabelSelectOverrideDirective,
  ],
  exports: [
    OwlDateTimeModule,
    UiKitDateTimePickerInlineDirective,
    OwlDateTimeToLabelSelectOverrideDirective,
  ],
  providers: [
    {
      provide: UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG,
      useFactory: (translate: TranslateService): IUiKitDateTimePickerModuleConfig => ({
        locale: translate.currentLang || LanguageCode.EN,
        format: DATE_TIME_FORMATS[translate.currentLang] || DATE_TIME_FORMATS[LanguageCode.EN],
        timeZone: TimeZone.LOCAL,
      }),
      deps: [TranslateService],
    },
    {
      provide: OWL_DATE_TIME_LOCALE,
      useFactory: (translate: TranslateService) => translate.currentLang,
      deps: [TranslateService],
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useFactory: owlDateTimeFormatFactory,
      deps: [UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG],
    },
    {
      provide: DateTimeAdapter,
      useClass: LuxonDateTimeAdapter,
      deps: [UI_KIT_DATE_TIME_PICKER_MODULE_CONFIG],
    },
    {
      provide: OwlDateTimeIntl,
      useClass: DateTimeIntl,
    },
  ],
})
export class UiKitDateTimeModule { }
