import { ITDSIconGroup } from '../enums/itds-icons.enum';
import { ITDSIconGroups } from '../interfaces/itds-icons.interface';
import { Copy } from '../svg/files/copy';
import { Download } from '../svg/files/download';
import { TrashCan } from '../svg/formatting/trash-can';
import { Add } from '../svg/navigation/add';
import { AddSquare } from '../svg/navigation/add-square';
import { Caret } from '../svg/navigation/caret';
import { Chevron } from '../svg/navigation/chevron';
import { Close } from '../svg/navigation/close';
import { CheckSquare } from '../svg/status/check-square';
import { Checkmark } from '../svg/status/checkmark';
import { CheckmarkFilled } from '../svg/status/checkmark-filled';
import { Information } from '../svg/status/info';
import { InformationFilled } from '../svg/status/information-filled';
import { WarningAltFilled } from '../svg/status/warning-alt-filled';
import { WarningFilled } from '../svg/status/warning-filled';
import { LinkAlt } from '../svg/toggle-icons/link-alt';

/**
 * ITDS Icon set in groups
 * Figma: https://www.figma.com/design/WOSWwiLtSxupJFmOHbwAHw/%E2%AD%90-Icons---Interticket-Design-Language?node-id=1-5200&m=dev
 * Exports: https://drive.google.com/drive/folders/1VR0SXqRjLFUkrgri04J6Bgv_E4t-p0Ri
 */
export const ITDSIconPack: ITDSIconGroups = {

  // FILES
  [ITDSIconGroup.FILES]: {
    Copy,
    Download,
  },

  // FORMATTING
  [ITDSIconGroup.FORMATTING]: {
    TrashCan,
  },

  // NAVIGATION
  [ITDSIconGroup.NAVIGATION]: {
    Add,
    AddSquare,
    Caret,
    Chevron,
    Close,
  },

  // STATUS
  [ITDSIconGroup.STATUS]: {
    CheckSquare,
    Checkmark,
    CheckmarkFilled,
    Information,
    InformationFilled,
    WarningFilled,
    WarningAltFilled,
  },

  [ITDSIconGroup.TOGGLE_ICONS]: {
    LinkAlt,
  },

};
