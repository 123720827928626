import { IContact } from '@core/interfaces/contact/contact.interface';
import { BillingInformationView } from '@core/models/billing/billing-information-view.model';
import { Company } from '@core/models/company/company.model';
import { Contact } from '@core/models/contact/contact.model';
import { IPartnerProfileEditor } from '../interfaces/partner-profile-editor.interface';

export class PartnerProfileEditor implements IPartnerProfileEditor {

  readonly company: Company;
  readonly contacts: Contact[];
  readonly billingInformation: BillingInformationView;

  constructor(iPartnerProfile: IPartnerProfileEditor) {
    this.company = new Company(iPartnerProfile.company);
    this.contacts = iPartnerProfile.contacts ? iPartnerProfile.contacts.map((iContact: IContact) => new Contact(iContact)) : [];
    this.billingInformation = new BillingInformationView(iPartnerProfile.billingInformation);
  }

}
