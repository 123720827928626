<ui-kit-callout [class.closeable]="options.closeButton" [palette]="toastPackage.toastType">

  <!-- CLOSE -->
  <ui-kit-button-icon
    *ngIf="options.closeButton"
    [icon]="'x'"
    (onClick)="remove()">
  </ui-kit-button-icon>

  <!-- TITLE -->
  <h1 *ngIf="title">
    {{ title | translate }}
    <small *ngIf="duplicatesCount$ | async as count">(x{{ count + 1 }})</small>
  </h1>

  <!-- DESCRIPTION -->
  <p *ngIf="message && !options.enableHtml">{{ message }}</p>
  <p *ngIf="message && options.enableHtml" [innerHTML]="message"></p>

</ui-kit-callout>
