import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  selector: 'generate-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateButtonComponent extends ButtonComponent {

  @Input() readonly classes: string | string[] = ['button', 'hollow', 'primary'];
  @Input() readonly label: string;
  @Input() readonly type: 'button' | 'submit' = 'button';

}
