<h2 *ngIf="title">{{ title | translate }}</h2>
<div class="component-container">
  <!-- PROGRESS BAR -->
  <div class="bar-container">
    <div class="bar ui-kit-palette-{{ palette }}" [style.width.%]="progressBarPercentage"></div>
  </div>

  <!-- INLINE LEGEND -->
  <div
    *ngIf="showLegend && legendPosition === 'inline'"
    class="inline-legend">{{ progressBarPercentage }}%</div>

  <!-- BOTTOM LEGEND -->
  <div class="legend" *ngIf="showLegend && legendPosition === 'bottom'">
    <ng-container *ngIf="!showPercentage else percentage">{{ current }} / {{ total }}</ng-container>
    <ng-template #percentage>{{ current / total | percent }}</ng-template>
    <span *ngIf="legend"> {{ legend | translate }}</span>
  </div>

</div>
