import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { hexaColorValidator } from '@interticket/core';

@Component({
  selector: 'ui-kit-color-preview',
  templateUrl: './ui-kit-color-preview.component.html',
  styleUrls: ['./ui-kit-color-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitColorPreviewComponent {

  @Input() readonly strict = false;
  @Input() @HostBinding('style.--color-preview-size') readonly size: string = '32px';
  @Input() readonly color = 'transparent';

  @HostBinding('style.--color-preview-bg') get colorPreviewBg(): string {
    return !this.strict || this.formBuilder.control(this.color, hexaColorValidator(false)).valid ? this.color : 'transparent';
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

}
