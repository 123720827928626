export enum BoSalesPermission {
  LIST_AND_SEARCH_EVENTS = 'sales.list.and.search.events',
  SELL_AND_CHECKOUT = 'sales.sell.ticket.from.box.office.-.checkout.flow',
  SELL_AND_CHECK_IN = 'sales.sell.ticket.from.box.office.-.sell.and.check.in',
  SELL_AND_PRINT = 'sales.sell.ticket.from.box.office.-.sell.and.print',
  SELL_TICKET = 'sales.sell.ticket.from.box.office',
  SELL_WITH_SHOPBOX = 'sales.selling.with.shopbox',
  // CUSTOM CARD
  REDEEM_CUSTOM_CARDS = 'sales.redeem.custom.cards.in.box.office',
  SELL_CUSTOM_CARDS = 'sales.sell.custom.card.in.box.office',
  // GIFT CARD
  REDEEM_GIFT_CARDS = 'sales.redeem.gift.cards.in.box.office',
  SELL_GIFT_CARDS = 'sales.sell.gift.card.in.box.office',
  // DISCOUNT CARD
  REDEEM_DISCOUNT_CARDS = 'sales.redeem.discount.cards.in.box.office',
  SELL_DISCOUNT_CARDS = 'sales.sell.discount.card.in.box.office',
}
