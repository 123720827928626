<div class="color-list">
  <ui-kit-button-icon
    *ngFor="let color of Colors"
    [class.selected]="control.getRawValue() === color"
    [style.backgroundColor]="color"
    [rippleDisabled]="true"
    [size]="'medium'"
    [icon]="'check'"
    (onClick)="selectColor(color)">
  </ui-kit-button-icon>
</div>
