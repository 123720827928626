import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette, UiKitSize } from '@ui-kit/enums';
import { IStatusBadgeConfig } from '../../interfaces/status-badge-config.interface';

@Component({
  selector: 'ui-kit-status-badge',
  templateUrl: './ui-kit-status-badge.component.html',
  styleUrls: ['./ui-kit-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitStatusBadgeComponent {

  @Input() readonly size: UiKitSize = UiKitSize.MEDIUM;
  @Input() readonly isIconRight: boolean = false;
  @Input() readonly statusConfig: IStatusBadgeConfig;

  @HostBinding('style')
  get paletteColors(): SafeStyle {
    const palette: UiKitPalette = this.statusConfig?.palette || UiKitPalette.DEFAULT;
    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-button-color: var(--${palette}-color);
      --ui-kit-button-color-background: var(--${palette}-color-background);
    `);
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }

}
