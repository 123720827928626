<div class="step-wrapper">
  <div *ngFor="let step of steps; index as i; trackBy: trackByFn" class="step-container" [matTooltip]="'wizard_step_number' | translate: i + 1">
    <div class="step-circle"
      [class.active-step]="currentStep === steps[i]"
      [class.valid-step]="steps[i].isVisited && isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.invalid-step]="steps[i].isVisited && !isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.future-step]="!steps[i].isVisited"
      (click)="changeStep(steps[i])">
      {{ i + 1 }}
    </div>
    <span class="step-label"
      [class.active-step]="currentStep === steps[i]"
      [class.valid-step]="steps[i].isVisited && isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.invalid-step]="steps[i].isVisited && !isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.future-step]="!steps[i].isVisited"
      (click)="changeStep(steps[i])">
      {{ step.label | translate }}
    </span>
    <span class="step-pattern"
      *ngIf="isLastStep(i)"
      [class.active-step]="currentStep === steps[i]"
      [class.valid-step]="steps[i].isVisited && isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.invalid-step]="steps[i].isVisited && !isValidStep(steps[i].name) && currentStep !== steps[i]"
      [class.future-step]="!steps[i].isVisited">
      – — — — –
    </span>
  </div>
</div>
