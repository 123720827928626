<div *ngxPermissionsOnly="[contentTranslationPermission.USE]" class="content-translation-wrapper">
  <label>
    {{ label | translate }}:

    <div class="content-languages">
      <span
        *ngFor="let language of languages"
        class="content-language {{ badgeClasses }}"
        [ngClass]="{ 'selected': isSelected(language) }"
        (click)="onClick(language)">
        {{ language }}
      </span>
    </div>

  </label>
</div>
