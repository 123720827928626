import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-kit-divider-line',
  templateUrl: './ui-kit-divider-line.component.html',
  styleUrls: ['./ui-kit-divider-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitDividerLineComponent {

  @Input() spacing = 22;
  @Input() unit: 'px' | 'rem' | 'em' = 'px';

  get margin(): string {
    return `${this.spacing}${this.unit} 0`;
  }

}
