import { AAGUID } from '@interticket/core';
import { IRegionPackageEditor } from '../interfaces/region-package-editor.interface';

export class RegionPackageEditor implements IRegionPackageEditor {

  readonly name: string;
  readonly featureIds: AAGUID[];

  constructor(iRegionPackageEditor: IRegionPackageEditor) {
    this.name = iRegionPackageEditor.name;
    this.featureIds = Array.isArray(iRegionPackageEditor.featureIds) ? iRegionPackageEditor.featureIds : [];
  }

}
