export enum CardPermission {
  // GIFT CARD
  GIFT_CARD_CREATE = 'card.template.create.module.create.template',
  GIFT_CARD_LIST = 'card.template.list.module.list.template',
  GIFT_CARD_VIEW = 'card.template.view.template.card',
  GIFT_CARD_EDIT = 'card.template.update.module.update.template',
  GIFT_CARD_DELETE = 'card.template.delete.module.delete.template',
  // DISCOUNT CARD
  DISCOUNT_CARD_CREATE = 'card.discount.create.module.create.discount',
  DISCOUNT_CARD_LIST = 'card.discount.list.and.search.discounts',
  DISCOUNT_CARD_VIEW = 'card.discount.view.discount.card',
  DISCOUNT_CARD_EDIT = 'card.discount.edit.discount.card',
  DISCOUNT_CARD_DELETE = 'card.discount.delete.discount.card',
  // CUSTOM CARD
  // TODO: Change permissions for VIEW/EDIT/DELETE when available
  CUSTOM_CARD_CREATE = 'card.template.create.module.create.custom.template',
  CUSTOM_CARD_LIST = 'card.template.list.module.list.custom.template',
  CUSTOM_CARD_VIEW = 'card.template.get.custom.module.get.custom.template',
  CUSTOM_CARD_EDIT = 'card.template.edit.custom.module.edit.custom.template',
  CUSTOM_CARD_DELETE = 'card.template.delete.custom.module.delete.custom.template',
  // ISSUED GIFT CARD
  ISSUED_GIFT_CARD_LIST = 'card.issued.list.module.list.issuedcard',
  ISSUED_GIFT_CARD_EDIT = 'card.issued.update.module.update.issuedcard',
  // ISSUED DISCOUNT CARD
  ISSUED_DISCOUNT_CARD_LIST = 'card.issued.list.module.list.discountcard',
  ISSUED_DISCOUNT_CARD_EDIT = 'card.issued.update.module.update.discountcard',
  // ISSUED CUSTOM CARD
  ISSUED_CUSTOM_CARD_LIST = 'card.issued.list.module.list.customcard',
  ISSUED_CUSTOM_CARD_EDIT = 'card.issued.update.module.update.customcard',
  // OTHER
  TEMPLATE_BLOCKCHAIN = 'card.template.blockchain.module.issue.gift.card.on.blockchain',
  // PERMIT TEMPLATE
  PERMIT_CARD_TEMPLATE_CREATE = 'card.template.create.permit.template',
  PERMIT_CARD_TEMPLATE_VIEW = 'card.template.list.permit.template',
  PERMIT_CARD_TEMPLATE_EDIT = 'card.template.edit.permit.template',
  PERMIT_CARD_TEMPLATE_DELETE = 'card.template.delete.permit.template',
  // PERMIT REQUEST
  PERMIT_CARD_REQUEST_LIST = 'card.issued.list.and.search.permit.requests',
  PERMIT_CARD_REQUEST_EDIT = 'card.issued.edit.permit.request',
}
