export enum UserPermission {
  LIST = 'users.list.and.search.users',
  EXPORT = 'users.export.users',
  DETAILS = 'users.view.user.details',
  INVITE = 'users.invite.new.user',
  DELETE = 'users.delete.user',
  INACTIVATE = 'users.inactivate.user',
  LOGIN = 'users.login',
  RESEND_INVITATION_MAIL = 'users.resend.invitation.mail.to.an.already.existing.user',
  ADD_ROLES = 'users.add.additional.roles.to.an.already.existing.user'
}
