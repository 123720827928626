import { InjectionToken } from '@angular/core';
import { ICrispConfig } from '@interticket/core';
import { Environment } from '../enums/environment.enum';

export type IDynamicAppConfigs = IDynamicAppConfig & {
  [key in Environment]?: Partial<IDynamicAppConfig>;
};

export type IAppConfig = IDynamicAppConfig & IStaticAppConfig;

export interface IDynamicAppConfig {
  accountSiteUrl: string;
  crisp?: ICrispConfig;
  env?: Environment;
  partnersApiDocsUrl: string;
  scEditorCDN?: string;
  scViewerCDN?: string;
  slackWebhookUrl?: string;
  stackdriverApiKey?: string;
  stackdriverProjectId?: string;
  cdnReferenceUrl?: string;
}

export interface IStaticAppConfig {
  googleMapsKey?: string;
  reportWebhookUrl?: string;
  serviceUrl: string;
  translationTags: string[];
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('APP_CONFIG');
