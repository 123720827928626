import { AAGUID } from '@interticket/core';
import { IPartnerEditor } from '../interfaces/partner-editor.interface';
import { PartnerProfileEditor } from './partner-profile-editor.model';

export class PartnerEditor extends PartnerProfileEditor implements IPartnerEditor {

  readonly partnerId: AAGUID;

  constructor(iPartnerEditor: IPartnerEditor) {
    super(iPartnerEditor);
    this.partnerId = iPartnerEditor.partnerId;
  }

}
