import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'checkable-search-bar',
  templateUrl: './checkable-search-bar.component.html',
  styleUrls: ['./checkable-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckableSearchBarComponent {

  @Input() searchFormControl: UntypedFormControl;
  @Input() labelFormControl: UntypedFormControl;
  @Input() translationFormControl: UntypedFormControl;

  onToggleLabelsFilterLabel(checked: boolean): void {
    this.labelFormControl.patchValue(checked);
  }

  onToggleTranslationsFilterLabel(checked: boolean): void {
    this.translationFormControl.patchValue(checked);
  }

}
