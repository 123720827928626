import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UiKitPalette } from '@ui-kit/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IChipOption } from '../../interfaces/chip-option.interface';
import { UiKitChipOptionVariant } from '../ui-kit-chip-option/ui-kit-chip-option.component';

@Component({
  selector: 'ui-kit-chip-set',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class UiKitChipSetComponent<ValueType = string> implements OnChanges, OnDestroy {

  protected cdr = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();

  @Input() protected chipList: IChipOption<ValueType>[];
  @Input() protected readonly control: FormControl<IChipOption<ValueType>[]>;
  @Input() readonly isDisabledSet: boolean = false;
  @Input() readonly removableSet: 'all' | 'none' | 'uniquePerChip' = 'uniquePerChip';
  @Input() readonly removeIcon: string = 'x';
  @Input() readonly palette: UiKitPalette;
  @Input() readonly variant: UiKitChipOptionVariant = 'filled';

  @Output() readonly remove = new EventEmitter<ValueType | string | number>();
  @Output() readonly add = new EventEmitter<IChipOption<ValueType>>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control?.currentValue) {
      this.subscribeToControlChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get chips(): IChipOption<ValueType>[] {
    if (this.control) {
      return this.control.value;
    }

    return this.chipList;
  }

  onRemoved(idx: number): void {
    if (this.control) {
      const chipList = [...this.control.value];
      this.emitRemove(chipList[idx]);
      chipList.splice(idx, 1);
      this.control.patchValue(chipList);
      return;
    }

    this.emitRemove(this.chipList[idx]);
    this.chipList.splice(idx, 1);
  }

  onAdded(chip: IChipOption<ValueType>): void {
    if (this.control) {
      this.emitAdd(chip);
      this.control.patchValue([...this.control.value, chip]);
      return;
    }

    this.emitAdd(chip);
    this.chipList = [...this.chipList, chip];
  }

  trackByFn(chip: IChipOption<ValueType>): ValueType | string | number {
    return chip.id || chip.value;
  }

  private subscribeToControlChanges(): void {
    this.control?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.detectChanges());
  }

  private emitRemove(chip: IChipOption<ValueType>): void {
    this.remove.emit(chip.id || chip.value);
  }

  private emitAdd(chip: IChipOption<ValueType>): void {
    this.add.emit(chip);
  }

}
