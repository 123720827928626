<!-- TOGGLER -->
<div
  matRipple
  [matRippleDisabled]="onlyToggleOnIcon || centered || isDisabled"
  class="expander-header"
  [style.borderRadius]="headerRadius"
  [class.is-disabled]="isDisabled"
  [class.only-toggle-on-icon]="onlyToggleOnIcon"
  (click)="onHeaderClick()">

  <!-- TITLE -->
  {{ title | translate }}
  <div class="external-header">
    <ng-content select="[expander-header]"></ng-content>
  </div>

  <!-- ARROW -->
  <ui-kit-button-icon
    (onClick)="onIconClick()"
    [rippleDisabled]="!onlyToggleOnIcon || centered || isDisabled"
    [isDisabled]="isDisabled"
    [icon]="isOpened ? 'chevron-up' : 'chevron-down'">
  </ui-kit-button-icon>
</div>

<!-- CONTENT -->
<div class="expander-content"
  *ngIf="isOpened"
  [style.borderRadius]="contentRadius"
  @accordionAnimation>
  <div class="row is-collapse-child">
    <div class="columns small-12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
