import { DisplayImageOrientation, IPartnerOrganizerWebSettings, PartnerOrganizerWebSettings, PartnerPageWebSettings, PublicPageTemplate, RegionSubPageWebSettings, WebPageCustomizationSettings } from '@interticket/core';

export class PartnerOrganizerWebSettingsWithDefaults extends PartnerOrganizerWebSettings implements IPartnerOrganizerWebSettings {

  constructor(iConfig: IPartnerOrganizerWebSettings) {
    super({
      defaultLanguageCode: iConfig?.defaultLanguageCode,
      partnerPageWebSettings: iConfig.partnerPageWebSettings && new PartnerPageWebSettings({
        activationAt: iConfig.partnerPageWebSettings.activationAt,
        coverImageOrientation: iConfig.partnerPageWebSettings.coverImageOrientation || DisplayImageOrientation.PORTRAIT,
        domain: iConfig.partnerPageWebSettings.domain,
        faviconId: iConfig.partnerPageWebSettings.faviconId || 'f02c7699-502a-4df5-a743-98d67d3760fa',
        footerLogoId: iConfig.partnerPageWebSettings.footerLogoId || '1f9febe4-868c-4ba6-b94c-df0cbc68b9e7',
        footerLogoLink: iConfig.partnerPageWebSettings.footerLogoLink || null,
        logoId: iConfig.partnerPageWebSettings.logoId || '1f9febe4-868c-4ba6-b94c-df0cbc68b9e7',
        primaryColor: iConfig.partnerPageWebSettings.primaryColor || '#25acdd',
        tabText: iConfig.partnerPageWebSettings.tabText || 'Interticket',
        template: iConfig.partnerPageWebSettings.template || PublicPageTemplate.DEFAULT,
        trackingSettings: iConfig.partnerPageWebSettings.trackingSettings || null,
        webPageCustomizationSettings: iConfig.partnerPageWebSettings.webPageCustomizationSettings
          && new WebPageCustomizationSettings(iConfig.partnerPageWebSettings.webPageCustomizationSettings) || null,
      }) || null,
      privacy: iConfig.privacy,
      regionSubPageWebSettings: iConfig.regionSubPageWebSettings && new RegionSubPageWebSettings({
        activationAt: iConfig.regionSubPageWebSettings.activationAt,
        coverImage: iConfig.regionSubPageWebSettings.coverImage || null,
        domain: iConfig.regionSubPageWebSettings.domain,
        shortDescription: iConfig.regionSubPageWebSettings.shortDescription || null,
        subPageSlug: iConfig.regionSubPageWebSettings.subPageSlug || null,
        template: iConfig.regionSubPageWebSettings.template || PublicPageTemplate.JEGY_HU,
        webPageCustomizationSettings: iConfig.regionSubPageWebSettings.webPageCustomizationSettings
          && new WebPageCustomizationSettings(iConfig.regionSubPageWebSettings.webPageCustomizationSettings) || null,
        webSiteTitle: iConfig.regionSubPageWebSettings.webSiteTitle || null,
      }) || null,
      supportedLanguages: iConfig?.supportedLanguages,
      terms: iConfig.terms,
    });
  }

}
