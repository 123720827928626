import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitPalette } from '@ui-kit/enums';

@Component({
  selector: 'ui-kit-icon-link',
  templateUrl: './ui-kit-icon-link.component.html',
  styleUrls: ['./ui-kit-icon-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitIconLinkComponent {

  @Input() readonly tooltip: string;
  @Input() readonly link: string[];
  @Input() readonly target: string;

  /** {@link} https://feathericons.com/ */
  @Input() readonly icon: string;

  @Input() readonly palette: UiKitPalette = UiKitPalette.PRIMARY;
  @HostBinding('style')
  get paletteColors(): SafeStyle {
    const palette: UiKitPalette = this.palette || UiKitPalette.DEFAULT;
    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-icon-color-hover: var(--${palette}-color-hover);
    `);
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }

}
