import { AAGUID, CountryCode, UtilString } from '@interticket/core';
import { IRegionsFeatureSelectItem } from '../interfaces/regions-feature-select-item.interface';

export class RegionsFeatureSelectItem implements IRegionsFeatureSelectItem {

  readonly translation: string;

  selected?: boolean;
  hidden?: boolean;
  id: AAGUID;
  name: CountryCode | string;
  label: string;

  constructor(iRegionsFeatureEditorItem: IRegionsFeatureSelectItem) {
    this.translation = iRegionsFeatureEditorItem.translation;
    this.selected = iRegionsFeatureEditorItem.selected;
    this.id = iRegionsFeatureEditorItem.id;
    this.name = iRegionsFeatureEditorItem.name;
    this.label = iRegionsFeatureEditorItem.label;
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }

  filter(text: string): void {
    this.hidden = !UtilString.include(this.translation, text);
  }

}
