import { IAuthLogoutRedirect } from '@core/interfaces/auth/auth-logout-redirect.interface';
import { AuthLogoutRedirectParams } from './auth-logout-redirect-params.model';

export class AuthLogoutRedirect implements IAuthLogoutRedirect {

  readonly returnUrl: string;
  readonly params: AuthLogoutRedirectParams;
  readonly clientOnly: boolean;
  readonly externalRedirect: boolean;

  constructor(iAuthLogoutRedirect: IAuthLogoutRedirect) {
    this.returnUrl = iAuthLogoutRedirect.returnUrl;
    this.params = iAuthLogoutRedirect.params ? new AuthLogoutRedirectParams(iAuthLogoutRedirect.params) : null;
    this.clientOnly = !!iAuthLogoutRedirect.clientOnly;
    this.externalRedirect = !!iAuthLogoutRedirect.externalRedirect;
  }

}
