import { AAGUID, IPartnerOrganizerWebSettings, IPlaceModuleConfig, LanguageCode, PartnerOrganizerWebSettings, PartnerPageWebSettings, RegionSubPageWebSettings } from '@interticket/core';
import { IPartnerOrganizerCheckoutSurveySettings } from '../interfaces/partner-organizer-checkout-survey-settings.interface';
import { IPartnerOrganizerDefaultSettings } from '../interfaces/partner-organizer-default-settings.interface';
import { IPartnerSettingsConfigMailSettings, IPartnerSettingsConfigMailSettingsGroup } from '../interfaces/partner-settings-confirm-mail.interface';
import { IPartnerSettingsNTAK } from '../interfaces/partner-settings-ntak.interface';
import { PartnerOrganizerDetail } from '../types/partner-organizer-detail.type';
import { PartnerOrganizerCheckoutSurveySettings } from './partner-organizer-checkout-survey-settings.model';
import { PartnerOrganizerConfigMailSettings } from './partner-organizer-confirmation-email.model';
import { PartnerOrganizerDefaultSettings } from './partner-organizer-default-settings.model';
import { PartnerSettingsHolder } from './partner-settings-holder.model';
import { PartnerSettingsNTAK } from './partner-settings-ntak.model';

export class PartnerOrganizerConfig
implements IPartnerOrganizerWebSettings, IPartnerOrganizerDefaultSettings, IPartnerSettingsNTAK, IPartnerSettingsConfigMailSettingsGroup, IPartnerOrganizerCheckoutSurveySettings {

  // Web Settings
  readonly defaultLanguageCode: LanguageCode;
  readonly partnerPageWebSettings: PartnerPageWebSettings;
  readonly privacy: string;
  readonly regionSubPageWebSettings: RegionSubPageWebSettings;
  readonly supportedLanguages: LanguageCode[];
  readonly terms: string;

  // Default Settings
  readonly defaultSubCategories: AAGUID[];
  readonly defaultTaxId: AAGUID;
  defaultPlaceSettings: IPlaceModuleConfig[];

  // General Settings
  readonly regNumber: string;
  readonly certFileName: string;
  readonly keyFileName: string;

  // Sale Settings
  readonly assignHolderToTickets: boolean;
  readonly assignHolderToGiftCards: boolean;

  // Checkout Survey Settings
  readonly surveyItemId: string;
  readonly surveyRequired: boolean;

  // Confirmation Mail
  readonly confirmationMail: IPartnerSettingsConfigMailSettings;

  constructor(iConfig: PartnerOrganizerDetail) {
    const webSettings = new PartnerOrganizerWebSettings(iConfig);
    const defaultSettings = new PartnerOrganizerDefaultSettings(iConfig);
    const generalSettings = new PartnerSettingsNTAK(iConfig);
    const saleSettings = new PartnerSettingsHolder(iConfig);
    const checkoutSurveySettings = new PartnerOrganizerCheckoutSurveySettings(iConfig);
    const confirmationMail = new PartnerOrganizerConfigMailSettings(iConfig);

    Object.entries({
      ...webSettings,
      ...defaultSettings,
      ...generalSettings,
      ...saleSettings,
      ...checkoutSurveySettings,
      ...confirmationMail,
    }).forEach(([key, value]) => {
      this[key] = value;
    });
  }

}
