export enum MediaImageType {
  PROFILE = 10,
  ID_CARD = 20,
  PLACE = 30,
  FEEDBACK = 40,
  TEXT_EDITOR = 50,
  PROGRAM = 60,
  REPORT_COMMENT = 70,
  COUPON = 100,
  BANNER = 120,
  CONTENT = 130
}
