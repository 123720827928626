import { CalloutType } from '@shared/enums/callout/calloutType.enum';
import { ICallout } from '@shared/interfaces/callout/callout.interface';
import { Button } from '@shared/models/button/button.model';
import { CalloutPermission } from './callout-permission.model';

export class Callout implements ICallout {

  readonly type: CalloutType;
  readonly title: string;
  readonly description: string | string[];
  readonly isCloseable: boolean;
  readonly button: Button | null;
  readonly permissions: CalloutPermission;

  constructor(iCallout: ICallout) {
    this.type = iCallout.type;
    this.title = iCallout.title;
    this.description = iCallout.description;
    this.isCloseable = !!iCallout.isCloseable;
    this.button = iCallout.button ? new Button(iCallout.button) : null;
    this.permissions = iCallout.permissions ? new CalloutPermission(iCallout.permissions) : null;
  }

}
