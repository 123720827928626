import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UiKitButtonDisplayMode } from '@ui-kit/types';
import { UiKitPalette } from '../../../enums/ui-kit-palette.enum';
import { UiKitSize } from '../../../enums/ui-kit-size.enum';

export type ButtonType = 'back' | 'close';

@Component({
  selector: 'ui-kit-button',
  templateUrl: './ui-kit-button.component.html',
  styleUrls: ['./ui-kit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitButtonComponent {

  @Input() readonly displayMode: UiKitButtonDisplayMode = 'default';
  @Input() readonly isDisabled: boolean = false;
  @Input() readonly isLoading: boolean = false;
  @Input() readonly isLink: boolean = false;
  @Input() readonly isFloat: boolean = false;
  @Input() readonly isHollow: boolean = false;
  @Input() readonly isLight: boolean = false;
  @Input() readonly isRounded: boolean = false;
  @Input() readonly isInvalid: boolean = false;
  @Input() readonly isInline: boolean = false;
  @Input() readonly isDisablePadding: boolean = false;
  @Input() readonly hasBackground: boolean = false;
  @Input() readonly tooltip: string = '';
  @Input() readonly tooltipPosition: TooltipPosition = 'below';
  @Input() readonly type: 'button' | 'submit' = 'button';
  @Input() readonly size: UiKitSize = UiKitSize.MEDIUM;
  @Input() readonly iconSize: number;
  @Input() readonly label: string;
  @Input() readonly hideTooltipWhenDisabled: boolean = true;
  @Input() readonly fillWidth: boolean = false;
  @Input() readonly rippleDisabled: boolean = false;
  @Input() readonly noBorder: boolean = false;
  @Input() readonly isAlignToInput: boolean = false;

  @Input() readonly palette: UiKitPalette = UiKitPalette.DEFAULT;
  @HostBinding('style')
  get paletteColors(): SafeStyle {
    const palette: UiKitPalette = this.palette || UiKitPalette.DEFAULT;
    return this.sanitizer.bypassSecurityTrustStyle(`
      --ui-kit-button-color: var(--${palette}-color);
      --ui-kit-button-color-hover: var(--${palette}-color-hover);
      --ui-kit-button-color-active: var(--${palette}-color-active);
      --ui-kit-button-color-background: var(--${palette}-color-background);

      --ui-kit-button-light-color-hover: var(--${palette}-light-color-hover, white);
      --ui-kit-button-light-color-hover-background: var(--${palette}-light-color-hover-background, var(--ui-kit-button-color-hover));
      --ui-kit-button-light-color-active-background: var(--${palette}-light-color-active-background, var(--ui-kit-button-color-active));
    `);
  }

  /** {@link} https://feathericons.com/ */
  @Input() readonly icon: string;

  @Input() readonly isIconOnly: boolean = false;
  @Input() readonly isIconRight: boolean = false;

  @Input()
  @HostBinding('attr.data-button-type')
  readonly buttonType: ButtonType;

  @Output() readonly onClick = new EventEmitter<PointerEvent>();

  get classes(): string[] {
    return [this.palette, this.size].filter(String);
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }

}
