import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  PARKING_STATUS_ICON_CONFIGS, PRODUCT_STATUS_ICON_CONFIGS, REPORT_STATUS_ICON_CONFIGS,
  STATUS_ICON_CONFIGS,
  VIGNETTE_STATUS_ICON_CONFIGS,
} from '@shared/configs/status-icon.config';
import { StatusIconType } from '@shared/enums/status-icon/status-icon-type.enum';
import { IStatusIcon } from '@shared/interfaces/status-icon/status-icon.interface';
import { StatusIconConfig } from '@shared/models/status-icon/status-icon-config.model';

@Component({
  selector: 'status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent implements OnInit, OnChanges {

  selectedStatusConfig: StatusIconConfig;

  @Input() statusConfigName: string;
  @Input() config: IStatusIcon;

  ngOnInit(): void {
    this.selectedStatusConfig = this.getStatusIconConfig(this.config.name);
  }

  ngOnChanges(changes: any): void {
    if (!changes.firstChange) {
      this.selectedStatusConfig = this.getStatusIconConfig(
        changes.config.currentValue.name,
      );
    }
  }

  private getStatusIconConfig(name: string): StatusIconConfig {
    switch (this.statusConfigName) {
      case StatusIconType.REPORT_LIST_STATUS_ICON_TYPE:
        return REPORT_STATUS_ICON_CONFIGS[name];
      case StatusIconType.PARKING_STATUS_ICON_CONFIGS:
        return PARKING_STATUS_ICON_CONFIGS[name];
      case StatusIconType.VIGNETTE_STATUS_ICON_CONFIGS:
        return VIGNETTE_STATUS_ICON_CONFIGS[name];
      case StatusIconType.PRODUCT_STATUS_ICON_CONFIGS:
        return PRODUCT_STATUS_ICON_CONFIGS[name];
      default:
        return STATUS_ICON_CONFIGS[name];
    }
  }

}
