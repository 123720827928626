export enum TransactionPermission {
  LIST = 'transactions.list.and.search.transactions',
  DETAILS = 'transactions.see.details.of.the.transactions',
  DOWNLOAD_TICKETS_PDF = 'transactions.download.tickets.in.pdf',
  RESEND_TICKETS = 'transactions.re-send.tickets',
  RETRY_SALES_TRANSACTION = 'transactions.retry.stucked.status.purchase',
  RETRY_REFUND_TRANSACTION = 'refunds.retry.stucked.status.refund',
  EXPORT_TO_EXCEL = 'transactions.export.results.to.excel',
  REGENERATE_TRANSACTION_ITEMS = 'transactions.regenerate.transaction.items'
}
