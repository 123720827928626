export const DollarPlusMinus = `<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_13028_49289)">
    <path d="M3.13333 4.73333V3.13333H4.73333M12.2 3.13333H13.8V4.73333M2.96667 6.96667V8.46667H4.56667M12.2 8.53333H13.8V6.93333M8.5 8C7.32179 8 6.36667 7.04487 6.36667 5.86667C6.36667 4.68846 7.32179 3.73333 8.5 3.73333C9.67821 3.73333 10.6333 4.68846 10.6333 5.86667C10.6333 7.04487 9.67821 8 8.5 8ZM2.06667 1H14.8667C15.4558 1 15.9333 1.47756 15.9333 2.06667V9.5C15.9333 10.0891 15.4558 10.5667 14.8667 10.5667H2.06667C1.47756 10.5667 1 10.0891 1 9.5L1 2.06667C1 1.47756 1.47756 1 2.06667 1Z" stroke="currentColor" stroke-width="1.2"/>
    <path d="M5 13V17M3 15H7M10 15H14" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
  </g>
  <defs>
    <clipPath id="clip0_13028_49289">
      <rect width="17" height="18" fill="currentColor"/>
    </clipPath>
  </defs>
</svg>`;
